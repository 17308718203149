import React, {useState, useRef, useMemo} from "react";
import * as S from  "./PatientSelectorView.style";
import { useSubscription } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { Actions } from "../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";
import * as P from "../../../../../utils/pipes";

// pipes
import Modal from "../../../../../utils/modal";
import ModalUserAiNavigator from "../modalUserAiNavigator";
import PriorityPatients from "./components/priorityPatients";
import { PopUpPayload } from "../../../outcomes/components/outcomesComponents/prostateCancer/cmpVerticalOptions/CmpVerticalOptions.style";
import FollowUpView from "./components/followUpView";
import { SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED } from "../../../../../graphql/subscriptions/UserToProcess";
import usePatientList from "../../../../../hooks/usePatientList";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ModalCreateInvitePatient from "../../../shared/modals/modalCreateInvitePatient";
import PatientsList from "./components/patientsList";
import ModalFiltersAiNavigator from "../modalFiltersAiNavigator";

const reactionNoPatients = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-patients.svg`;

function PatientSelectorView() {  

    const container = useRef(null);
    const textInput = useRef(null);
    const [activeinput, setActiveinput] = useState(false);
    const {t, i18n: { language }} = useTranslation("global");
    const { recordAction } = useRecordNavigation();
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [filtersOptionsActived, setFiltersOptionsActived] = useState(false);
    const [tabSelected, setTabSelected] = useState(3);
    const { patientList, patientListLoading, patientQuantity } = usePatientList(); 
    const [filterProcess, setFilterProcess] = useState([]);
    const [filterGender, setFilterGender] = useState(null);

    const [activeInviteCreatePatient, setActiveInviteCreatePatient] = useState(false);

    const activeInput = () => {
      textInput.current.focus(); 
      setActiveinput(true);
    } 

    const [inputText, setInputText] = useState('');

    return (
      <S.PatientSelectorView
        filtersSectionActived={filterGender || filterProcess.length > 0}
      >
        <Modal open={!!selectedPatient}>
          <ModalUserAiNavigator
            setSelectedPatient={setSelectedPatient}
            selectedPatient={selectedPatient}
          />
        </Modal>
        <Modal open={activeInviteCreatePatient}>
          <ModalCreateInvitePatient
            setCreateInvitePatientActive={setActiveInviteCreatePatient}
          />
        </Modal>
        <Modal open={filtersOptionsActived}>
          <ModalFiltersAiNavigator
            filterProcess={filterProcess}
            setFilterProcess={setFilterProcess}
            filterGender={filterGender}
            setFilterGender={setFilterGender}
            setFiltersOptionsActived={setFiltersOptionsActived}
          />
        </Modal>
        {/* <div className="navigator-title">
          <i className="navigator-title__icon icon zaia-i-magic"></i>
          <div className="navigator-title__text">
            {t("globally.ainavigator")}
          </div>
        </div> */}
        {
          patientList?.length === 0 && !patientListLoading && 
          <div className="no-patients-container">
            <div className="no-patients">
              <ZaiaReaction
                zaiaImg={reactionNoPatients}
                widthImg="392.1px"
                text={t("aiNavigator.noPatientsTitle")}
                subText={t("aiNavigator.noPatientsSubtitle")}
                sizeText="33.25px"
                sizeSubText={"15px"}
                widthText="412px"
                widthSubText={"412px"}
              />
              <button className="no-patients__button"
                onClick={() => setActiveInviteCreatePatient(true)}
              >
                {t("globally.inviteCreatePatient")}
              </button>
            </div>
          </div>
        }
        {
          patientList?.length > 0 &&
          <>
          <div className="ainavigator-content">
            <div className="ainavigator-content__header">
              <div className="searcher-container">
                <div className="searcher-content" ref={container} onClick={() => activeInput()}>
                  <i className="searcher-content__icon icon zaia-i-search"></i>
                  <div className="searcher-input">
                    <input
                      className="searcher-input__input"
                      type="text"
                      onChange={(event) => setInputText(event.target.value)}
                      value={inputText}
                      ref={textInput} 
                      placeholder={t("globally.searchPatient")}
                    />
                    <i className="searcher-input__icon icon zaia-search"/>
                  </div>
                </div>
              </div>
              <div className="ainavigator-content__tabs">
                <button 
                  className={`button-tab ${tabSelected === 3 && "button-tab--selected"}`}
                  onClick={() => {
                    recordAction(Actions.AINAVIGATOR.PATIENTS);
                    setTabSelected(3);
                  }}
                >
                  <i className="button-tab__icon icon zaia-i-person"></i>
                  <div className="button-tab__text">
                    {t("globally.patients")}
                  </div>
                </button>
                {/* <button 
                  className={`button-tab ${tabSelected === 2 && "button-tab--selected"}`}
                  onClick={() => {
                    recordAction(Actions.AINAVIGATOR.ALERTS);
                    setTabSelected(2);
                  }}
                >
                  <i className="button-tab__icon icon zaia-icono-notificaciones"></i>
                  <div className="button-tab__text">
                    {t("globally.alerts")}
                  </div>
                </button> */}
                <button 
                  className={`button-tab ${tabSelected === 1 && "button-tab--selected"}`} 
                  onClick={() => {
                    recordAction(Actions.AINAVIGATOR.FOLLOW_UP);
                    setTabSelected(1);
                  }}
                >
                  <i className="button-tab__icon icon zaia-i-followup"></i>
                  <div className="button-tab__text">
                    {t("aiNavigator.followUp")}
                  </div>
                  <Popup
                    trigger={
                      <i className="button-tab__icon-alert icon icon zaia-icono-ayuda"></i>
                    }
                    on={["hover", "focus"]}
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["bottom center"]}
                    nested
                  >
                    <PopUpPayload>
                      <p>
                        {t("aiNavigator.followUpDescription")}
                      </p>
                    </PopUpPayload>
                  </Popup>
                </button>
              </div>
              {/* <button className="filter-button" 
                onClick={() => setFiltersOptionsActived(true)}
              >
                <div className="filter-button__text">{t("globally.filter")}</div>
                <i className="filter-button__icon zaia-i-filters"></i>
              </button> */}
            </div>
            { 
              (filterGender || filterProcess.length > 0) &&
              <div className="filters-active">
                <div className="filters-active__left">
                  <div className="filters-active__label">{t("globally.activedFilters")}</div>
                  <div className="filters-active__filters">
                    {
                      filterProcess.length > 0 &&
                      <div className="filter-container">
                        <div className="filter-container__close" 
                          onClick={() => setFilterProcess([])}
                        >
                          <i className="filter-container__close-icon icon zaia-icono-cerrar"></i>
                        </div>
                        <div className="filter-container__text">{t("globally.programs")}</div>
                        <div className="filter-container__space">
                          <Popup
                            trigger={
                              <div className="text-filter">{`${P.dataTranslation(filterProcess[0].processName, language)} ${filterProcess.length > 1 ? "+" : ""}${filterProcess.length > 1 ? filterProcess.length - 1 : ""}`}</div>
                            }
                            position={"bottom center"}
                          >
                            <FiltersOptions
                              processes={filterProcess}
                              setFilterProcess={setFilterProcess}
                            />
                          </Popup>
                        </div>
                      </div>
                    }
                    {
                      filterGender &&
                      <div className="filter-container">
                        <div className="filter-container__close"
                          onClick={() => setFilterGender(null)}
                        >
                          <i className="filter-container__close-icon icon zaia-icono-cerrar"></i>
                        </div>
                        <div className="filter-container__text">{t("globally.gender")}</div>
                        <div className="filter-container__space">
                          <div className="text-filter">{
                            filterGender === "masculine" 
                            ? t("globally.male") 
                            : t("globally.female")  
                          }</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="filters-active__right">
                  <div className="filters-active__clean-filters"
                    onClick={() => {
                      setFilterGender(null);
                      setFilterProcess([]);
                    }}
                  >
                    {t("globally.cleanFilters")}
                  </div>
                </div>
              </div>
            }
            <div className="suggestion-list">
              <div className="buttons-container">
                <div className="buttons-container__caption">
                  {/* {
                    !inputText && tabSelected === 2 && 
                    t("aiNavigator.table.priorityAlertsPatients")
                  }
                  {
                    !inputText && tabSelected === 3 && 
                    <>
                      {t("aiNavigator.table.allYourPatients1")}
                      {'\u00A0'}
                      <b>{`(${patientQuantity})`}</b>
                      {'\u00A0'}
                      {t("aiNavigator.table.allYourPatients2")}
                    </>
                  } */}
                  {/* {
                    inputText && <>
                      <div className="caption__close" 
                        onClick={() => {
                          setInputText("")
                          textInput.set = ""
                        }}
                      >
                        <i className="caption__close-icon icon zaia-icono-cerrar"></i>
                      </div>
                      <div className="caption__text">
                        {t("globally.resultsFor")} "
                        <b>{inputText}</b>
                        {`"`}
                      </div>
                    </>
                  } */}
                </div>
                
              </div>
              <div className={`content-space ${tabSelected === 1 && "content-space--actived"}`}>
                <FollowUpView
                  filterProcess={filterProcess}
                  filterGender={filterGender}
                  inputText={inputText}
                  setInputText={setInputText}
                  textInput={textInput}
                />
              </div>
              {/* {
                tabSelected === 2 &&
                <PriorityPatients
                  setSelectedPatient={setSelectedPatient}
                  inputText={inputText}
                  filterProcess={filterProcess}
                  filterGender={filterGender}
                />
              } */}
              <div className={`content-space ${tabSelected === 3 && "content-space--actived"}`}>
                <PatientsList 
                  filterProcess={filterProcess}
                  filterGender={filterGender}
                  setSelectedPatient={setSelectedPatient}
                  selectedPatient={selectedPatient}
                  inputText={inputText} 
                  setInputText={setInputText}
                  textInput={textInput}
                />
              </div>
            </div>
          </div>
          </>
        }
      </S.PatientSelectorView>
    )
  }

  const FiltersOptions = ({processes, setFilterProcess}) => {

    const {i18n: { language }} = useTranslation("global");

    const deleteProcess = (item) => {
      setFilterProcess(processes.filter(process => process.id !== item.id));
    }

    return (
      <S.FiltersOptions>
        {
          processes?.map(process => {
            return (
              <div className="filter-option">
                <div className="filter-option__icon"
                  onClick={() => deleteProcess(process)}
                >
                  <i className="filter-option__icon-close icon zaia-icono-cerrar"/>
                </div>
                <div className="filter-option__text">
                  {P.dataTranslation(process.processName, language)}
                </div>
              </div> 
            )
          })
        }
      </S.FiltersOptions>
    )
  }
  
  export default PatientSelectorView