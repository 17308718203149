import styled from "styled-components";
import { getHexOpacity } from "../../../utils/pipes";
const WaveZaiaSplash = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Splash.svg`;

export const ZaiaSplash = styled.div`
  /* background-color: ${({theme}) => `${theme.background}${getHexOpacity(0.5)}`}; */
  background-color: ${({theme}) => theme.background};
  display: flex;
  flex-direction: column;
  height: ${({webView}) => webView ? '100dvh' : '100svh'};
  width: 100vw;
  overflow: hidden;
  /* background-repeat: no-repeat;
  background-position: top; */

  .zaia-splash-blur{
    height: ${({webView}) => webView ? '100dvh' : '100svh'};
    width: 100vw;
    z-index: 50;
    position: absolute;
    backdrop-filter: blur(4px);
  }
  .zaia-loader {
    display: flex;
    margin: auto;
    z-index: 100;
  }
  .wave-top {
    position: fixed;
    transform: translate(-200px,-920px) scale(0.9) rotate(8deg);
    z-index: 90;
  }
  .wave-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translate(257px,961px) scale(1.2) rotate(182deg);
    z-index: 90;
  }

  @media only screen and (max-width: 1650px) {
    .wave-top {
      transform: translate(-349px,-918px) scale(0.7) rotate(8deg);
    }
    .wave-bottom {
      transform: translate(333px,936px) scale(1.0) rotate(182deg);
    }
  }

  @media only screen and (max-width: 1390px) {
    .wave-top {
      transform: translate(-349px,-918px) scale(0.7) rotate(8deg);
    }
    .wave-bottom {
      transform: translate(532px,936px) scale(1.0) rotate(182deg);
    }
  }

  @media only screen and (max-width: 1188px) {
    .wave-top {
      transform: translate(-500px,-870px) scale(0.7) rotate(8deg);
    }
    .wave-bottom {
      transform: translate(717px,900px) scale(1.0) rotate(182deg);
    }
  }

  @media only screen and (max-width: 1024px) {
    .wave-top {
      transform: translate(-568px,-782px) scale(0.5) rotate(5deg);
    }
    .wave-bottom {
      transform: translate(670px,719px) scale(0.6) rotate(168deg)
    }
  }

  @media only screen and (max-width: 744px) {
    .wave-top {
      transform: translate(-568px,-782px) scale(0.5) rotate(5deg);
    }
    .wave-bottom {
      transform: translate(72px,697px) scale(0.5) rotate(164deg);
    }
  }

  @media only screen and (max-width: 600px) {
    .wave-top {
      transform: translate(-744px,-782px) scale(0.4) rotate(5deg);
    }
    .wave-bottom {
      transform: translate(820px,755px) scale(0.45) rotate(170deg);
    }
  }

  @media only screen and (max-width: 390px) {
    .wave-top {
      transform: translate(-744px,-782px) scale(0.4) rotate(5deg);
    }
    .wave-bottom {
      transform: translate(866px,704px) scale(0.45) rotate(164deg);
    }
  }
`



