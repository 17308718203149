import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import ReactGA from "react-ga4";

const env = process.env.REACT_APP_ENV;

const PrivateRoute = ({ component: Component, authed, ...rest }) => {

  useEffect(() => {
    if (env === 'production') {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [Component])
  
  return authed === true ? (
      <Component />
    ) : (
      <Navigate to="/" replace />
    );
};

export default PrivateRoute;
