import styled from "styled-components";
import { getHexOpacity } from "../../../../../../utils/pipes";

export const SurveyQuestionsList = styled.div`
  /* padding: 10px; */
  /* background-color: var(--white); */
  position: relative;
  height: 100%;
  box-sizing: border-box;
  border-top: 0.5px solid #EEEEEE;
  .filters-section {
    margin-top: 22.5px;
    display: flex; 
    justify-content: space-between;
    &__left {
      display: flex;
      align-items: center;
      .tabs-label {
        font-family: Nunito Sans;
        font-size: 14px;
        color: #68688D;
        margin-right: 20px;
      }
      .tabs-list {
        align-items: center;
        display: flex;
        .tab {
          cursor: pointer;
          font-family: Nunito Sans;
          padding: 5px 0 6px;
          margin-right: 20px;
          display: flex;
          align-items: center;
          &--selected {
            padding: 5px 0px;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            font-family: Nunito Sans Bold;
          }
          &--delicate {
            border-bottom-color: #FF8181;
            color: #FF8181;
          }
          &--all {
            border-bottom-color: #6C6BCC;
            color: #3E3DA3;
          }
          &__text {
            font-size: 14px;
          }
          &__point {
            margin-left: 5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #FF8181;
          }
          &--selected {
            font-family: Nunito Sans Bold;
          }
        }
      }
      .highlights-button {
        cursor: pointer;
        display: flex;
        border: 1px solid #AAAADB;
        align-items: center;
        padding: 7.5px 15px;
        border-radius: 60px;
        color: #36369B;
        &__icon {
          margin-right: 5px;
        }
        &--active {
          padding: 8.5px 15px;
          color: white;
          border: none;
          background-color: #FFA800;

        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      .results-label {
        font-family: "Nunito Sans";
        font-size: 16px;
        color: #707582;
        margin-right: 10px;
      }
      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        &__icon {
          margin-right: 5px;
          width: 25px;
          height: 25px;
          padding: 5px;
          border-radius: 50%;
          font-size: 15px;
          box-sizing: border-box;
          &--delicate {
            background-color: #FFECEC;
            color: #FF3030;
          }
          &--normal {
            background-color: #F0F0FF;
            color: #3E3E5B;
          }
        }
        &__text {
          color: #707582;
          font-family: Nunito Sans;
          font-size: 16px;
        }
      }
    }
  }
  .dates-container {
    margin-top: 28px;
    display: flex;
    /* flex-direction: column; */
    position: relative;
    .slider-container {
      /* width: calc(100% - 30px); */
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 38px 0 35px;
      .alice-carousel__prev-btn {
        position: absolute;
        height: 100%;
        width: 30px;
        top: 0;
        left: -35px;
        padding: 0;
        transition: 0.5s;
      }
      .alice-carousel__next-btn {
        position: absolute;
        height: 100%;
        width: 30px;
        top: 0;
        right: -38px;
        padding: 0;
        transition: 0.5s;
      }
    }
    .button-calendar {
      font-size: 24px;
      color: #3E3DA3;
      align-items: center;
      margin-left: 6px;
      display: flex;
      &__icon {
        margin: auto;
        cursor: pointer;
      }
    }
  }
  .table-event-filters {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 40px;
    &__clear {
      padding-top: 10px;
      color: #526479;
      font-family: "Nunito Sans";
      font-size: 16px;
      text-decoration-line: underline;
      margin-right: 10px;
      cursor: pointer;
    }
    .event-item {
      margin-top: 10px;
      margin-left: 10px;
      display: flex;
      border-radius: 15px;
      background: var(--Purple-II, #6C6BCC);
      display: flex;
      padding: 5px 10px;
      align-items: center;
      &__name {
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 16px;
        margin-right: 10px;
      }
      &__button {
        border-radius: 57px;
        background: rgba(255, 255, 255, 0.29);
        display: flex;
        padding: 5.613px;
        align-items: flex-start;
        font-size: 6px;
        color: #FFF;
        cursor: pointer;
      }
    }
  }
  .select-view {
    display: none;
    &__button-selector {
    }
    &__popup {
      width: 100%;
      left: 0;
      padding: 20px;
      box-sizing: border-box; 
    }
  }
  .view-container {
    overflow: auto;
    height: calc(100% - 50px);
    margin-top: 49px;
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 15px;
      margin-bottom: 20px;
      thead {
        padding: 10px;
        tr {
          th {
            /* border-bottom: 1.6px solid var(--no-notification-background); */
            color: #707582;
            font-family: "Nunito Sans";
            font-size: 15px;
            padding: 18px 10px 15px 10px;
            text-align: left;
            font-weight: 400;
            text-align: center;
          }
          .th-event-type{
            text-align: left;
            padding: 18px 20px 15px 20px;
          }
        }
      }
      tbody {
        padding: 10px;
        i {
          /* display: inline; */
        }
        td:first-child,
        th:first-child {
          /* border-radius: 14px 0 0 14px; */
        }
        td:last-child,
        th:last-child {
          border-radius: 0 14px 14px 0;
        }
        tr {
          .icon-save-form-field {
            /* margin-right: 10px;
            color: ${({theme}) => theme.inputSelected};
            cursor: pointer; */
          }
          .td-index0 {
            display: flex;
            text-align: left;
            align-items: center;
            border-radius: 14px 0 0 14px;
          }
          td {
            padding: 30px 20px;
            text-align: left;
            color: var(--blue-dark-color);
            /* vertical-align: text-top; */
            .detail-item {
              display: flex;
              align-items: center;
              justify-content: left;
              color: var(--blue-color);
              font-family: "Nunito Sans Bold";
              font-size: 15px;
              margin-bottom: 10px;
            }
            .template-header-item-icon {
              color: var(--blue-color);
              font-size: 12px;
              margin-left: 5px;
            }
            .highlight-button {
              margin: 0 20px 0 10px;
              border-radius: 14px;
              border: 1px solid #AAAADB;
              color: #38379C;
              padding: 8px;
              cursor: pointer;
              &--active {
                border: none;
                padding: 9px;
                background-color: #FFA800;
                color: white;
              }
              .icon-save-form-field {
                display: flex;
              }
            }
            .event-type {
              &__name {
                color: #23234F;
                font-family: "Nunito Sans Light";
                font-size: 15px;
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                .template-header-item-icon {
                  color: #38379C;
                  font-size: 13px;
                }
              }
              &__option {
                margin-top: 5px;
                cursor: pointer;
                font-family: "Nunito Sans";
                font-size: 15px;
                font-style: normal;
                color: #38379C;
                display: flex;
                align-items: center;
                &-icon {
                  font-size: 13px;
                  display: flex;
                  margin-right: 5px;
                }
                &-text {
                  text-decoration: underline;
                }
              }
            }
            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              &__icon {
                margin-right: 5px;
                width: 25px;
                height: 25px;
                padding: 5px;
                border-radius: 50%;
                font-size: 15px;
                box-sizing: border-box;
                &--delicate {
                  background-color: #FFECEC;
                  color: #FF3030;
                }
                &--delicate {
                  background-color: #F0F0FF;
                  color: #3E3E5B;
                }
              }
              &__text {
                color: #707582;
                font-family: Nunito Sans;
                font-size: 16px;
              }
            }
          }
        }
        tr:nth-child(odd) {
          background-color: var(--table-color);
          border-radius: 14px;
        }
      }
    } 
    .survey-list-reaction {
      position: absolute;
      width: 100%;
      margin-top: 60px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 0px;
  }

  @media only screen and (max-width: 850px) {
    .table-title{
      background-color: transparent;
      border-bottom: none;
      padding: 0;
      .buttons-container {
        display: none;
      }
    }
    .select-view {
      display: block;
      &__label {
        margin-bottom: 5px;
        color: ${({theme}) => `${theme.primaryColor}` };
      }
      &__button-selector {
        background-color: ${({theme}) => `${theme.primaryColor}` };
        justify-content: space-between;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
      }
      &__icon-container {
        display: flex;
        align-items: center;
        color: ${({theme}) => `${theme.onPrimaryColor}` };
        padding-right: 14px;
        i {
          display: flex;
        }
      }
    }
    .view-container {
      height: calc(100% - 95px);
      width: 100%;
      min-width: auto;
    }
  }
`;

export const ProstateProcessRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  h5 {
    font-family: "Open Sans Bold";
    margin-bottom: 10px;
  }
  .row-left {
    width: ${({row1}) => row1 ? row1 : 'auto'}; 
    min-width: ${({row1Min}) => row1Min ? row1Min : 'auto'}; 
    margin-right: 5px;
    padding-bottom: 10px;
    flex-grow:2;
  }
  .row-right {
    width: ${({row2}) => row2 ? row2 : 'auto'};
    min-width: ${({row2Min}) => row2Min ? row2Min : 'auto'}; 
    margin-left: 5px;
    flex-grow:2;
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`

export const ButtonView = styled.button`
  border-radius: 50px;
  /* height: 37px; */
  padding: 5px 15px;
  /* padding: 0 10px; */
  justify-content: center;
  min-width: 153px;
  border: none;
  outline: none;
  background-color: ${({active, theme}) => active ? "#6C6BCC" : theme.background};
  border: 1px solid ${({active, theme}) => active ? "#6C6BCC" : "#AAAADB"};
  color: ${({active, theme}) => active ? theme.background : theme.primaryColor };
  margin: 0 0 0 14px;
  font-family: "Nunito Sans Light";
  font-size: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon-container {
    margin-right: 5px;
    /* padding-top: 6px; */
    i {
      font-size: 13px;
      color: ${({active, theme}) => active ? "#FFFFFF" : "#6C6BCC"};
    }
  }

  @media only screen and (max-width: 850px) {
    background-color: ${({active, theme}) => `${theme.primaryColor}${active ? '': '61'}` };
    border-radius: 0px;
    margin: 0;
    width: calc(100vw - 20px);
  }
`

export const SelectViewSelector  = styled.div`
  background-color: ${({theme}) => theme.background };
  /* padding: 10px; */
  overflow: hidden;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  width: auto;
`

export const SliderBox = styled.div`
  /* padding: 0px 2.5px; */
    width: 240px;
  /* max-width: 230px; */
  /* padding: 0 25px; */
  padding-right: 5px;
  &:first-child {
    margin-left: 25px;
  }
  .slider-box {
    padding: 5px 20px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Nunito Sans;
    font-size: 15px;
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    background-color: ${({selected}) => selected ? "#D2D2FF" : "#F0F0FF"};
    font-size: ${({selected}) => selected ? "Nunito Sans Bold" : "Nunito Sans"};

    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    &-title {
      font-size: 15px;
    }
    &-filled {
      color: #6D6F8B;
      font-size: 14px;
      color: ${({selected}) => selected ? "#1F1F52" : "#6D6F8B"};
    }
    &-subtitle {
      font-size: 13px;
      margin-bottom: 15px;
    }
    &-code {
      font-size: 16px;
    }
    &--selected {
      font-size: Nunito Sans Bold;
    }
  }
`

export const CarouselButton = styled.button`
  height: 100%;
  width: 100%;
  width: 49px;
  opacity: ${({disabled}) => disabled ? '0.4' : '1'};
  cursor: pointer;
  border: none;
  background-color: #EFEFFF;
  .icon {
    color: #36369B;
    justify-content: center;
  }
  left: ${({position}) => position === 'prev' ? 0 : '' };
  right: ${({position}) => position === 'prev' ? '' : 0 };
  border-radius: 50%
` 

export const CalendarContainer = styled.div`
  background-color: white;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 9px 0px #23229D21;
  border-radius: 14px;
  .marker {
    display: flex;
    align-items: center;
    margin-bottom: 20px; 
    &__point {
      margin-right: 10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      &--green {
        background-color: #40BA82;
      }
      &--red {
        background-color: #FF3030;
      }
    }
    &__text {
      font-family: Nunito Sans;
      font-size: 14px;
      color: #707582;
    }
  }
`
