import styled from "styled-components";

export const FollowUpView = styled.div`
  height: 100%;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(35, 34, 157, 0.17);
    padding: 3px 10px;

    .filters-button {
      display: flex;
      align-items: center;
      display: flex;
      padding: 10px;
      align-items: center;
      border-radius: 43px;
      border: 1px solid #6C6BCC;
      color: #6C6BCC;
      cursor: pointer;
      &__icon {
        margin-right: 10px;
      }
    }

    .filter-state {
      display: flex;
      margin-left: 20px;
      cursor: pointer;
      display: flex;
    }
  }

  .follow-up-list {
    height: calc(100% - 88px);

    &__top {
      display: flex;
      justify-content: space-between;
      padding: 10px 50px 0 50px;
      color: #707582;
      font-family: "Nunito Sans";
      font-size: 15px;
      font-style: normal;
    }

    &__center {
      height: calc(100% - 67px);
      overflow: auto;
      margin: 10px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    } 

    .follow-up__item {
      padding: 5px 40px;
    }

    &__bottom {
      display: flex;
      justify-content: center;
      .pagination {
        padding: 17px 0px;
        display: flex;
        align-items: center;
        &__arrow {
          cursor: pointer;
          color: #3E3DA3;
          font-size: 12px;
          &--back {
            margin-right: 45px;
          }
          &--next {
            margin-left: 5px;
          }
        }
        &__number {
          cursor: pointer;
          color: #6C6BCC;
          text-align: center;
          font-family: "Nunito Sans";
          font-size: 16px;
          letter-spacing: -0.144px;
          margin-right: 40px;
          &--selected {
            border-bottom: 1px solid #6C6BCC;
          }
        }
      }
      .add-patient {
        cursor: pointer;
        right: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        align-items: center;
        margin: 17px 52px;
        color: #6C6BCC;
        font-family: "Nunito Sans";
        font-size: 17px;
        &__text {
          margin-right: 5px;
        }
        &__icon {
          font-size: 16px;
          opacity: 0.7;
        }
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 10px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttons-container__caption {
    color: #1F1F52;
    font-size: 17px;
    display: flex;
    align-items: center;

    .caption {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      &__text {
        display: flex;
        font-size: 17px;
        color: ##F1F52;
        width: 100%;

        div {
          display: flex;
          align-items: center;
          margin-left: 10px;
          font-size: 14px;
          font-weight: 400;
          padding: 4px 10px;
          padding-left: 10px;
          padding-right: 15px;
          box-shadow: 0 0 4px 0 #D8D8EA;
          border-radius: 8px;

          i {
            margin-right: 10px;
            font-size: 8px;
            color: #5A7078;
            cursor: pointer;
          }
        }
      }
    }
  }
`

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  .item-text {
    border-bottom: 1.5px solid ${({color, activeTab}) => activeTab ? color : "transparent"};
    color: ${({color}) => color};
    font-weight: ${({activeTab}) => activeTab ? "800" : "400"};
    /* margin-right: 20px; */
  }

  .item-point {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 5px;
    background-color: ${({color}) => color};
  }
`