import styled from "styled-components";

export const MedicationDate = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  .title {
    color: #3E3DA3;
    font-family: Nunito Sans;
    font-size: 17px;
    margin-top: 20px;
  }
  .calendar-inputs {
    margin: 20px 33px 28px;
    display: flex;
    justify-content: center;
    .input-container {
      display: flex;
      align-items: center;
      .input-label {
        margin-right: 10px;
        color: #3E3E5B;
        font-family: "Nunito Sans";
        font-size: 14px;
      }
      &--unfocus {
        opacity: 0.3  ;
      }
    }
  }
  .calendar-range-container {
    display: flex;
    .calendar-range {
      margin: auto;
      width: 300px;
    }
  }
`