import React, { useEffect, useState } from "react";
import * as S from "./HealthIndicatorMeasure.style";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const painScale0 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/0.svg`;
const painScale1 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/1.svg`;
const painScale2 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/2.svg`;
const painScale3 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/3.svg`;
const painScale4 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/4.svg`;
const painScale5 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/5.svg`;
const painScale6 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/6.svg`;
const painScale7 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/7.svg`;
const painScale8 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/8.svg`;
const painScale9 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/9.svg`;
const painScale10 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/painScale/10.svg`;

function HealthIndicatorMeasure({
  selectedMeasure,
  selectedMeasure2,
  selectedMeasureType,
  selectedHealthIndicator,
  changeFormField,
  measureList
}) {

  const [value, setValue] = useState("0"); 
  const MAX = "10"; 

  const { t } = useTranslation("global");
  
  useEffect(() => {
    if(measureList?.length === 1) {
      changeFormField([
        {
          value: measureList[0],
          text: measureList[0],
          fieldName: "measureType"
        }
      ])
    }
  }, [measureList])

  const painScaleIcon = useMemo(() => {
    let colorTemp = ""
    switch(value) {
      case "0": {colorTemp = painScale0; break; }
      case "1": {colorTemp = painScale1; break;}
      case "2": {colorTemp = painScale2; break;}
      case "3": {colorTemp = painScale3; break;}
      case "4": {colorTemp = painScale4; break;}
      case "5": {colorTemp = painScale5; break;}
      case "6": {colorTemp = painScale6; break;}
      case "7": {colorTemp = painScale7; break;}
      case "8": {colorTemp = painScale8; break;}
      case "9": {colorTemp = painScale9; break;}
      case "10": {colorTemp = painScale10; break;}
      default: {colorTemp = "";}
    }
    return colorTemp;
  }, [value])

  const primaryBallColor = useMemo(() => {
    let colorTemp = "black"
    switch(value) {
      case "0": {colorTemp = "#63CEE5"; break; }
      case "1": {colorTemp = "#5ECC8C"; break;}
      case "2": {colorTemp = "#69CD62"; break;}
      case "3": {colorTemp = "#B6E45F"; break;}
      case "4": {colorTemp = "#F0D860"; break;}
      case "5": {colorTemp = "#FFD95E"; break;}
      case "6": {colorTemp = "#FFD95E"; break;}
      case "7": {colorTemp = "#FFA736"; break;}
      case "8": {colorTemp = "#FF792E"; break;}
      case "9": {colorTemp = "#FF6D29"; break;}
      case "10": {colorTemp = "#D32549"; break;}
      default: {colorTemp = "black";}
    }
    return colorTemp;
  }, [value])

  const secondaryBallColor = useMemo(() => {
    let colorTemp = "black"
    switch(value) {
      case "0": {colorTemp = "#E6F0FF"; break; }
      case "1": {colorTemp = "#AEF2CA"; break;}
      case "2": {colorTemp = "#DCFFDF"; break;}
      case "3": {colorTemp = "#DDEEB7"; break;}
      case "4": {colorTemp = "#FAFFDC"; break;}
      case "5": {colorTemp = "#FFF5DC"; break;}
      case "6": {colorTemp = "#FFF5DC"; break;}
      case "7": {colorTemp = "#FFE6DC"; break;}
      case "8": {colorTemp = "#FFDEDC"; break;}
      case "9": {colorTemp = "#FFA77D"; break;}
      case "10": {colorTemp = "#FF7D7D"; break;}
      default: {colorTemp = "black";}
    }
    return colorTemp;
  }, [value])

  const getPainScale = () => {
    return (
      <S.PainScale
        primaryBallColor={primaryBallColor}
        secondaryBallColor={secondaryBallColor}
      >
        <img src={painScaleIcon} alt="" className={`img-container ${value === "10" && "img-container--last"}`} />
        <div className="slider-container">
          <input 
            className="input-range" 
            type="range" 
            min="0" 
            max={MAX} 
            value={selectedMeasure.value ?? "0"}
            onChange={(e) => {
              setValue(e.target.value)
              changeFormField([
                {
                  value: Number(e.target.value),
                  text: Number(e.target.value),
                  fieldName: "measure"
                }
              ])
            } 
            }
          />
        </div>
        <div className="slider-numbers">
          {
            [0,1,2,3,4,5,6,7,8,9,10].map((number) => {
              return (
                <div key={number} className="slider-number">
                  {number}
                </div>
              )
            })
          }
        </div>
      </S.PainScale>
    )
  }


  // useEffect(() => {

  //   let valueCalc = (sPressureValue + (2*dPressureValue))/3;

  //   if(sPressureValue && dPressureValue) {
  //     changeFormField(
  //       [
  //         {
  //           value: valueCalc?.toFixed(2), 
  //           text: valueCalc?.toFixed(2), 
  //           fieldName: "measure"
  //         }
  //       ]
  //     )
  //   } else {
  //     changeFormField(
  //       [
  //         {
  //           value: null, 
  //           text: null, 
  //           fieldName: "measure"
  //         }
  //       ]
  //     )
  //   }
  // }, [sPressureValue, dPressureValue]);

  const calcPressure = () => {
    console.log("hoola", selectedMeasure.value);
    console.log("adios", selectedMeasure2.value);
    console.log("adios", (selectedMeasure.value + (2*selectedMeasure2.value)));
    
    console.log(((Number(selectedMeasure.value) + (2*Number(selectedMeasure2.value)))/3)?.toFixed(2));
    
    
    return ((Number(selectedMeasure.value) + (2*Number(selectedMeasure2.value)))/3)?.toFixed(2);
  }

  const calcCurrentPressure = (pSis, pDias) => {
    console.log("12344555", pSis);
    console.log("123dfgdf", pDias);

    
    return ((Number(pSis) + (2*Number(pDias)))/3)?.toFixed(2);
  }

  const getPressures = () => {

    return (
      <S.Pressures>
        <div className="inputs">
          <div className={`input-content ${selectedHealthIndicator.value === null && "input-content--disabled"}`}>
            <div className="input-label">
              {t("healthAddData.healthIndicators.systolicPressure")}
            </div>
            <ZaiaInput
              placeholder={t("healthAddData.healthIndicators.typeResult")}
              type={"number"}
              name="data"
              errorOption={false}
              getText={(e) => {
                changeFormField(
                  [
                    {
                      value: selectedMeasure2.value, 
                      text: "", 
                      fieldName: "measure2"
                    },
                    {
                      value: e ? e : null, 
                      text: calcCurrentPressure(e, selectedMeasure2.value), 
                      fieldName: "measure"
                    },
                  ]
                )
              }}
              value={selectedMeasure.value ?? ""}
              padding={"20px"}
              borderRadius={"14px"}
              textColor={"#6C6BCC"}
              borderColor={"transparent"}
              placeholderTextColor={"#878792"}
              fontSize="15px"
              disabled={!selectedHealthIndicator.value}
            />
          </div>
          <div className={`input-content ${selectedHealthIndicator.value === null && "input-content--disabled"}`}>
            <div className="input-label">
              {t("healthAddData.healthIndicators.diastolicPressure")}
            </div>
            <ZaiaInput
              placeholder={t("healthAddData.healthIndicators.typeResult")}
              type={"number"}
              name="data"
              errorOption={false}
              getText={(e) => {
                changeFormField(
                  [
                    {
                      value: selectedMeasure.value, 
                      text: "", 
                      fieldName: "measure"
                    },
                    {
                      value: e ? e : null, 
                      text: calcCurrentPressure(selectedMeasure.value, e), 
                      fieldName: "measure2"
                    }
                  ]
                )
              }}
              value={selectedMeasure2.value ?? ""}
              padding={"20px"}
              borderRadius={"14px"}
              textColor={"#6C6BCC"}
              borderColor={"transparent"}
              placeholderTextColor={"#878792"}
              fontSize="15px"
              disabled={!selectedHealthIndicator.value}
            />
          </div>
        </div>
        <div className="pressure-result">
          <div className="pressure-result__text">
            {calcPressure()}
          </div>
          <div className="pressure-result__measure">
            {measureList ? measureList[0] : ""}
          </div>
        </div>
      </S.Pressures>
    )
  }

  const inputContent = () => {
    return (
      <>
        <div className={`input-content ${selectedHealthIndicator.value === null && "input-content--disabled"}`}>
          {
            measureList?.length === 1 &&
            <div className="input-space__icon">
              {measureList[0]}
            </div>
          }
          <ZaiaInput
            placeholder={t("healthAddData.healthIndicators.typeResult")}
            type={"number"}
            name="data"
            errorOption={false}
            getText={(e) => {
              // if(e) {
                changeFormField(
                  [
                    {
                      value: e ? e : null, 
                      text: e, 
                      fieldName: "measure"
                    }
                  ]
                )
            }}
            value={selectedMeasure.value ?? ""}
            padding={"20px"}
            borderRadius={"14px"}
            textColor={"#6C6BCC"}
            borderColor={"transparent"}
            placeholderTextColor={"#878792"}
            fontSize="15px"
            disabled={!selectedHealthIndicator.value}
          />
          {
            measureList?.length > 1 &&
            <div className="unit-list">
              {
                measureList?.map(
                  (measure) => {
                    return (
                      <button 
                        key={measure}
                        className={`unit-item ${measure === selectedMeasureType?.value && 'unit-item--selected'}`} 
                        onClick={
                          () => {
                            changeFormField(
                              [
                                {
                                  value: measure, 
                                  text: measure, 
                                  fieldName: "measureType"
                                }
                              ]
                            )
                          }
                        }
                      >
                        <div className="unit-item__text">{measure}</div>
                      </button>
                    )
                  }
                )
              }
            </div>
          }
        </div>
        {!selectedHealthIndicator.value && <div className="input-msg">{t("healthAddData.healthIndicators.healthIndicatorWarning")}</div>}
      </>
    )
  }

  return (
    <S.HealthIndicatorMeasure>
      <div className="title">{t("healthAddData.healthIndicators.resultQuestion")}</div>
      <div className="measure-inputs">
        <div className="input-space">
          <div className="input-label">
            {
              selectedHealthIndicator?.value === "pain" 
              ? t("healthAddData.healthIndicators.painScaleSlide") 
              : t("healthAddData.healthIndicators.measureResult")
            }
          </div>
          {
            selectedHealthIndicator?.value === "pain" && getPainScale()
          }
          {
            selectedHealthIndicator?.value === "pressure" && getPressures()
          }
          {
            selectedHealthIndicator?.value !== "pressure" && selectedHealthIndicator?.value !== "pain" && inputContent()
          }
        </div>
      </div>
    </S.HealthIndicatorMeasure>
  );
}

export default HealthIndicatorMeasure;
