import React, { useEffect, useState }  from "react";
import * as S from "./Sidebar.style";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../../contextAPI/StateProvider";
import Cookies from "js-cookie";

// Routes
import { Routes } from "../../../../utils/constants";
import { Actions } from "../../../../utils/actionsIds";
import { useNavigate, useParams } from "react-router-dom";

// Hooks
import useActivateModal from "../../../../hooks/useActivateModal"
import useDoctorData from "../../../../hooks/useDoctorData"
import useRecordNavigation from "../../../../hooks/useRecordNavigation"

// Components
import ZaiaImage from "../../../zaiaComponents/zaiaImage";

// Pipes
import * as P from '../../../../utils/pipes';

// GraphQL
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_LAST_MESSAGE } from "../../../../graphql/subscriptions/Chat";

import {signOut} from "supertokens-auth-react/recipe/emailpassword";

import Modal from "../../../../utils/modal";
import ZaiaSplash from "../../../zaiaComponents/zaiaSplash";
import ModalCreateInvitePatient from "../modals/modalCreateInvitePatient";
import useAssignSelectPatient from "../../../../hooks/useAssignSelectPatient";
import Popup from "reactjs-popup";

// Assets
const sidebarImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/florence-health.svg`;
const notificationSoundMp3 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaia-sounds/notificationSound.mp3`;
const defaultDoctor = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-doctor.png`;

function Sidebar({ namePage, setActiveMenu }) {
  const [{ selectedPatient }, dispatch] = useStateValue();
  const { doctorData } = useDoctorData();
  const navigate = useNavigate();
  const { patientID } = useParams();
  const { DASHBOARD, ALGORITHMS, CHAT, AGENDA, AINAVIGATOR, HOME, PROFILE, FILE_READING, SURVEYS, POLICE } = Routes;
  const { t, i18n: { language } } = useTranslation("global");
  const lang = language.split('-')[0];
  const {desactiveModal} = useActivateModal()
  const [incomingMsg, setIncomingMsg] = useState(false);
  const [incomingMsgCounter, setIncomingMsgCounter] = useState(0);
  const notificationSoundAudio = new Audio(notificationSoundMp3);
  const [signOutPressed, setSignOutPressed] = useState(false);
  const webView = Cookies.get('webView');
  const env = process.env.REACT_APP_ENV;
  const { recordAction } = useRecordNavigation();
  const [accountMenuActive, setAccountMenuActive] = useState(false);
  const [inviteAddPatientModal, setInviteAddPatientModal] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const { removePatient } = useAssignSelectPatient();


  const logout = async () => {
    setSignOutPressed(true);
    await signOut();

    Cookies.remove('sFrontToken');
    Cookies.remove('sIdRefreshToken');
    Cookies.remove('sAccessToken');
    
    dispatch({
      type: "SET_AUTH",
      isAuth: false,
    });
    navigate(HOME.route);
    setSignOutPressed(false);
  };

  useEffect(() => {
    desactiveModal();
    if(namePage === "chat") {
      if (incomingMsgCounter > 1) setIncomingMsgCounter(1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePage])

  const playNotificationSound = () => {
    const playPromise = notificationSoundAudio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(_ => {})
        .catch(error => {
          console.log(error);
        });
    }
  }


  useSubscription(
    SUBSCRIPTION_GET_LAST_MESSAGE,
    {
      variables: { 
        doctorId: doctorData?.id,
      },
      onData: ({data: {data}}) => {
        const newMessage = data.message[0];

        const isOwnerDoctor = newMessage?.chatGroup.ownerDoctorId === doctorData.id;
        const isPersonInChat = newMessage?.chatGroup.personToChats.some(element => element.doctorId === doctorData.id);

        if (
          newMessage?.chatGroup && 
          (isOwnerDoctor || isPersonInChat)
        ) {
          if (incomingMsg === false ) {
            setIncomingMsg(newMessage);
            setIncomingMsgCounter(1);
          } 
          if (
            incomingMsg &&
            incomingMsg.id !== newMessage?.id && 
            (!newMessage?.fromDoctorId || newMessage.fromDoctorId !== doctorData.id) 
          ) {
            if(namePage !== "chat") setIncomingMsgCounter(incomingMsgCounter + 1);
            setIncomingMsg(newMessage);
            playNotificationSound();
          } 
          if(incomingMsg === undefined || incomingMsg === null) {
            setIncomingMsg(newMessage);
            playNotificationSound();
          }
        }
      }
    }
  );

  const goToPage = (route) => {
    dispatch({
      type: "SET_CONTEXT_PROCESS",
      contextProcess: null,
    });
    dispatch({
      type: "SET_CONTEXT_PATIENT",
      contextPatient: null,
    });
    navigate(route); 
    setActiveMenu(false);
    setMenuActive(!menuActive)
  }

  const isClassActive = (moduleName) => {
    if (namePage === moduleName) {
      return "active";
    }
  }

  const isClassActiveDropdown = (moduleNames = []) => {
    if (moduleNames.includes(namePage)) {
      return "active-dropdown";
    }
  }

  const removePatientAction = () => {
    removePatient()
    dispatch({
      type: "SET_CONTEXT_PROCESS",
      contextProcess: null,
    });
  }
  
  return (
    <>
      <Modal open={inviteAddPatientModal}>
        <ModalCreateInvitePatient
          setCreateInvitePatientActive={setInviteAddPatientModal}
        />
      </Modal>
      <S.Sidebar 
        selectedPatient={selectedPatient} 
        webView={webView} 
        env={env}
        menuActive={menuActive}
      >
        <Modal open={(signOutPressed)}>
          <ZaiaSplash/>
        </Modal>
        <div className="title-container"
          onClick={() => {
            if (namePage !== AINAVIGATOR.id)  {
              navigate(HOME.route);
            } else {
              removePatientAction();
              navigate(HOME.route);
            }
          }}
        >
          <img className="zaia-title" alt="sidebarImg" src={sidebarImg} />
          {/* <div className="sidebar-close">
            <i className="sidebar-close-icon icon zaia-icono-cerrar" onClick={() => setActiveMenu(false)}></i>
          </div> */}
        </div>

        <div className="sidebar-tabs-container">
          <div
            id={Actions.AINAVIGATOR.VIEW}
            onClick={
              () => {
                goToPage(AINAVIGATOR.route);
                recordAction(Actions.AINAVIGATOR.VIEW);
              }
            }
            className={`sidebarOption ${isClassActive(AINAVIGATOR.id)}`}
          >
            <div className="sidebar-tab-name">{t("globally.home")}</div>
          </div>

          <div
            id={Actions.ALERTS.ID}
            onClick={
              () => {
                goToPage(DASHBOARD.route);
                recordAction(Actions.ALERTS.ID);
              }
            }
            className={`sidebarOption ${isClassActive(DASHBOARD.id)}`}
          >
            <div className="sidebar-tab-name">{t("globally.alerts")}</div>
          </div>

          <div
            id={Actions.AGENDA.VIEW}
            onClick={
              () => {
                goToPage(AGENDA.route);
                recordAction(Actions.AGENDA.VIEW);
              }
            }
            className={`sidebarOption ${isClassActive(AGENDA.id)}`}
          >
            <div className="sidebar-tab-name">{t("globally.agenda")}</div>
          </div>

          {/* ALGORITMOS */}
          {/* <div
            id={Actions.ALGORITHMS.VIEW}
            onClick={
              () => {
                goToPage(ALGORITHMS.route);
                recordAction(Actions.ALGORITHMS.VIEW);
              }
            }
            className={`sidebarOption ${isClassActive(ALGORITHMS.id)} `}
          >
            <div className="sidebar-tab-name">{t("globally.algorithms")}</div>
          </div> */}

          <div
            id={Actions.CHAT.VIEW}
            onClick={
              patientID && namePage === "chat" ? null : () => {
                goToPage(CHAT.route);
                recordAction(Actions.CHAT.VIEW)
              }
            }
            className={`sidebarOption ${isClassActive("chat")}`}
          >
              <div className="sidebar-tab-name">{t("globally.chat")}</div>
            {
              incomingMsg && incomingMsgCounter > 1? 
              <div className="sidebar-tab-alarm"></div> 
              : null
            }
          </div>

          {/* Encuestas */}
          <div
            onClick={
              () => {
                goToPage(SURVEYS.route);
              }
            }
            className={`sidebarOption ${isClassActive(SURVEYS.id)} `}
          >
            <div className="sidebar-tab-name">{t("globally.surveys")}</div>
          </div>

          <Popup
            trigger={() => (
              <div className="sidebar-option-dropdown">
                <div className={`sidebar-tab-name ${isClassActiveDropdown([FILE_READING.id])}`}>{t("globally.navbarMore")}</div>
                <i className="icon zaia-i-down" />
              </div>
            )}
            position="bottom center"
          >
            {(close) => (
              <S.DropdownContent>
                <div
                  className="label"
                  onClick={() => {
                    window.open(`${lang === 'es' ? 'https://www.singularity.health/es/blog' : 'https://www.singularity.health/blog'}`)
                    close();
                  }}
                >
                  {t("globally.blog")}
                </div>

                <div
                  className="label"
                  onClick={() => {
                    goToPage(FILE_READING.route)
                    close();
                  }}
                >
                  {t("globally.fileReading")}
                </div>
              </S.DropdownContent>
            )}
          </Popup>

          {/* Blog: Hide on Desktop */}
          <div
            onClick={() => {window.open(`${lang === 'es' ? 'https://www.singularity.health/es/blog' : 'https://www.singularity.health/blog'}`)}}
            className={`sidebarOption ${isClassActive("blog")} `}
            data-nav="blog"
          >
            <div className="sidebar-tab-name">{t("globally.blog")}</div>
          </div>

          {/* File Reading: Hide on Desktop */}
          <div
            onClick={
              () => {goToPage(FILE_READING.route)}
            }
            data-nav="file-reading"
            className={`sidebarOption ${isClassActive(FILE_READING.id)} `}
          >
            <div className="sidebar-tab-name">{t("globally.fileReading")}</div>
          </div>



          {/* Lectura de Archivos */}
          <div className="menu-sign-out">
            <button className="sign-out" onClick={logout}>
              <i className="sign-out__icon icon zaia-i-singout"></i>
              <div className="sign-out__text">{t("account.signOut")}</div>
            </button>
          </div>
        </div>


        <div className="menu-trigger"
          onClick={() => setMenuActive(!menuActive)}
        >
          <i className="menu-trigger__icon icon zaia-icono-menu"></i>
        </div>

        <div className="user-content" onClick={() => setAccountMenuActive(true)}>  
          <div className="user-content__label">
            {t("profile.myAccount")}
          </div>

          <div className="user-content-user-image">
            <ZaiaImage img={doctorData?.picture} alt="Zaia User" size={40} defaultImg={defaultDoctor} rounded={true}/> 
          </div>

          {/* <i className="icon zaia-i-down"/> */}
        </div> 

      </S.Sidebar>

      {
        accountMenuActive &&
        <S.AccountUserMenu>
          <div className="menu">
            <div className="menu__left">
              <div className="menu__name">{`${doctorData?.name} ${doctorData?.lastname}`}</div>
              <div className="menu__email">{doctorData?.email}</div>
              <div className="menu__action"
                onClick={() => {navigate(PROFILE.route); setAccountMenuActive(false)}}
              >
                <i className="menu__action-icon icon zaia-i-manage-account"></i>
                <div className="menu__action-text">{t("account.manageYourAccount")}</div>
              </div>
              <div className="menu__action"
                onClick={() => setInviteAddPatientModal(true)}
              >
                <i className="menu__action-icon icon zaia-i-invitepatient"></i>
                <div className="menu__action-text">{t("globally.inviteCreatePatient")}</div>
              </div>
              <div className="menu__bottom">
                <div className="menu__link">{t("account.privacyPolicies")}</div>
                <div className="menu__link-separator"></div>
                <div className="menu__link">{t("account.termsAndConditions")}</div>
              </div>
            </div>
            <div className="menu__right">
              <button className="sign-out" onClick={logout}>
                <i className="sign-out__icon icon zaia-i-singout"></i>
                <div className="sign-out__text">{t("account.signOut")}</div>
              </button>
            </div>
          </div>
          <div className="background"
            onClick={() => setAccountMenuActive(false)}
          ></div>
        </S.AccountUserMenu>
      }
    </>
  );
}

const ProcessName = ({userToProcesses}) => {
  const { i18n: { language } } = useTranslation("global");

  return (
    <S.ProcessNameContainer>
        {
          userToProcesses.map((userToProcess, index) => {
            return (
              <S.ProcessName 
                last={index === userToProcesses.length - 1} 
                key={userToProcess.process.id}
              >
                <div className="card-selected-patient-process">
                  {P.dataTranslation(userToProcess.process.processName, language)}
                </div>
              </S.ProcessName>
            )
          })
        }
    </S.ProcessNameContainer>
  )
}

export default Sidebar;
