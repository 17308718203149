
import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ListOfAlerts = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px 20px 20px 20px;
  box-sizing: border-box;
  .table-container {
    border: 2px solid #F6F6FF;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    overflow: auto;
    box-shadow: 0px 4px 4px 0px #F6F7F7;
    border-radius: 20px;
    .suggestion-table {
      table-layout: auto;
      border-collapse: collapse;
      width: 100%;
      thead {
        border-bottom: solid #23229D40 0.5px;
      }
      th {
        position: sticky;
        top: 0;
        height: 54px;
        align-items: center;
        color: #8686A9;
        font-size: 19px;
        font-family: "Nunito Sans";
        font-weight: 400;
        text-align: start;
        background-color: white;
        .filter {
          &__button {
            cursor: pointer;
            display: flex;
            align-items: center;
            &--name {
              display: flex;
              align-items: center;
            }
            .check-patient {
              font-size: 20px;
              color: #36369B;
            }
          }
          &__button-content {
            &--name {
              margin-left: 31px;
            }
          } 
          &__icon {
            font-size: 12px;
            margin-right: 5px;  
            color: #36369B;
            &-description {
              color: #3E3E5B;
            }
          }
          &__text {
            font-family: Nunito Sans;
            font-size: 16px;
            color: #3E3E5B;
          }
          &__label-actived {
            color: #707582;
            font-family: Nunito Sans;
            font-size: 12px;         
          }
          &__container {
            padding-left: 20px;
            display: flex;
            align-items: center;
          }
        }
        .management-status-selector {
          width: 217px;
          justify-self: end;
        }
      }
      tbody {
        overflow: auto;
        tr {
          border-bottom: 1px solid #F6F6FF;
        }
        .patient-email {
          text-align: center;
          color: #526479;
          font-size: 16px;
          background-color: #eef1f4;
          border-radius: 5px;
          width: fit-content;
          margin: auto;
        }
        .patient-program {
          font-size: 16px;
          padding: 0px 10px;
          border-radius: 4px;
          background-color: #F7F7FB;
          font-family: Nunito Sans;
          font-size: 14px;
          color: #3E3E5B;
          width: fit-content;
        }
        .patient-date {
          font-family: Nunito Sans;
          font-size: 16px;
          color: #707582;
        }
        .patient-info {
          display: flex;
          align-items: center;
          height: 60px;
          .check-patient {
            font-size: 20px;
            color: #36369B;
            margin-right: 10px;
            cursor: pointer;
            margin-left: 20px;
          }
        }
        .patient-content {
          display: flex;
          /* margin-left: 20px; */
          /* width: 100%; */
          /* justify-content: center; */
          /* margin: 0 15%; */
          align-items: center;
          position: relative;
          width: auto;
          &__info {
            margin-left: 10px;
            &-name-psa {
              color: #1F1F52;
              font-size: 15px;
              font-family: "Nunito Sans";
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              &--selected {
                color: #6C6BCC;
                font-family: "Nunito Sans Bold";
              }
            }
          }
          &__alert-selected {
            position: absolute;
            color: #707582;
            font-size: 12px;
            margin-top: -48px;
            inline-size: max-content;
          }
          &__extra-option {
            position: absolute;
            display: flex;
            align-items: center;
            color: #36369B;
            &-text {
              font-size: 14px;
              inline-size: max-content;
            }
            &-icon {
              margin-left: 5px;
              font-size: 10px;
            }
          }
        }
        .alert-level {
          display: flex;
          align-items: center;
          font-size: 16px;
          &__icon {
            margin-right: 5px;
            font-size: 14px;
          }
        }
        .alert-type {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #3E3E5B;
          &__icon {
            margin-right: 5px;
            font-size: 14px;
          }
        }
        .alert-description {
          font-size: 16px;
          color: #1F1F52;
          align-items: center;
          display: flex;
          font-family: Nunito Sans;
          b {
            font-family: Nunito Sans;
          } 
          &__icon {
            margin-right: 5px;
            display: flex;
            font-size: 14px;
            color: #3E3DA3;
          }
        }
        .alert-date {
          color: #707582;
        }
        .alert-management {
          justify-content: end;
          width: 100%;
          display: flex;
          align-items: center;
          &__status {
            margin-right: 7px;
            color: #707582;
            display: flex;
            &-text {
              color: #3E3E5B;
            }
            &-icon {
              color: #3E3E5B;
              margin-left: 10px;
              font-size: 15px;
            }
          }
          .alert-options{
            background-color: #F0F0FF;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            cursor: pointer;
            justify-content: center;
            display: flex;
            align-items: center;
            margin-right: 20px;
            &_icon {
              font-size: 15px;
              color: #36369B;
            }
          }          
        }
      }
      td {
        box-sizing: border-box;
      }
      
    }
    .zaia-reaction {
      width: 100%;
      height: 100%;
      display: flex;
    }
    
  }
`;

export const FiltersAlerts = styled.div`

`;


export default ListOfAlerts;