import React, { useEffect, useMemo } from "react";
import * as S from "./Outcomes.style";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useStateValue } from "../../../contextAPI/StateProvider";

// Components
import SidebarPatients from "../shared/sidebarPatients";
import DasboardOutcomesByPatient from "./components/dasboardOutcomesByPatient";
import SidebarTopPatients from "../patients/components/sidebarTopPatients";
import ZaiaReaction from "../../zaiaComponents/zaiaReaction";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../graphql/queries/Process";

// pipes
import * as P from "../../../utils/pipes";
import usePatientList from "../../../hooks/usePatientList";

// hooks
import usePatientVerification from "../../../hooks/usePatientVerification";


// Assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function Outcomes() {
  const { t, i18n: { language } }= useTranslation("global");
  const navigate = useNavigate();
  const { processNameKey, patientID } = useParams();
  const [{ selectedPatient }, dispatch] = useStateValue();
  const panelActive = (
    window.location.href.split('?')[1]?.split('=')[0] === "panelActive" && 
    window.location.href.split('?')[1].split('=')[1] 
  )
  ? window.location.href.split('?')[1].split('=')[1] 
  : null;

  const { data: processData } = useQuery( 
    QUERY_GET_PROCESS_NAMES, 
    { 
      variables: { 
        whereFilter: 
        selectedPatient 
        ? { 
          userToProcesses: { 
            userId: { 
              _in: [ selectedPatient?.id ]
            }
          }
        } 
        : null
      }
    } 
  );

  const process = useMemo(() => {
    if(processNameKey) return processData?.process.find((process) => process.nameKey === processNameKey)
  }, [processData, processNameKey])
  
  const { patientValid, loadingValidation }  = usePatientVerification(patientID, process?.id);
  
  useEffect(() => {
    if(patientValid === false && !loadingValidation && processNameKey) navigate('/outcomes/' + processNameKey);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientValid, loadingValidation])

  useEffect(() => {
    if(processData && processNameKey && !process?.id) {
      navigate('/outcomes')
    }
    if(process?.id) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: process?.id
      });
    }

    if(!process?.id && !processNameKey) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: null
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process?.id, processData])

  useEffect(() => {
    if(patientID) {
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: patientID,
      });
    } else {
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: null,
      });
    }
  }, [patientID])

  return (
    <S.OutcomesDiv selectedPatient={selectedPatient}>
      { 
        process?.id ?
        <div className="outcomes-container">
          {
            selectedPatient ? null : 
            <div className="outcomes-container__sidebar-patients">
              <SidebarPatients 
                buttonHide={true} 
                defaultHide={
                  panelActive !== null && 
                  String(panelActive).toLowerCase() !== "true"
                } 
                heightTop="140px" 
                urlRedirection={`/outcomes/${processNameKey}`} 
                processIdFilter={process?.id}
              >
                <SidebarTopPatients title={t("patients.patientList.select")} activeFilterProcess={false}/>
              </SidebarPatients>
            </div>
          }
          <div className="outcomes-container__info-content">
            { patientID ?  
              <DasboardOutcomesByPatient  
                patientID={patientID} 
                processID={process?.id} 
                processNameKey={processNameKey} 
                processToCustomFormID={process?.processToCustomForms[0]?.customFormId}/> :
                <S.OutcomesEmpty>
                  <ZaiaReaction
                    // zaiaImg={logoZaiaOutcomes}
                    widthImg="200px"
                    text={t("outcomes.selectPatient")}
                    sizeText="20px"
                    widthText="240px"
                  />
                </S.OutcomesEmpty>
            }
          </div>
        </div> : 
        <S.ProgramBoxes>
          <div className="reaction-content">
            <ZaiaReaction 
              zaiaImg={logoZaiaEmpty}
              text={t("algorithms.title")}
              sizeText="20px"
              textColor="var(--blue-color)"
              widthImg="110px"
            />
          </div>
          <div className="algorithms__containerBoxes" >
            {
              processData?.process.map((process, index) => {
                return (
                  <div 
                    className="box-container" 
                    key={process.nameKey}
                  >
                    <Link to={selectedPatient ? `/outcomes/${process.nameKey}/${selectedPatient.id}` : `/outcomes/${process.nameKey}`}>
                        <Box
                          icon={process.icon}
                          title={P.dataTranslation(process.processName, language)}
                          process={process}
                        />
                    </Link>
                  </div>
                )
              }) 
            }
          </div>
        </S.ProgramBoxes>
      }
    </S.OutcomesDiv>
  );
}

const Box = ({ icon, title, process }) => {
  const [t] = useTranslation("global");

  const {patientQuantity} = usePatientList(process.id);

  return (
    <div>
      <S.BoxDiv>
        <div className="box__iconContainer">
          <i className={`icon ${icon}`} />
        </div>
        <h5>{title}</h5>
        <div className="box__patients">
          <div className="box__patients-number">{patientQuantity}</div>
          <div className="box__patients-text">{t("globally.patients")}</div>
        </div>
      </S.BoxDiv>
    </div>
  );
};

export default Outcomes;
