import styled from "styled-components";
import { getHexOpacity } from "../../utils/pipes";

export const Private = styled.div`
  .private {
    height: ${({webView}) => webView ? '100dvh' : '100svh'};
    /* display: flex; */
    position: relative;
    &__sidebar {
      position: relative;
      z-index: 300;
      height: 74px;
      width: 100%;
    }
    &__right {
      position: relative;
      z-index: 200;
      width: 100%;
      height: ${({police}) => police ? "100dvh" : "calc(100dvh - 74px)"};
      /* background-color: ${({theme}) => theme.primaryColor}; */
      &-header{
        position: relative;
        z-index: 200;
        width: 100%;
        height: 70px;
        background-color: ${({theme}) => theme.primaryVariantColor};
      }      
      &-body {
        position: relative;
        z-index: 100;
        width: 100%;
        height: 100%;
        /* height: ${({webView}) => webView ? 'calc(100dvh - 70px)' : 'calc(100svh - 70px)'}; */
        /* background-color: ${({theme}) => theme.background}; */
        display: flex;
        /* border-bottom-left-radius: 30px; */
        overflow: hidden;
        &-content {
          /* background-color: ${({theme}) => theme.primaryColor};; */
          filter: ${({activeModal}) => activeModal ? 'blur(4px)': ''};
          height: 100%;
          width: 100%;
        }
        &-modal {
          height: 100%;
          width: 100%;
          position: absolute;
          background-color: ${({theme}) => `${theme.background}${getHexOpacity(0.8)}`};
        }
      }
    }
    
    .background-extra-shortcuts {
      background-color: #FFFFFFC2;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 400;
    }
    &__shortcuts {
      position: absolute;
      z-index: 400;
      /* border-radius: 30px; */
      /* width: 100%;
      height: 100%; */
      /* background-color: #433D4D; */
      .shortcuts {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 0 30px 42px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
      }
      .extra-shortcut {
        display: flex;
        flex-direction: column;
        align-items: end;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 32px 33.185185px #fff;
      }
      .shortcut {
        cursor: pointer;
        display: flex;
        margin-top: 20px;
        align-items: center;
        &__info {
          display: flex;
          flex-direction: column;
          align-items: end;
          margin-right: 20px;
        }
        &__title {
          color: var(--Night-blue, #1F1F52);
          font-family: "Nunito Sans SemiBold";
          font-size: 17px;
        }
        &__description {
          color: var(--Night-blue, #1F1F52);
          font-family: "Nunito Sans Light";
          font-size: 15px;
        }
        &__button {
          width: 49px;
          height: 49px;
          border: none;
          cursor: pointer;
          outline: none;
          background-color: #FFFFFF;
          display: flex;
          box-shadow: 0px 0px 23.592592239379883px -1.814814805984497px #23229D40;
          border-radius: 50%;
          &-icon {
            font-size: 17px;
            color: white;
            margin: auto;
            display: flex;
          }
        }
      }
      .button-action {
        margin-top: 20px;
        width: 49px;
        height: 49px;
        border: none;
        outline: none;
        background-color: #FFFFFF;
        display: flex;
        cursor: pointer;
        box-shadow: 0px 0px 23.592592239379883px -1.814814805984497px #23229D40;
        border-radius: 50%;
        margin-top: 20px;
        flex-direction: column;
        &__content {
          margin: auto;
          justify-content: center;
        }
        &__icon {
          display: flex;
          margin: auto;
          color: ${({theme}) => theme.primaryColor};
          &--notes {
            /* margin: none; */
            justify-content: center;
            font-size: 18px;
            margin-top: 3px;
          }
        }
        &__text {
          font-family: Nunito Sans;
          font-weight: 800;
          font-size: 8px;
          color: #3E3DA3;
          margin-top: 3px;
        }
      }

      .notes {
        &__container {
          position: fixed;
          bottom: 0;
          right: ${({maxViewActived, minimizeActived}) => maxViewActived && !minimizeActived ? "0px" : "100px"};
          height: ${({maxViewActived, minimizeActived}) => maxViewActived && !minimizeActived ? "90%" : "auto"};
          width: ${({maxViewActived, minimizeActived}) => maxViewActived && !minimizeActived ? "100%" : "auto"};
          display: flex;
        }
      }
      
      .iachatbot {
        /* &__button { */
          width: 49px;
          height: 49px;
          border: none;
          outline: none;
          background-color: #FFFFFF;
          display: flex;
          cursor: pointer;
          box-shadow: 0px 0px 23.592592239379883px -1.814814805984497px #23229D40;
          border-radius: 50%;
          margin-top: 20px;
          position: relative;
          &--medpalm {
            background-color: #D2D2FF;
          }
          &__button-logo {
            margin: auto;
            padding-left: 1px;
            height: 27px;
            border-radius: 50%;
            display: flex;
            color: ${({theme}) => theme.onPrimaryColor};
          }
          &__button-florence-medpalm-logo {
            background-color: white;
            position: absolute;
            right: -7px;
            bottom: -7px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            box-shadow: 0px 0px 18.677467346191406px -1.4367283582687378px #23229D40;
            justify-content: center;
            &-img {
              width: 19px;
            }
          }
          /* &-chatgpt-icon {
            display: flex;
            margin: auto;
            font-size: 20px;
          } */
        /* } */

        &__container {
          position: fixed;
          bottom: 0;
          right: 100px;
          display: flex;
          right: 110px;
          height: ${({monacoMaxViewActived, monacoMinimizeActived}) => monacoMaxViewActived && !monacoMinimizeActived ? "90%" : "auto"};
          width: ${({monacoMaxViewActived, monacoMinimizeActived}) => monacoMaxViewActived && !monacoMinimizeActived ? "calc(100% - 200px)" : "auto"};
        }

        &__box {
          width: ${({monacoMaxViewActived}) => monacoMaxViewActived ? "100%" : "586px"};
          /* width: 426px; */
          display: flex;
          flex-direction: column;
          box-shadow: 0px 0px 20px -2px rgba(35, 34, 157, 0.25);
          border-radius: 14px 14px 0px 0px;
          position: relative;
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    .follow-up {
      position: absolute;
      z-index: 400;
    }

  }


  @media only screen and (max-width: 768px) {
    .iachatbot {
      &__container {
        width: 100% !important;
      }
    }
  }


  @media only screen and (max-width: 1124px) {
    .private {
      /* &__right {
        width: 100%;
        background-color: ${({theme}) => `${theme.background}`};
        &-header{
          background-color: ${({theme}) => `${theme.background}`};
        }        
        &-body {
          background-color: ${({theme}) => `${theme.background}`};
          border-bottom-left-radius: 0px;
        }
      }

      &__iachatbot {
        .iachatbot {
          &__button {
            top: 0;
            background-color: transparent;
            transform: translate(53px,10px);
          }
        }
      } */

    }
  }

`;