import styled from "styled-components";

export const HealthIndicatorMeasure = styled.div`
	.title {
		color: #3E3DA3;
		font-family: "Nunito Sans";
		font-size: 16px;
		padding: 20px 10px;
	}
	.measure-inputs {
		margin-top: 30px;
		.input-space {
      position: relative;
      &--dose {
        margin-top: 30px;
      }
      .input-label {
        color: #3E3E5B;
        font-family: "Nunito Sans";
        font-size: 14px;
        margin: 0px 10px 10px 10px;
      }
      .input-content {
        display: flex;
        &--disabled {
          opacity: 0.5;
        }
        .unit-list {
          display: flex;
          .unit-item {
            border-radius: 14px;
            border: none;
            border-bottom: 1px solid #6C6BCC;
            background: #FFF;
            display: flex;
            color: #6C6BCC;
            font-family: Nunito Sans;
            justify-content: center;
            font-size: 19px;
            cursor: pointer;
            align-items: center;
            min-width: 65px;
            margin-left: 10px;
            &__circle {
              background-color: transparent;
              border-radius: 50%;
              width: 8px;
              height: 8px;
              border: 2px solid #6C6BCC;
              margin-right: 5px;
              &--selected {
                border: 2px solid #FFF;
              }
            }
            &--selected {
              background-color: #6C6BCC;
              color: #FFF
            }
          }
        }
      }
      .input-msg {
        color: #8C8DA4;
        font-family: "Nunito Sans";
        font-size: 13px;
        padding: 10px;
      }
      &__icon{
        position: absolute;
        z-index: 1;
        right: 0;
        margin: 20px 26px 20px 0;
        color: #6C6BCC;
        font-size: 21px;
      }
      .input-container {
        border: none;
        border-bottom: 1px solid #6C6BCC;
        input {
        font-family: "Nunito Sans Bold";
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
      }
      
		}
	}
`

export const Pressures = styled.div`
  .inputs {
    display: flex;
    :not(:last-child) {
      margin-right: 10px;
    }
    .input-content {
      flex-direction: column;
    }
    .input-label {
      color: #3E3DA3;
    }
  }
  .pressure-result {
    font-size: 17px;
    color: #6C6BCC;
    margin-top: 20px;
    justify-content: end;
    display: flex;
    &__measure {
      font-family: "Nunito Sans Bold";
      margin-left: 5px;
    }
  }
`

export const PainScale = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 77px;
  width: 315px;
  .img-container {
    height: 220px;
    margin-bottom: 30px;
    margin-right: 52px;
    &--last {
      margin-right: 0;
      margin-left: 0px;
    }
  }
  .slider-container {
    width: 100%;
    display: flex;
    .input-range {
      box-sizing: border-box;
      width: 100%;
      -webkit-appearance: none; 
      height: 5px; 
      background: grey; 
      border-radius: 43px;
      background: linear-gradient(90deg, #63CEEE -1.3%, #5BCA5F 18.42%, #FFE777 40.4%, #FEC53B 61.85%, #FF6D2B 82.78%, #D12349 99.99%);
      &::-webkit-slider-thumb { 
        -webkit-appearance: none; 
        height: 42px; 
        width: 42px; 
        border-radius: 50%; 
        background: radial-gradient(50% 50% at 50% 50%, ${({secondaryBallColor}) => secondaryBallColor} 0%, ${({primaryBallColor}) => primaryBallColor} 100%);
        cursor: pointer; 
        box-shadow: 0 0 2px 0 #555; 
      }
      &::-webkit-slider-runnable-track { 
        -webkit-appearance: none; 
        box-shadow: none; 
        border: none; 
        background: transparent; 
      }
    }
  }
  .slider-numbers {
    display: flex;
    justify-content: space-between;
    color: #3E3DA3;
    font-family: "Nunito Sans";
    font-size: 17.455px;
    width: 100%;
    margin-top: 30px;
    padding: 0 10px 0 16px;
    box-sizing: border-box;
  }
`