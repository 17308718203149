import { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Routes } from "../../utils/constants";

const env = process.env.REACT_APP_ENV;

const PublicRoute = ({ component: Component, authed, ...rest }) => {
  const { AINAVIGATOR } = Routes;

  useEffect(() => {
    if (env === 'production') {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [Component])

  return authed === false ? (
      <Component />
    ) : (
      <Navigate to={AINAVIGATOR.route} replace />
    );
};

export default PublicRoute;
