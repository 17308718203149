import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListOfAlerts.Style";
import { Actions } from "../../../../../utils/actionsIds";

// Pipes
import * as P from "../../../../../utils/pipes";
import Popup from "reactjs-popup";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-alerts.svg`;
const alertsEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/list-alerts-empty.svg`;

function ListOfAlerts({
}) {

  const [filterAZ, setFilterAZ] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterAlertLevel, setFilterAlertLevel] = useState(null);
  const [filterAlertType, setFilterAlertType] = useState(null);
  const [commentsOnly, setCommentsOnly] = useState(false);
  const [managementStatusSelected, setManagementStatusSelected] = useState(null);

  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  const managementStatus = [
    {
      value: 1,
      text: "Gestionada",
      icon: "zaia-i-check-1"
    },
    {
      value: 2,
      text: "No gestionada",
      icon: "zaia-i-close"
    }
  ]

  return (
    <S.ListOfAlerts>
      <div className="table-container">
        <table className="suggestion-table">
          <thead className="suggestion-table__head">
            <tr>
              <th className="filter filter--patient-name"> 
                <div className="filter__container">
                  {
                    true 
                    ? <i className="check-patient icon zaia-i-checkbox-off"></i>
                    : <i className="check-patient icon zaia-i-checkbox-on"></i>
                  }
                  <div className="filter__button-content filter__button-content--name">
                    <div 
                      className={`filter__button filter__button--name ${filterAZ !== null && "filter__button--actived"}`}
                      onClick={() => setFilterAZ(
                        filterAZ === null ? true : (filterAZ === false ? null : false)
                      )}
                    >
                      <i className={`filter__icon icon zaia-i-name-sort`}></i>
                      <div className="filter__text">
                        ~Nombre
                      </div>
                    </div>
                    <div className="filter__label-actived filter__label-actived--name">
                      {
                        filterAZ === null
                        ? ""
                        : filterAZ === false 
                          ? `${t("aiNavigator.filters.orderAZ")} Z-A`
                          : `${t("aiNavigator.filters.orderAZ")} A-Z`
                      }
                    </div>
                  </div>
                </div>
              </th>
              <th className="filter filter--program">
                <Popup
                  trigger={
                    <div className={`filter__button ${filterDate !== null && "filter__button--actived"}`}>
                      <i className="filter__icon icon zaia-i-especilidad-sort"></i>
                      <div className="filter__text">
                        ~Nivel del alerta
                      </div>
                    </div>
                  }
                  closeOnDocumentClick
                  keepTooltipInside
                  position={["bottom center"]}
                  nested
                >
                  {
                    close => (
                      <FilterAlerts
                        close={close}
                        filterAlertLevel={filterAlertLevel}
                        setFilterAlertLevel={setFilterAlertLevel}
                        filterAlertType={filterAlertType}
                        setFilterAlertType={setFilterAlertType}
                        commentsOnly={commentsOnly}
                        setCommentsOnly={setCommentsOnly}
                      />
                    )
                  }
                </Popup>                   
              </th>
              <th className="filter filter--program">
                <Popup
                  trigger={
                    <div className={`filter__button ${filterDate !== null && "filter__button--actived"}`}>
                      <i className="filter__icon icon zaia-i-especilidad-sort"></i>
                      <div className="filter__text">
                        ~tipo del alerta
                      </div>
                    </div>
                  }
                  closeOnDocumentClick
                  keepTooltipInside
                  position={["bottom center"]}
                  nested
                >
                  {
                    close => (
                      <FilterAlerts
                        close={close}
                        filterAlertLevel={filterAlertLevel}
                        setFilterAlertLevel={setFilterAlertLevel}
                        filterAlertType={filterAlertType}
                        setFilterAlertType={setFilterAlertType}
                        commentsOnly={commentsOnly}
                        setCommentsOnly={setCommentsOnly}
                      />
                    )
                  }
                </Popup>                   
              </th>
              <th className="filter">
                <div className="filter__button">
                  <i className="filter__icon filter__icon-description icon zaia-icono-menu"/>
                  <div className="filter__text">~Descripción de alerta</div>
                </div>
              </th>
              <th className="filter filter--date">
                <div className="filter__container">
                  <div className="filter__button-content">
                    <div 
                      className={`filter__button ${filterDate !== null && "filter__button--actived"}`}
                      onClick={() => setFilterDate(
                        filterDate === null ? true : (filterDate === false ? null : false)
                      )}
                    >
                      <i className="filter__icon icon zaia-i-name-sort"></i>
                      <div className="filter__text">
                        ~Date
                      </div>
                    </div>
                    <div className="filter__label-actived">
                      {
                        filterDate === null
                        ? ""
                        : filterDate === true
                          ? t("aiNavigator.filters.mostRecent")
                          : t("aiNavigator.filters.oldest")
                      }
                    </div>
                  </div>
                </div>
              </th>
              <th>
                {
                  false &&
                  <div className="management-status-selector">
                    <ZaiaSelect
                      type="select"
                      setValue={(e) => {
                        setManagementStatusSelected(e)
                      }}
                      value={managementStatusSelected}
                      items={managementStatus.map(
                        (status) => {
                          return {
                            value: status?.value,
                            text: status?.text
                          }
                        }
                      )}
                      shadow={false}
                      placeholder={"~Estado de gestión"}
                      fontSize="14px"
                      backgroundColor={"#F0F0FF"}
                      borderRadius={"10px"}
                      heightList={"200px"}
                      color={"#36369B"}
                      textColor={"##36369B"}
                      arrowColor={"##36369B"}
                      borderColor={"transparent"}
                      textItemsColor={"##36369B"}
                      placeholderTextColor={"#36369B"}
                    />
                  </div>
                }
              </th>
            </tr>
          </thead>
          {
            false &&
            <div className="suggestion-table__reaction">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                widthImg="100px"
                text={t("globally.errorLoadingPatients")}
                sizeText="15px"
                widthText="240px"
              />
            </div>
          }
          {
            false &&
            <div className="suggestion-table__reaction">
              <ZaiaReaction
                widthImg="150px"
                zaiaImg={logoZaiaLoading}
              />  
            </div>
          }
          {
            false &&
            <div className="suggestion-table__reaction">
              <div className="reaction-empty">
                <img 
                  className="reaction-empty__img"
                  src={alertsEmpty}
                  alt=""
                />
                <div className="reaction-empty__text">No hay alertas para revisar por el momento</div>
                <button className="reaction-empty__button">
                  Revisar historial de alertas gestionadas
                </button>
              </div>
            </div>
          }
          <tbody className="suggestion-table__body">
            { 
              [1,2,3,4,5,6,7,8,9]?.map((item) => {
                return (
                  <tr 
                    onClick={
                      () => {}
                    }
                    key={item.id}
                    className="suggestion-table__item">
                    <td className="patient-info">
                      {
                        true
                        ? <i className="check-patient icon zaia-i-checkbox-on"></i>
                        : <i className="check-patient icon zaia-i-checkbox-off"></i>
                      }
                      <div className="patient-content">
                        <ZaiaImage
                          img={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThgMPDV54XVOYrK2qJwF4AEy_jLEac9pVh8Q&s"}
                          alt=""
                          size={31}
                          rounded={true}
                        />
                        <div className="patient-content__info">
                          {
                            false &&
                            <div className="patient-content__alert-selected">
                              <div className="patient-content__alert-selected">~Alerta seleccionada</div>
                            </div>
                          }
                          <div className="patient-content__info-name-psa">
                            {`${"Cameron"} ${"Williamson"}`}
                          </div>
                          {
                            false &&
                            <div className="patient-content__extra-option">
                              <div className="patient-content__extra-option-text">~Filtrar por este paciente</div>
                              <i className="patient-content__extra-option-icon icon zaia-i-especilidad-sort"></i>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="alert-level"
                        style={{
                          color: "#EA3330"
                        }}
                      >
                        <i className="alert-level__icon icon zaia-i-tooltip"></i>
                        <div className="alert-level__text">{"~Alerta Severa"}</div>
                      </div>
                    </td>
                    <td>
                      <div className="alert-type">
                          <i className={`alert-type__icon icon ${"zaia-icono-sintomas"}`}></i>
                          <div className="alert-type__text">{"~Síntoma"}</div>
                        </div>
                      </td>
                    <td>
                      {
                        false &&
                        <div className="alert-description">
                          <div className="alert-description__text">
                            {"~El paciente ha presentado dolor de cabeza"}
                          </div>
                        </div>
                      }
                      {
                        false &&
                        <div className="alert-description">
                          <div className="alert-description__icon icon zaia-i-tooltip"></div>
                          <div className="alert-description__text">
                            <b>{"~Vomito"}</b> {"~Muy severo"}
                          </div>
                        </div>
                      }
                      {
                        true &&
                        <div className="alert-description">
                          <div className="alert-description__icon icon zaia-i-tooltip"></div>
                          <div className="alert-description__text">
                           {"~Temperatura"}  <b>{"~35 °C"}</b>
                          </div>
                        </div>
                      }
                    </td>
                    <td>
                      <div className="alert-date">
                        {"~Feb 11, 2014"}
                      </div>
                    </td>
                    <td>
                      <div className="alert-management">
                        {
                          false &&
                          <div className="alert-management__status">
                            <div className="alert-management__status-text">Gestionada</div>
                            <i className="alert-management__status-icon icon zaia-i-check-1"></i>
                          </div>
                        }
                        <div className="alert-options">
                          <i className="alert-options__icon zaia-icono-dots-vertical"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </S.ListOfAlerts>
  );
}


const FilterAlerts = ({ 
  close,
  filterAlertLevel,
  setFilterAlertLevel,
  filterAlertType,
  setFilterAlertType,
  commentsOnly,
  setCommentsOnly
}) => {

  return(
    <S.FiltersAlerts>
      <div className="filters-box filters-box--left">
        <div className="filters-box__label">Seleccione nivel</div>
        <div className="filters-box__title">Nivel de alerta</div>
        <div className="filters-box__content">
          <div className="levels-list">
            <div className={`level-item ${true && "level-item--actived"}`}>
              <div className="level-item__point"></div>
              <div className="level-item__text">Todos</div>
              <div className="level-item__number">20</div>
            </div>
            {
              [1,2,3,4].map((item) => {
                return (
                  <div className={`level-item ${false && "level-item--actived"}`}>
                    <div className="level-item__point"></div>
                    <div className="level-item__text">Leve</div>
                    <div className="level-item__number">4</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="filters-box filters-box--right">
        <div className="filters-box__label">Seleccione tipo de alerta</div>
        <div className="filters-box__title">Tipo de alerta</div>
        <div className="filters-box__content">
          <div className="types-list">
            <div className="type-item">
              <div className="type-item__circle">
                <div className="type-item__circle-number">
                  20
                </div>
                <div className="type-item__circle-icon icon zaia-icono-home"></div>
              </div>
              <div className="type-item__label">Todos</div>
            </div>
            {
              [1,2,3,4].map((item) => {
                return (
                  <div className="type-item">
                    <div className="type-item__circle">
                      <div className="type-item__circle-number">
                        20
                      </div>
                      <div className="type-item__circle-icon icon zaia-icono-home"></div>
                    </div>
                    <div className="type-item__label">Síntomas</div>
                  </div>
                )
              })
            }
          </div>
          <div className="just-comments">
            <div className="just-comments__left">
              <div className="just-comments__icon icon zaia-i-message-outline"></div>
              <div className="just-comments__text">Sólo comentarios</div>
            </div>
            <div className="just-comments__right">
              <div className="just-comments__switch">
              <ZaiaToggleCircular
                id={'patient-alert-toggle'}
                color={"#27AE60"}
                toggle={(e) => {setCommentsOnly(e)}}
                active={commentsOnly}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.FiltersAlerts>
  )

}

export default ListOfAlerts;