import React, { useEffect, useState } from "react";
import * as S from "./NotesView.style";
import { useTranslation } from "react-i18next";

// Components
import ZaiaImage from "../../zaiaComponents/zaiaImage";
import ListNotes from "./components/listNotes/ListNotes";
import NotesMaxView from "./components/notesMaxView";
import NoteComplete from "./components/noteComplete";
import NoteCreationEditionMaxView from "./components/noteCreationEditionMaxView";
import NoteForm from "./components/noteForm";
import PatientSelection from "./components/patientSelection";
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_TO_PROCESS_HISTORY_BY_PATIENT } from "../../../graphql/queries/UserToProcessHistory";
import { useStateValue } from "../../../contextAPI/StateProvider";

// assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;

function NotesView({
  closeFunc,
  maxHeight,
  maxViewActived,
  setMaxViewActived,
  minimizeActived,
  setMinimizeActived
}) {    
  const {t, i18n: { language }} = useTranslation("global");
  const [noteSelected, setNoteSelected] = useState(false);
  const [patientSelectionActived, setPatientSelectionActived] = useState(false);
  const [createNoteActived, setCreateNoteActived] = useState(false);
  const [editNoteActived, setEditNoteActived] = useState(false);

  const [{
    notesPatient
  }, dispatch] = useStateValue();

  const patientSelection = (patient) => {    
    dispatch({
      type: "SET_NOTES_PATIENT",
      notesPatient: patient,
    });
  }

  if(minimizeActived) {
    return (
      <S.NotesView>
        <div className="notes-minimized">
          <div className="notes-minimized__top">
            <div className="notes-minimized__buttons">
              <button className="circle-button circle-button--close-notes"
                onClick={() => closeFunc(false)}
              >
                <i className="circle-button__icon icon zaia-icono-cerrar"></i>
              </button>
              <button className="circle-button circle-button--maximize-notes"
                onClick={() => setMinimizeActived(false)}
              >
                <i className="circle-button__icon icon zaia-i-up"></i>
              </button>
            </div>
            <div className="notes-minimized__title">{t("notes.medicalNotes")}</div>
          </div>
          <div className="notes-minimized__bottom">
            <ZaiaImage
              img={notesPatient.picture} 
              alt="" 
              size={52} 
              defaultImg={defaultUser} 
              rounded
            />
            <div className="patient-name">{notesPatient.name + " " + notesPatient.lastname}</div>
          </div>
        </div>
      </S.NotesView>
    )
  }

  return (
    <S.NotesView
      maxViewActived={maxViewActived}
    >
      <div className="notes">
        <Header 
          closeFunc={closeFunc}
          maxViewActived={maxViewActived}
          setMaxViewActived={setMaxViewActived}
          minimizeActived={minimizeActived}
          setMinimizeActived={setMinimizeActived}
          patientSelected={notesPatient}
          setPatientSelected={patientSelection}
          setPatientSelectionActived={setPatientSelectionActived}
          setNoteSelected={setNoteSelected}
        />
        <div className="notes-container">
          <NotesContent 
            setCreateNoteActived={setCreateNoteActived}
            createNoteActived={createNoteActived}
            maxViewActived={maxViewActived}
            noteSelected={noteSelected}
            setNoteSelected={setNoteSelected}
            patientSelected={notesPatient}
            setPatientSelected={patientSelection}
            patientSelectionActived={patientSelectionActived}
            setPatientSelectionActived={setPatientSelectionActived}
            editNoteActived={editNoteActived}
            setEditNoteActived={setEditNoteActived}
          />
        </div>
      </div>
    </S.NotesView>
  );
}

const Header = ({ 
  closeFunc, 
  maxViewActived,
  setMaxViewActived,
  setMinimizeActived,
  patientSelected,
  setPatientSelected,
  setPatientSelectionActived,
  setNoteSelected,
}) => {

  const {t, i18n: { language }} = useTranslation("global");
  
  const closeAction = () => {
    closeFunc(false);
    setPatientSelected(null);
    setNoteSelected(false);
    setMinimizeActived(false);
    setMaxViewActived(false);
  }

  return (
    <S.HeaderDiv
      maxViewActived={maxViewActived}
    >
      <div className="header">
        <div className="header__left">
          <div className="header__close-chat" onClick={() => closeAction()}>
            <i className="header__close-chat-icon icon zaia-icono-cerrar" />
          </div>
          {
            patientSelected &&
            <>
              <div className="header__max" onClick={() => setMaxViewActived(!maxViewActived)}>
                {
                  maxViewActived
                  ? <i className="header__max-icon icon zaia-icono-flechas-arriba-abajo ~changeicon" />
                  : <i className="header__max-icon icon zaia-icono-flechas-arriba-abajo ~changeicon" />
                }
              </div>
              <div className="header__minimize"
                onClick={() => setMinimizeActived(true)}
              >
                <i className="header__minimize-icon icon zaia-icono-dropdown-light" />
                <div className="header__minimize-text">{t("globally.minimize")}</div>
              </div>
            </>
          }
        </div>
        <div className="header__right">
          <div className="header__title">
            <i className="header__title-icon icon zaia-document"></i>
            <div className="header__title-text">{
              patientSelected
              ? t("notes.notesFor")
              : t("globally.notes")
            }</div>
          </div>
          {
            patientSelected &&
            <div className="header__patient-selected">
              <div className="header__patient-selected-img">
                <ZaiaImage
                  img={patientSelected.picture} 
                  alt="" 
                  size={26} 
                  defaultImg={defaultUser} 
                  rounded
                />
              </div>
              <div className="header__patient-selected-name">{patientSelected.name + " " + patientSelected.lastname}</div>
              <div className="header__patient-selected-close" 
                onClick={() => {
                  setPatientSelected(null);
                  setPatientSelectionActived(false);
                  setMaxViewActived(false);
                }}
              >
                <i className="header__patient-selected-close-icon icon zaia-icono-cerrar"></i>
              </div>
            </div>
          }
        </div>
      </div>
    </S.HeaderDiv>
  );
};

const NotesContent = ({
  setCreateNoteActived,
  createNoteActived,
  maxViewActived,
  noteSelected,
  setNoteSelected,
  patientSelected,
  setPatientSelected,
  patientSelectionActived,
  setPatientSelectionActived,
  editNoteActived,
  setEditNoteActived
}) => {

  const {t, i18n: { language }} = useTranslation("global");

  const [formNoteText, setFormNoteText] = useState("");
  const [userToProcessSelected, setUserToProcessSelected] = useState(null);

  const {
    data: userToProcessHistory,
    loading: userToProcessHistoryLoading,
    error: userToProcessHistoryError,
    refetch: refetchUserToProcessHistory
  } = useQuery(QUERY_GET_USER_TO_PROCESS_HISTORY_BY_PATIENT, {
    variables: {
      patientId: patientSelected?.id
    },
  });  

  useEffect(() => {
    refetchUserToProcessHistory();
  }, [createNoteActived])

  if(!patientSelected) {
    return <PatientSelection
      patientSelected={patientSelected}
      setPatientSelected={setPatientSelected}
      patientSelectionActived={patientSelectionActived}
      setPatientSelectionActived={setPatientSelectionActived}
    />
  } else {
    if(maxViewActived) {
      if(createNoteActived || editNoteActived) {
        return <NoteCreationEditionMaxView
          setCreateNoteActived={setCreateNoteActived}
          editNoteActived={editNoteActived}
          setEditNoteActived={setEditNoteActived}
          patientSelected={patientSelected}
          formNoteText={formNoteText}
          setFormNoteText={setFormNoteText}
          userToProcessSelected={userToProcessSelected}
          setUserToProcessSelected={setUserToProcessSelected}
        />
      } else {
        return <NotesMaxView
          listNotes={userToProcessHistory?.userToProcessHistory.filter(userToProcessHistory => userToProcessHistory.descript)}
          maxViewActived={maxViewActived}
          setCreateNoteActived={setCreateNoteActived}
          createNoteActived={createNoteActived}
          setNoteSelected={setNoteSelected}
          noteSelected={noteSelected}
          editNoteActived={editNoteActived}
          setEditNoteActived={setEditNoteActived}
          patientSelected={patientSelected}
        />
      }
    } else {
      if(createNoteActived || editNoteActived) {
        return <NoteForm
          maxViewActived={maxViewActived}
          setCreateNoteActived={setCreateNoteActived}
          editNoteActived={editNoteActived}
          setEditNoteActived={setEditNoteActived}
          patientSelected={patientSelected}
          formNoteText={formNoteText}
          setFormNoteText={setFormNoteText}
          userToProcessSelected={userToProcessSelected}
          setUserToProcessSelected={setUserToProcessSelected}
        />
      } else {
        if(noteSelected) {
          return <NoteComplete
            setNoteSelected={setNoteSelected}
            noteSelected={noteSelected}
            setEditNoteActived={setEditNoteActived}
          />
        } else {
          return <ListNotes
            listNotes={userToProcessHistory?.userToProcessHistory.filter(userToProcessHistory => userToProcessHistory.descript)}
            maxViewActived={maxViewActived}
            setCreateNoteActived={setCreateNoteActived}
            createNoteActived={createNoteActived}
            noteSelected={noteSelected}
            setNoteSelected={setNoteSelected}
            setEditNoteActived={setEditNoteActived}
            patientSelected={patientSelected}
          />
        }
      }
    }
  }
}

export default NotesView;
