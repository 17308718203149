import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GlobalStyles } from "./themes";

// Routes
import publicRoutesList from "./routes/publicRoutesList";
import PublicRoute from "./routes/components/PublicRoute";
import privateRoutesList from "./routes/privateRoutesList";

// Components
import Private from "./pages/private"
import NotFound from "./pages/public/foundError"

// SEO
import SEO from "./utils/SEO"

// Supertokens
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react"; 
import { SuperTokensConfig } from "./superTokensConfig";
import ZaiaSplash from "./pages/zaiaComponents/zaiaSplash";
import PrivateRoute from "./routes/components/PrivateRoute";

SuperTokens.init(SuperTokensConfig);

function App({ existToken }) {

  if (existToken === null) {
    return (
      <ZaiaSplash />
    )
  }
  
  return (
    existToken !== null
      && <SuperTokensWrapper>
          <GlobalStyles />
          <Router>
            <SEO/>
            <Routes>    
              <Route element={<Private authed={existToken} />}>
                {privateRoutesList.map((privateRoute, index) => (
                  <Route
                    key={index}
                    path={privateRoute.path}
                    element={<PrivateRoute {...privateRoute} authed={existToken} />}
                  />
                ))}
              </Route>

              {publicRoutesList.map((publicRoute, index) => (
                <Route
                  key={index}
                  path={publicRoute.path}
                  element={<PublicRoute {...publicRoute} authed={existToken} />}
                />
              ))}
                
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </SuperTokensWrapper>
  );
  
}

export default App;
