import styled from "styled-components";
import { getHexOpacity } from "../../../utils/pipes";

export const FollowUpModalClose = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .content {
    z-index: 200;
    background-color: #F0EFF9;
    margin: auto;
    padding: 30px;
    border-radius: 14px;
  }
`

export const FollowUp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .minimized {
    position: fixed;
    bottom: 0;
    left: 31px;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    background-color: red;
    padding: 10px 20px 20px 20px;
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 0px 23.592592239379883px -1.814814805984497px #23229D40;
    background-color: #FCFCFE;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .option-button {
        width: 30px;
        height: 30px;
        border-radius: 34.57px;
        background-color: rgba(109, 109, 185, 0.12);
        color: #36369B;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        margin-right: 10px;
        &__icon {
          font-size: 7.3px;
          margin: auto;
        }
      }
      &__title {
        font-family: "Nunito Sans Bold";
        font-size: 20px;
        color: #3E3DA3;
      }
    }  
    .patient-info {
      display: flex;
      align-items: center;
      &__img-patient {
        min-width: 56px;
        min-height: 56px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0px 2px 6px #0000001A;
        font-size: 8px;
        vertical-align: middle;
        box-shadow: 0px 0px 15px 0px rgba(35, 34, 157, 0.20);
        border: 5px solid #FFF;
      }
      &__name {
        color: var(--TEXT-I, #1F1F52);
        font-family: "Nunito Sans Light";
        font-size: 24.269px;
        margin-left: 10px;
      }
    }  
  }
  .big-screen {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: red;
    padding: 10px 35px 0px 35px;
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 0px 23.592592239379883px -1.814814805984497px #23229D40;
    background-color: #F8F8FF;
    height: 90%;
    width: 100%;
    box-sizing: border-box;
    .header {
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 17px;
      .buttons {
        display: flex;
        .option-button {
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          height: 36px;
          padding: 13.715px;
          justify-content: center;
          align-items: center;
          border-radius: 45.26px;
          background: rgba(109, 109, 185, 0.12);
          border: none;
          outline: none;
          color: #36369B;
          &__icon {
            font-size: 10px;
            &--minimize {
              font-size: 16px;
            }
          }
          &__text {
            font-size: 20px;
            font-family: "Nunito Sans";
            margin-left: 14px;
          }
        }
      }
    }
    .content {
      height: calc(100% - 63px);
      display: flex;
      &__left {
        width: calc(100% - 402px);
        .patient-card {
          margin-bottom: ${({viewTab}) => viewTab === 2 ? "56px" : "20px"} ;
          padding: 20px 26px;
          border-radius: 14px;
          display: flex;
          width: 100%;
          box-sizing: border-box;
          background-color: #FFF;
          justify-content: space-between;
          &__info {
            align-items: center;
            display: flex;
            overflow: hidden;

            .patient-image {
              padding: 6.75px;
              backdrop-filter: blur(8.288250923156738px);
              overflow: hidden;
              box-shadow: 0px 0px 12px 0px rgba(35, 34, 157, 0.20);
              border-radius: 50%;
              height: fit-content;
              margin-right: 20px;
            }
            
            .patient-info {
              overflow: hidden;

              &__name {
                color: #1F1F52;
                font-family: "Nunito Sans Bold";
                font-size: 35.368px;
                margin-bottom: 5px;
                height: 48px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &__program {
                font-family: "Nunito Sans";
                padding: 0px 5px;
                color: #526479;
                font-size: 17px;
                background-color: #EEF1F4;
                width: fit-content;
                border-radius: 4px;
                display: flex;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 1.2em;
                white-space: nowrap;
                margin-bottom: 10px;
                /* strong {
                  margin-right: 10px;
                } */
              }
              &__email {
                color: #1F1F52;
                font-family: "Nunito Sans";
                font-size: 17px;
                margin-bottom: 10px;
              }
              &__shortcuts {
                display: flex;
                .shortcut {
                  cursor: pointer;
                  padding: 10px;
                  border-radius: 10px;
                  background: #EFEEF8;
                  margin-right: 12px;
                  color: #6C6BCC;
                  text-align: center;
                  text-shadow: 0px 0px 11.4px rgba(255, 255, 255, 0.73);
                  font-family: "Nunito Sans";
                  font-size: 12px;
                }
              }
            }
          }

          &__right {
            .numbers {
              display: flex;
              padding: 10px 40px 10px 20px;
              flex-direction: column;
              border-radius: 10px;
              background: #F0EFF9;
              /* height: 107px; */
              box-sizing: border-box;
              &__cellphone {
                color: #1F1F52;
                font-family: "Nunito Sans";
                font-size: 14px;
                margin-top: 20px;
              }
              &__title {
                display: flex;
                margin-bottom: 10px;
                &-text {
                  color: #1F1F52;
                  font-family: "Nunito Sans Bold";
                  font-size: 14px;
                  margin-right: 10px
                }
                &-icon {
                  font-size: 15px;
                }
              }
              .number {
                margin-top: 10px;
                &__title {
                  font-family: Nunito Sans;
                  font-size: 12px;
                  font-weight: 300;
                  color: #4A4A75; 
                }
                &__phone {
                  font-family: Nunito Sans;
                  font-size: 14px;
                  font-weight: 300;
                  color: #1F1F52;
                }
              }
            }
            .button-email {
              display: flex;
              width: 244px;
              margin-top: 10px;
              padding: 10px 20px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 10px;
              background: #6C6BCC;
              color: #FFF;
              text-align: center;
              box-shadow: 0px 0px 10px -3.111px rgba(35, 34, 157, 0.25);
              border: none;
              outline: none;
              cursor: pointer;
              &__text {
                font-family: "Nunito Sans Light";
                font-size: 14px;
              }
            }
          }
        }
        .principal-content {
          height: calc(100% - 245px);
          /* border-radius: 14px 14px 0px 0px; */
          /* padding: 40px 20px 0 20px; */
          display: flex;
          flex-direction: column;
          overflow: auto;
          &--boxes {
            height: calc(100% - 198.39px);
          }
          .card-list {
            margin: 0px 20px 0 20px;
            height: 100%;
            overflow: auto;
            padding: 20px 0;
            box-sizing: border-box;
          }
        }
        
      }
      &__right {
        width: 382px;
        margin-left: 20px;
        margin-bottom: 20px;
        border-radius: 14px;
        background: #FFF;
        .title {
          padding: 25px 20px 0 20px;
          align-items: center;
          display: flex;
          margin-bottom: 20px;
          &__icon {
            font-size: 15px;
            margin-right: 10px;
          }
          &__text {
            color: #707582;
            font-family: "Nunito Sans";
            font-size: 20px;
          }
        }
        .comments-list {
          padding: 0 10px 0 20px;
          height: calc(100% - 72px);
          overflow: auto;
          display: flex;
          flex-direction: column;
          &__last-follow-up-text {
            color: #707582;
            font-family: "Nunito Sans Bold";
            font-size: 30px;
            margin: 0px 25px 5px 25px;
          }
          .comment-card {
            display: flex;
            padding: 20px;
            flex-direction: column;
            border-radius: 14px;
            background: #FFF;
            box-shadow: 0px 0px 18.3px -6px rgba(56, 55, 156, 0.29);
            margin-bottom: 15px;
            &__time {
              justify-content: space-between;
              display: flex;
              color: #878792;
              font-family: "Nunito Sans Bold";
              font-size: 16px;
            }
            &__text {
              color: #3E3E5B;
              font-family: "Nunito Sans";
              font-weight: 300;
              font-size: 16px;
            }
          }
          .last-comment-separator {
            margin: 25px 0 25px;
            width: 100%;
            border-bottom: 1px solid #DEDEDE;
          }
        }
      }
    }
  }
`;

export const PatientNamePopup = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(35, 34, 157, 0.14);
  padding: 3px 10px;
`

export const FollowUpCard = styled.div`
  display: flex;
  padding: 25px 20px 25px 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 14px;
  background: #F7FAFF;
  box-shadow: 0px 0px 10px 0px rgba(35, 34, 157, 0.14);
  border-left: 20px solid ${({color}) => color};
  margin-bottom: 20px;
  .status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__text {
      color: ${({color}) => color};
      font-family: "Nunito Sans Bold";
      font-size: 20px;
      text-transform: capitalize;
    }
    .outcomes-tabs {
      padding: 5px;
      border-radius: 28px;
      background-color: #F0EFF9;
      display: flex;
      .outcomes-tab {
        cursor: pointer;
        color: #3E3E5B;
        font-family: Nunito Sans;
        font-size: 17px;
        padding: 5px 20px 5px 20px;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &--selected {
          border-radius: 32px;
          background-color: #FFFFFF;
          color: #1F1F52;
          font-family: Nunito Sans Bold;
        }
      }
    }
  }
  .card-content {
    width: 100%;
    .info-card {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &__left {
        align-items: center;
        display: flex;
        color: #3E3E5B; 
        .calendar-icon {
          font-size: 15px;
          margin-right: 8px;  
        }
        .card-info {
          &__status-large {
            color: #3E3E5B;
          }
        }
        .change-date-action {
          font-family: Nunito Sans;
          font-size: 17px;
          color: #36369B;
          margin-left: 10px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &__right {
        color: ${({color}) => color};
        font-family: "Nunito Sans Bold";
      }
    }
  }
  .button-outcommes {
    margin-top: 12px;
    width: 70%;
    .fill-outcomes {
      width: 100%;
      height: 47px;
      padding: 10px 20px 10px 20px;
      border-radius: 70px;
      border: 1px solid #3E3DA3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      color: #3E3DA3;
      font-size: 13px;
      font-family: Nunito Sans;
      cursor: pointer;
    }
  }
  .add-comment {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    .input-container {
      display: flex;
      outline: none;
      padding: 9px 20px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      background: #FFF;
      font-family: "Nunito Sans";
      border: none;
      border-bottom: 1px solid ${({descriptionWarning}) => descriptionWarning ? "#FF0000" : "#A7A7D5"};
      border-radius: 0px;
      width: 100%;
      margin-bottom: 10px;
      textarea {
        height: 22px;
        font-size: 16px;
        &::placeholder {
          font-size: 16px;
        }
      }
    }
    &__button-container{
      width: fit-content;
      align-self: end;
      button {
        width: 100%;
        margin-left: 0px;
      }
    }
    &__msg {
      font-family: Nunito Sans;
      font-size: 15px;
      color: #8C8DA4;
      margin-top: 6px;
      .required {
        color:#FF0000;
        display: inline-flex;
      }
    }
    &__buttons {
      display: flex;
      margin-left: auto;
    }
    &__button {
      display: flex;
      width: 113px;
      height: 37px;
      padding: 10.141px 5.071px;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      border: none;
      margin-left: 10px;
      cursor: pointer;
      font-size: 16px;
      &--cancel {
        background: transparent;
        font-family: "Nunito Sans"; 
        color: #36369B;
      }
      &--comment {
        background: #F2F2F2;
        font-family: "Nunito Sans Bold"; 
        color: #909090;
      }
    }
  }
  .change-date {
    width: 100%;
    /* display: flex; */
    /* align-items: center; */
    .remember {
      font-family: Nunito Sans;
      font-size: 14px;
    }
    &__form{
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
    &__info {
      display: flex;
      align-items: center;
    }
    &__buttons {
      display: flex;
    }
    &__icon {
      font-size: 21px;
      margin: auto 25px;
      color: #59599E;
    }
    .date-container {
      &__title {
        font-family: "Nunito Sans Light";
        font-size: 14px;
        line-height: 19px;
      }
      &__icon {
        margin-right: 21px;
      }
      &__date-box {
        align-items: center;
        padding: 19px 0px;
        display: flex;
        &--new-date {
          cursor: pointer;
          padding: 20px;
          border-radius: 14px;
          background-color: #F0F0FF;
        }
      }
      &__date-input {
        font-family: Nunito Sans;
        font-size: 17px;
        background-color: transparent;
        border: none;
        outline: none;
      }
      &__day {
        font-family: Nunito Sans;
        font-size: 14px;
      }
    }
  }
  /* . */
  .button {
    cursor: pointer;
    display: flex;
    min-width: 202px;
    padding: 7.5px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-family: "Nunito Sans";
    font-size: 16px;
    outline: none;
    &--cancel {
      border: 0.507px solid #36369B;
      color: #36369B;
      background-color: transparent;
    }
    &--disabled {
      background-color: #EEF1F4 !important;
      cursor: default;
      color: #8C8DA4 !important;
    }
    &--mark {
      background: ${({buttonColor}) => buttonColor};
      border: none;
      color: #FFF;
      margin-left: 10px;
    }
    &__icon {
      margin-right: 5px;
      font-size: 15px;
    }
  }
`