import React, { useState, useRef } from "react";
import ReCaptcha from "react-google-recaptcha";
import * as S from "./loginFormStyle";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

// Routes
import { useNavigate, Link } from "react-router-dom";
import { Routes } from "../../../../../utils/constants";

// context
import { useStateValue } from "../../../../../contextAPI/StateProvider";

// components
import ErrorMessage from "../../../errorMessage";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";

// superTokens
import { signIn } from "supertokens-auth-react/recipe/emailpassword";
import ZaiaSplash from "../../../../zaiaComponents/zaiaSplash";
import Modal from "../../../../../utils/modal";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
const env = process.env.REACT_APP_ENV;

function LoginForm() {
  const [t] = useTranslation("global");
  const { AINAVIGATOR } = Routes;
  const navigate = useNavigate();
  const loginCaptchaRef = useRef(null);
  const [{ colorTheme }, dispatch] = useStateValue();
  const [signInPressed, setSignInPressed] = useState(false);
  
  const [errorMsg, setErrorMsg] = useState('');
  const [activeErrorForm, setActiveErrorForm] = useState(false);
  const [supertokensLoginLoading, setSupertokensLoginLoading] = useState();

  async function signInClicked(
    email, 
    password, 
    resetForm, 
    captchaToken
  ) {
    setSupertokensLoginLoading(true);
    try {
      let response = await signIn({
        formFields: [{
          id: "email",
          value: email
        }, 
        {
          id: "password",
          value: password
        }]
      })

      setSupertokensLoginLoading(false);

      if (response.status === "WRONG_CREDENTIALS_ERROR") {
        setErrorMsg(t('home.errorLogin'));
        activateError();
        resetForm();
        if(captchaToken) loginCaptchaRef.current.reset();
        setSignInPressed(false);
      } else {
        dispatch({
          type: "SET_AUTH",
          isAuth: true,
        });
        navigate(AINAVIGATOR.route);

        setSignInPressed(false);
      }
    } catch (err) {
      setSupertokensLoginLoading(false);
      setErrorMsg(t('globally.errorServer'));
      activateError();
      resetForm();
      if(captchaToken) loginCaptchaRef.current.reset();
      setSignInPressed(false);
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    setSignInPressed(true);
    if (env !== 'test' && env !== 'development') {
      const captchaToken = await loginCaptchaRef.current.executeAsync();
      if (captchaToken) {
        loginFunction(values, resetForm, captchaToken);
      } else {
        setErrorMsg(t('globally.errorCaptcha'));
        activateError();
        loginCaptchaRef.current.reset();
        setSignInPressed(false);
      }
    } else {
      loginFunction(values, resetForm);
    }
  };

  const loginFunction = async (values, resetForm, captchaToken) => {
    try {
      await signInClicked(values.email, values.password, resetForm, captchaToken);
    } catch (err) {
      setSupertokensLoginLoading(false);
      setErrorMsg(t('globally.errorServer'));
      activateError();
      resetForm();
      if(captchaToken) loginCaptchaRef.current.reset();
      setSignInPressed(false);
    }
  }

  const activateError = () => {
    setActiveErrorForm(true);
    setTimeout(function() {
      setActiveErrorForm(false);      
      setErrorMsg('');      
    }, 5000);
  }

  return (
    <>
      <Modal open={(signInPressed)}>
        <ZaiaSplash/>
      </Modal>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("register.validation.email"))
            .required(t("register.validation.required")),
            password: Yup.string().required(t("register.validation.password")),
          })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <S.LoginFormDiv>
            <form className="loginForm" data-testid="login-form" onSubmit={props.handleSubmit}>
              <ErrorMessage
                errorMessage={errorMsg}
                activeError={activeErrorForm}
              />
              <h2>{t("home.logIn")}</h2>
              <div>
                <h3 className="title-register login-title">
                  {t("home.email")}
                </h3>
                <ZaiaInputForm
                  placeholder={t("globally.email")}
                  name="email"
                  type="email"
                  icon="icono-email"
                  rightIcon={false}
                />
                <h3 className="title-register login-title">
                  {t("home.password")}
                </h3>
                <ZaiaInputForm
                  placeholder={t("globally.password")}
                  name="password"
                  type="password"
                  icon="icono-contrasena"
                  rightIcon={false}
                />
                <div className="captcha-container">
                  {
                    env !== 'test' && env !== 'development' &&
                    <ReCaptcha
                      ref={loginCaptchaRef}
                      sitekey={reCaptchaKey}
                      size="invisible"
                      theme={colorTheme}
                    />
                  }
                </div>
                <p className="text-center">
                  <Link to="/reset-password">{t("home.forgot")}</Link>
                </p>
                <ZaiaButton
                  // id={'login-button'}
                  type="submit"
                  marginTop="80"
                  marginBottom="30"
                  title={
                    supertokensLoginLoading 
                      ? `${t("globally.loading")}...` 
                      : `${t("home.signIn")}`
                  }
                />
              </div>  
            </form>
          </S.LoginFormDiv>
        )}
      </Formik>
    </>
  );
}

export default LoginForm;
