import React, { useMemo, useState } from "react";
import * as S from "./UserAINavigatorBoxes.style";
import * as P from "../../../../../../utils/pipes";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../../zaiaComponents/zaiaImage";
import ProcessName from "../../../../shared/processName";
import Popup from "reactjs-popup";
import { Actions } from "../../../../../../utils/actionsIds";

// Routes
import { Routes } from "../../../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../../../contextAPI/StateProvider";
import { useEffect } from "react";
import useRecordNavigation from "../../../../../../hooks/useRecordNavigation";
import Modal from "../../../../../../utils/modal";
import ModalAddOutcomesEvent from "../../../../shared/modals/modalAddOutcomesEvent";
import ModalAddEventCalendar from "../../../../agenda/components/modalAddEventCalendar";
import FollowUpStages from "../followUpStages";
import ZaiaSelect from "../../../../../zaiaComponents/zaiaSelect";
import useAssignSelectPatient from "../../../../../../hooks/useAssignSelectPatient";
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_TYPES_BY_IDS } from "../../../../../../graphql/queries/Process";
import { QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER_ID } from "../../../../../../graphql/queries/SideEffects";
import { QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER_ID } from "../../../../../../graphql/queries/VitalSigns";

const aiOutcomes = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/ai-modal-background.svg`;
const florenceSpeechBubble = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/monaco-chat-bubble.svg`;

function UserAINavigatorBoxes({
  patient,
  closeModal,
  followUpInfo,
  followUpMinimizeFunc
}) {


  const {t, i18n: { language }} = useTranslation("global");
  const navigate = useNavigate();
  const { AINAVIGATOR, DASHBOARD, AGENDA } = Routes;
  const [, dispatch] = useStateValue();
  const { recordAction } = useRecordNavigation();
  const [addOutcomesEvent, setAddOutcomesEvent] = useState(false);
  const [addCalendarEvent, setAddCalendarEvent] = useState(false);
  const [activeFollowUpStages, setActiveFollowUpStages] = useState(false);
  const [followUpActiveId, setFollowUpActiveId] = useState(followUpInfo ? followUpInfo[0].id : null);
  const { selectPatient, removePatient } = useAssignSelectPatient();
  const [dateNow] = useState(new Date(Date.now()));
  const [dateAMonthBefore] = useState(new Date(
    new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 30)).setHours(0, 0, 0, 0)
  ))

  const [mildAlertsQuantity, setMildAlertsQuantity] = useState(0);
  const [mediumAlertsQuantity, setMediumAlertsQuantity] = useState(0);
  const [severeAlertsQuantity, setSevereAlertsQuantity] = useState(0);
  
  const { data: processTypes } = useQuery(QUERY_GET_PROCESS_TYPES_BY_IDS, {
    variables: {
      processId: patient?.userToProcesses.map((item) => {return item.process.id})
    }
  });  

  const sideEffects = useMemo(() => {
    let sideEffects1 = [];
    let sideEffects2 = [];
    let sideEffects3 = [];
    processTypes?.process.forEach((process) => {
      process.processToSideEffects.forEach((sideEffect) => {
        if (sideEffect.severity === 1 || sideEffect.severity === 0) {
          
          if(
            !sideEffects1.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects1.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }

          if(
            !sideEffects2.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects2.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }

          if(
            !sideEffects3.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects3.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }
        }
        if (sideEffect.severity === 2 || sideEffect.severity === 3) {

          if(
            !sideEffects2.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects2.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }

          if(
            !sideEffects3.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects3.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }
        }
        if (sideEffect.severity === 4 || sideEffect.severity === 5) {
          if(
            !sideEffects3.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects3.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }
        }
      });
    }); 

    return [sideEffects1, sideEffects2, sideEffects3];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processTypes]);

  const vitalSigns = useMemo(() => {
    const vitalSignsBySeverity = [[], [], []];
  
    processTypes?.process.forEach((process) => {
      process.processToVitalSigns.forEach((vitalSign) => {
  
        const vitalSignFilter = {
          _and: {
            measure1: {},
            vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
            user: {
              userToProcesses: {
                processId: { _eq: process.id }
              }
            }
          }
        };
  
        if (vitalSign.min) {
          vitalSignFilter._and.measure1._gte = vitalSign.min;
        }
  
        if (vitalSign.max) {
          vitalSignFilter._and.measure1._lte = vitalSign.max;
        }
  
        const severity = vitalSign.severity;
        if (severity >= 1 && severity <= 3) {
          vitalSignsBySeverity[severity - 1].push(vitalSignFilter);
        }
      });
    });
  
    return vitalSignsBySeverity;
  }, [processTypes]);

  const {
    data: sideEffectsData,
    loading: sideEffectsDataLoading,
    error: sideEffectsDataError,
  } = useQuery(
    QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER_ID, {
    variables: {
      sideEffects1: sideEffects[0],
      severities1:  [0, 1],
      sideEffects2: sideEffects[1],
      severities2:  [2, 3],
      sideEffects3: sideEffects[2],
      severities3:  [4, 5],
      initialDate: dateAMonthBefore,
      finalDate: dateNow,
      userId: patient.id
    },
  });    

  const { 
      data: vitalSignsData, 
      loading: vitalSignsDataLoading, 
      error: vitalSignsDataError
  } = useQuery(
    QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER_ID, {
    variables: {
      vitalSigns1:  vitalSigns[0],
      vitalSigns2:  vitalSigns[1],
      vitalSigns3:  vitalSigns[2],
      initialDate: dateAMonthBefore,
      finalDate: dateNow,
      userId: patient.id
    },
  });

  useEffect(() => {

    let mildQuantity = 0;
    let mediumQuantity = 0;
    let highQuantity = 0;

    mildQuantity =+ vitalSignsData?.user[0].vitalSigns1Count.aggregate.count;
    mediumQuantity =+ vitalSignsData?.user[0].vitalSigns2Count.aggregate.count;
    highQuantity =+ vitalSignsData?.user[0].vitalSigns3Count.aggregate.count;

    mildQuantity =+ sideEffectsData?.user[0].sideEffects1Count.aggregate.count;
    mediumQuantity =+ sideEffectsData?.user[0].sideEffects2Count.aggregate.count;
    highQuantity =+ sideEffectsData?.user[0].sideEffects3Count.aggregate.count;

    setMildAlertsQuantity(mildQuantity);
    setMediumAlertsQuantity(mediumQuantity);
    setSevereAlertsQuantity(highQuantity);
    
  }, [sideEffectsData, vitalSignsData, dateNow, dateAMonthBefore]); 

  useEffect(() => {
    selectPatient(patient?.id);
  }, [])

  const openFlorence = () => {
    closeModal();
    dispatch({
      type: "SET_FLORENCE_VIEW",
      florenceView: 2,
    });
    dispatch({
      type: "SET_FLORENCE_PATIENT",
      florencePatient: patient,
    });
    dispatch({
      type: "SET_FLORENCE_ACTIVE",
      florenceActive: patient,
    });
  }

  const followUpActive = useMemo(() => {
    if(followUpInfo) {
      if(followUpInfo.length === 1) {
        return followUpInfo[0];
      } else {
        return followUpInfo.find(followUp => followUp.id === followUpActiveId);
      }
    }
  }, [followUpInfo, followUpActiveId])

  const stages = [
    {
      id: 0,
      key: "all",
      name: t("globally.all"),
      color: "#3E3DA3"
    },
    {
      id: 1,
      key: "due",
      name: t("followUp.due"),
      color: "#FF8181",
      hoverColor: "#F15050",
      backgroundColor: "#FFF8F8"
    },
    {
      id: 2,
      key: "today",
      name: t("globally.today"),
      color: "#4DB4FF",
      hoverColor: "#0F9AFF",
      backgroundColor: "#E7F5FF"
    },
    {
      id: 3,
      key: "next",
      name: t("followUp.next"),
      color: "#2ED078",
      hoverColor: "#019947",
      backgroundColor: "#E9F9F0"
    }
  ]

  const currentStage = (nextUpdate) => {
    const nextUpdateDate = new Date(nextUpdate);

    if(
      nextUpdateDate.getDate() === new Date(Date.now()).getDate() &&
      nextUpdateDate.getMonth() === new Date(Date.now()).getMonth() &&
      nextUpdateDate.getFullYear() === new Date(Date.now()).getFullYear()
    ) {
      return stages[2]
    } else if(
      nextUpdateDate > new Date(Date.now())
    ) {
      return stages[3]
    } else {
      return stages[1]
    }
  }

  const daysRemaining = () => {
    const date = new Date(followUpActive?.nextFollowingDate)
    const dateNow = new Date(Date.now())
    const daysRemaining = Math.floor((date - dateNow) / (1000 * 60 * 60 * 24)) + 1
    return Math.abs(daysRemaining)
  }

  return (
    <S.UserAINavigatorBoxes>
      <Modal open={addOutcomesEvent}>
        <ModalAddOutcomesEvent
          setCreateEventActive={setAddOutcomesEvent}
        />
      </Modal>
      <Modal open={addCalendarEvent }>
        <ModalAddEventCalendar
          event={null}
          setCardEventSelected={null}
          setCreateEventCalendarActive={setAddCalendarEvent}
        />
      </Modal>
      {
        activeFollowUpStages
        ? <FollowUpStages
          setActiveFollowUpStages={setActiveFollowUpStages}
          followUpInfo={followUpInfo}
        />
        : <>
          <div className="options-row options-row--first">
            <div className="box box--outcomes"
              onClick={
                () => {
                  navigate(`${AINAVIGATOR.route}/${patient.id}?outcomes=true`); 
                  if(followUpMinimizeFunc) followUpMinimizeFunc(true);
                  recordAction(Actions.AINAVIGATOR_MODAL.GO_OUTCOMES);
                }
              }
            >
              <div className="box__title">
                <div className="box__title-text">{t("globally.outcomes")}</div>
              </div>
              <div className="box__subtitle">{t("aiNavigator.patientModal.outcomesSubtitle")}</div>
              <img src={aiOutcomes} alt="" className="box__image" />
              <i className="box__icon box__icon--outcomes icon zaia-i-check"/>
              {/* <div className="box__buttons">
                <button className="box__button"
                  onClick={
                    () => {
                      navigate(`${AINAVIGATOR.route}/${patient.id}?outcomes=true`); 
                      if(followUpMinimizeFunc) followUpMinimizeFunc(true);
                      recordAction(Actions.AINAVIGATOR_MODAL.GO_OUTCOMES);
                    }
                  }
                >{t("aiNavigator.patientModal.goToOutcomes")}</button>
                <button className="box__button"
                  onClick={() => {
                    recordAction(Actions.AINAVIGATOR_MODAL.EVENT_OUTCOMES_MODAL);
                    setAddOutcomesEvent(true);
                  }}
                >{t("globally.addEvent")}</button>
              </div> */}
            </div>
            <div className="box box--outcomes"
              onClick={
                () => {
                  navigate(`${AGENDA.route}`); 
                  if(followUpMinimizeFunc) followUpMinimizeFunc(true);
                  recordAction(Actions.AINAVIGATOR_MODAL.GO_AGENDA);
                }
              } 
            >
              <div className="box__title">
                <div className="box__title-text">{t("aiNavigator.patientModal.agenda")}</div>
              </div>
              <div className="box__subtitle">{t("aiNavigator.patientModal.agendaSubtitle")}</div>
              <img src={aiOutcomes} alt="" className="box__image" />
              <i src="" alt="" className="box__icon box__icon--calendar icon zaia-i-calendar-2" />
              {/* <div className="box__buttons">
                <button className="box__button"
                  onClick={
                    () => {
                      navigate(`${AGENDA.route}`); 
                      if(followUpMinimizeFunc) followUpMinimizeFunc(true);
                      recordAction(Actions.AINAVIGATOR_MODAL.GO_AGENDA);
                    }
                  } 
                >{t("aiNavigator.patientModal.goToAgenda")}</button>
                <button className="box__button"
                  onClick={() => {
                    setAddCalendarEvent(true);
                    recordAction(Actions.AINAVIGATOR_MODAL.EVENT_AGENDA_MODAL);
                  }}
                >{t("aiNavigator.patientModal.createNewAppointment")}</button>
              </div> */}
            </div>
            <div className="box box--follow-up">
              <div className="box__title"
                onClick={
                  () => {
                    setActiveFollowUpStages(true);
                    recordAction(Actions.AINAVIGATOR_MODAL.GO_FOLLOW_UP);
                  }
                }
              >
                <div className="box__title-text">{t("aiNavigator.treatmentFollowUp")}</div>
              </div>
              <div className="box__program">
                {
                  followUpInfo?.length === 1 &&
                  <>
                  <div className="box__subtitle-program">
                    {t("globally.program")}
                  </div>
                    <div className="box__subtitle box__subtitle--program"
                      onClick={
                        () => {
                          setActiveFollowUpStages(true);
                          recordAction(Actions.AINAVIGATOR_MODAL.GO_FOLLOW_UP);
                        }
                      }
                    >{followUpActive.processName}</div>
                  </>
                }
                {
                  followUpInfo?.length > 1 &&
                  <div className="select-container">
                    <div className="select-container__label">{t("globally.selectProgram")}</div>
                    <ZaiaSelect
                      items={followUpInfo.map((followUp) => ({
                        value: followUp.id,
                        text: followUp.processName,
                      }))}
                      value={followUpActiveId}
                      setValue={(e) => {setFollowUpActiveId(e)}}
                      padding={"5px 10px"}
                      textColor={"#F6F6FF"}
                      borderColor={"#6C6BCC"}
                      fontSize={"17px"}
                      backgroundColor={"#6C6BCC"}
                      borderRadius={"17px"}
                      textItemsColor={"#6C6BCC"}
                    />
                  </div>
                }
                <div className="next-following"
                  onClick={
                    () => {
                      setActiveFollowUpStages(true)
                      recordAction(Actions.AINAVIGATOR_MODAL.GO_FOLLOW_UP);
                    }
                  }
                >
                  <div className="label">{t("followUp.nextFollowUp")}</div>
                  <div className="date-container">
                    <div className="status">
                      <i className="status__icon icon zaia-i-calendar-1"></i>
                      <div className="status__name">
                      {
                        currentStage(followUpActive?.nextFollowingDate).key === 'today' &&
                        <b>
                          {t("globally.today").toUpperCase()}
                        </b>
                      }
                      {
                        currentStage(followUpActive?.nextFollowingDate).key === 'next' &&
                        <div className="date-container__info-state-days-time">
                          {daysRemaining()} {daysRemaining() < 2 ? t("followUp.dayLeft") : t("followUp.daysLeft")}
                        </div>
                      }
                      {
                        currentStage(followUpActive?.nextFollowingDate).key === 'due' &&
                        <>
                          <b>
                            {t("followUp.due").toUpperCase()}
                          </b>
                          <div className="date-container__info-state-days-time">
                            - {daysRemaining()} {daysRemaining() < 2 ? t("followUp.dayLate") : t("followUp.daysLate")}
                          </div>
                        </>
                      }
                      </div>
                    </div>
                    <div className="date">{P.longDate(followUpActive?.nextFollowingDate, t, language)}</div>
                  </div>
                </div>
              </div>
              {/* <div className="other-followings" 
                onClick={
                  () => {
                    setActiveFollowUpStages(true)
                    recordAction(Actions.AINAVIGATOR_MODAL.GO_FOLLOW_UP);
                  }
                }
                style={
                  {
                    justifyContent: followUpActive?.lastFollowingDate ? "space-between" : "center"
                  }
                }
              >
                {
                  followUpActive?.lastFollowingDate &&
                  <div className="following">
                    <div className="label">
                      <div className="label__color" style={{backgroundColor: "#D9D9D9"}}></div>
                      <div className="label__text">{t("followUp.lastFollowUp")}</div>
                    </div>
                    <div className="date">{P.longDate(followUpActive.lastFollowingDate, t, language)}</div>
                  </div>
                }
                <div className="following"
                  style={
                    {
                      alignItems: followUpActive?.lastFollowingDate ? "flex-start" : "center"
                    }
                  }
                >
                  <div className="label">
                    <div className="label__color" style={{backgroundColor: "#28B2ED"}}></div>
                    <div className="label__text">{t("followUp.futureFollowUp")}</div>
                  </div>
                  <div className="date">{P.longDate(followUpActive?.futureFollowingDate, t, language)}</div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="options-row options-row--second" >
            <div className="box box--alerts"
              onClick={
                () => {
                  navigate(`${DASHBOARD.route}?view=2`); 
                  if(followUpMinimizeFunc) followUpMinimizeFunc(true);
                  recordAction(Actions.AINAVIGATOR_MODAL.GO_ALERTS);
                }
              }
            >
              <div className="box__title">
                <div className="box__title-text">{t("globally.alerts")}</div>
                <i className="box__title-icon icon zaia-i-flag"/>
              </div>
              <div className="box__subtitle">{t("aiNavigator.patientModal.alertsSubtitle")}</div>
              <div className="alert-items">
                <div className="alert-item">
                  <i className="alert-item__icon icon zaia-icono-medicina"></i>
                  <div className="alert-item__name">{t("globally.medication")}</div>
                </div>
                <div className="alert-item">
                  <i className="alert-item__icon alert-item__icon--health-indicators icon zaia-icono-signos-vitales"></i>
                  <div className="alert-item__name">{t("globally.healthIndicators")}</div>
                </div>
                <div className="alert-item">
                  <i className="alert-item__icon icon zaia-icono-sintomas"></i>
                  <div className="alert-item__name">{t("globally.symptoms")}</div>
                </div>
              </div>
              <div className="alert-counters">
                <div className="alert-counter alert-counter--first">
                  <div className="alert-counter__color" style={{backgroundColor: "#53C25D"}}></div>
                  <div className="alert-counter__text">{t("globally.mild")}</div>
                  <div className="alert-counter__number">{mildAlertsQuantity || 0}</div>
                </div>
                <div className="alert-counter">
                  <div className="alert-counter__color" style={{backgroundColor: "#FE6330"}}></div>
                  <div className="alert-counter__text">{t("globally.medium")}</div>
                  <div className="alert-counter__number">{mediumAlertsQuantity || 0}</div>
                </div>
                <div className="alert-counter">
                  <div className="alert-counter__color" style={{backgroundColor: "#C92241"}}></div>
                  <div className="alert-counter__text">{t("globally.severe")}</div>
                  <div className="alert-counter__number">{severeAlertsQuantity || 0}</div>
                </div>
              </div>
            </div>
            <div className="box box--health" 
              onClick={
                () => {
                  navigate(`${AINAVIGATOR.route}/${patient.id}`); 
                  if(followUpMinimizeFunc) followUpMinimizeFunc(true);
                  recordAction(Actions.AINAVIGATOR_MODAL.GO_HEALTH);
                }
              }
            >
              <div className="box__title box__title--health">
                <div className="box__title-text">{t("globally.health")}</div>
              </div>
              <div className="box__subtitle">{t("aiNavigator.patientModal.healthSubtitle")}</div>
              <i className="box__icon box__icon--heart icon zaia-i-hearth"/>
            </div>
            <div 
              className="box box--florence" 
              onClick={() => {
                openFlorence();
                recordAction(Actions.AINAVIGATOR_MODAL.GO_FLORENCE);
              }}
            >
              <img src={florenceSpeechBubble} alt="" className="box__florence-image" />
              <div className="box__florence-text">
                <div className="box__florence-title">{t("aiNavigator.patientModal.monaco")}</div>
                <div className="box__florence-subtitle">{t("aiNavigator.patientModal.monacoSubtitle")}</div>
              </div>
            </div>
          </div>
        </>
      }
    </S.UserAINavigatorBoxes>
  );
}

export default UserAINavigatorBoxes;
