import React, { useRef, useState, useEffect, useContext  } from "react";
import * as S from "./ZaiaInput.style.js";
import * as P from "../../../utils/pipes";
import { ThemeContext } from 'styled-components';
import { useTranslation } from "react-i18next";

function  ZaiaInput({ 
  placeholder, 
  icon,
  setErrors,
  padding,
  height,
  fontSize,
  textarea,
  errorOption = true,
  value,
  getText,
  backgroundColor                                                ,
  textColor,
  placeholderTextColor,
  activeBorder=true,
  borderColor,
  borderRadius,
  ...props
}) {  
  const container = useRef(null);
  const textInput = useRef(null);
  const [active, setActive] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const theme = useContext(ThemeContext);

  const {i18n: { language }} = useTranslation("global");
  const [activedMic, setActivedMic] = useState(false);
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const [ recognition ] = useState(SpeechRecognition ? new SpeechRecognition() : null);


  const btnStopRecord = () => {
    recognition.stop();
    setActivedMic(false);
  };

  const btnStartRecord = () => {
    const languageSetting = P.getMicLanguage(language);
    recognition.lang = languageSetting;
    recognition.continuous = true;
    recognition.start();
    recognition.onresult = (event) => {
      const results = event.results;
      const frase = results[results.length - 1][0].transcript;
      value += ' ' + frase;
      getText(value);
    }
    setActivedMic(true);
  };


  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const activeInput = () => {
    if (!props.disabled) {
      textInput.current.focus(); 
      setActive(true);
    } 
  } 

  return (
    <S.ZaiaInput
      padding={padding} 
      active={active} 
      disabled={props.disabled} 
      fontSize={fontSize}
      activedMic={activedMic}
      backgroundColor={backgroundColor                                                }
      textColor={textColor}
      placeholderTextColor={placeholderTextColor}
      activeBorder={activeBorder}
      borderColor={borderColor}
      borderRadius={borderRadius}
      height={height}
    >
        <div className="input-container" onClick={() => activeInput()} ref={container}>
          {
            icon && <i className={'icon-input-form icon zaia-' + icon} style={{ color: active ? theme.inputSelected : theme.inputPlaceholder }} />
          }
          {
            textarea && 
            SpeechRecognition && 
            <>
              <div className='mic-container' onClick={() => !activedMic ? btnStartRecord() : btnStopRecord()}>
                <i className='mic-icon icon zaia-microphone'/>
              </div>
            </>
          }
          {
            textarea &&
            <textarea 
              ref={textInput}
              {...props}
              type={seePass ? "text" : props.type}
              placeholder={placeholder} 
              onChange={(event) => getText(event.target.value)}
              value={value}
            />
          }
          {
            !textarea &&
            <input 
              ref={textInput}
              {...props}
              type={seePass ? "text" : props.type}
              placeholder={placeholder} 
              onChange={(event) => getText(event.target.value)}
              value={value}
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
            />
          }
          {
            props.type === "password" && 
            value &&
            <i 
              className={
                'icon-input-pass icon zaia-' + 
                (
                  seePass 
                  ? "icono-visibility" 
                  : 'icono-invisible'
                )
              } 
              data-testid="button-icon-seepass" 
              onClick={() => setSeePass(!seePass)} 
              style={{ 
                color: seePass 
                ? theme.inputSelected 
                : theme.inputPlaceholder 
              }} 
            />
          }
        </div>
    </S.ZaiaInput>
  );
}

export default ZaiaInput;
