import React, { useMemo, useEffect, useState } from "react";
import * as S from "./VitalsignsBoxInfo.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// pipes
import * as P from "../../../../../../../utils/pipes";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_VITAL_SIGNS_SIMPLE } from "../../../../../../../graphql/queries/VitalSigns";

// components
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import VitalSignTimeLineCard from "../../../../../shared/charts/vitalSignTimeLineCard";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;
const loadingZaia = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function VitalsignsBoxInfo({
  time,
  icon
}) {
  const { t, i18n: { language } } = useTranslation("global");
  const { patientID } = useParams();
  const { 
    data: userToVitalSigns, 
    loading: userToVitalSignsLoading 
  } = useQuery(
    QUERY_GET_USER_VITAL_SIGNS_SIMPLE, {
    variables: { 
      patientId: patientID, 
      startDate: new Date(time?.startDate), 
      finishDate: new Date(time?.finishDate) 
      }
    }
  );
  const [totalPercentage, setTotalPercentage] = useState(0);

  const color = {
    primary: "var(--red-color)",
    secundary: "var(--box-shadow-red-color)",
    dark: "var(--red-box-headercolor)",
  }


  const getListLevels = (range) => {
    const orderRange = range.slice().sort((a, b) => {return b - a});
    return [
      '>' + orderRange[0],
      orderRange[0] + ' - ' + orderRange[1],
      '<' + orderRange[1]
    ]
  }

  const getPercentageInRange = (arrayVitalSigns, range) => {
    const orderRange = range.slice().sort((a, b) => {return b - a});
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const quantityFeelings = arrayVitalSigns?.length;
    const sumFeelings = arrayVitalSigns?.reduce(reducer); 
    const average = (sumFeelings / quantityFeelings);
    const percentage = (orderRange[0] - average)/(orderRange[0] - orderRange[1]) * 100;
    return percentage;
  } 

  const calcCurrentPressure = (pSis, pDias) => {
    return ((pSis + (2*pDias))/3)?.toFixed(2);
  }

  const processVitalSignData = async (info, time, dateAdjustFn, dateIncrementFn) => {
    let vitalSignData = [];

    info?.forEach(vitalSign => {
        let vitalSignDayHistory = [];
        let vitalSignDayHistoryPressure1 = [];
        let vitalSignDayHistoryPressure2 = [];
        let vitalSignPercentage = [];
        const infoJoined = timesJoined(vitalSign?.userVitalSignHistories);
        
        let dateStart = new Date(time.startDate);
        let dateFinish = dateAdjustFn(new Date(time.startDate));
        let dateTemp = new Date(time.startDate);

        while (dateTemp.getTime() !== dateFinish.getTime()) {
            let dateFiltered = infoJoined.filter(info => new Date(info.created_at).getDate() === dateTemp.getDate());
            let percentageInRange = getPercentageInRange(dateFiltered[0]?.measures, vitalSign.comment.range);
            let percentage = dateFiltered.length ? Math.min(percentageInRange, 100) : 0;
            vitalSignPercentage.push({
                day: dateTemp.getDate(),
                percentage: percentage,
            });
            dateTemp = dateIncrementFn(dateTemp);
        }

        vitalSign?.userVitalSignHistories.forEach(vitalSignHistory => {
            vitalSignDayHistory.push([
              new Date(vitalSignHistory.created_at).getTime() - 18000000,
              P.convertVitalSign(vitalSign.value, vitalSignHistory.measure1, vitalSignHistory.unit)
            ]);  
            if(vitalSign.value === "pressure") {
              vitalSignDayHistoryPressure1.push([
                new Date(vitalSignHistory.created_at).getTime() - 18000000,
                P.convertVitalSign(vitalSign.value, vitalSignHistory.measure2, vitalSignHistory.unit)
              ]);       
              vitalSignDayHistoryPressure2.push([
                new Date(vitalSignHistory.created_at).getTime() - 18000000,
                P.convertVitalSign(vitalSign.value, calcCurrentPressure(vitalSignHistory.measure1, vitalSignHistory.measure2), vitalSignHistory.unit)
              ]);
            }
        });

        let historieslength = vitalSign.userVitalSignHistories.length;
        let lastReportDate = historieslength !== 0 ? new Date(vitalSign.userVitalSignHistories[historieslength - 1].created_at).getTime() : 0;


        vitalSignData.push({
          id: vitalSign.value,
          name: P.dataTranslation(vitalSign.comment, language),
          vitalSignPercentage: vitalSignPercentage,
          vitalSignToChart: vitalSignDayHistory,
          listLevels: getListLevels(vitalSign.comment.range),
          minDate: dateStart.getTime(),
          maxDate: dateFinish.getTime(),
          unit: vitalSign.comment.unit[0],
          lastReportDate: lastReportDate,
        });

        if(vitalSign.value === "pressure") {

          vitalSignData.push({
            id: vitalSign.value,
            name: P.dataTranslation(vitalSign.comment, language),
            vitalSignPercentage: vitalSignPercentage,
            vitalSignToChart: vitalSignDayHistoryPressure1,
            listLevels: getListLevels(vitalSign.comment.range),
            minDate: dateStart.getTime(),
            maxDate: dateFinish.getTime(),
            unit: vitalSign.comment.unit[0],
            lastReportDate: lastReportDate,
          });

          vitalSignData.push({
            id: vitalSign.value,
            name: P.dataTranslation(vitalSign.comment, language),
            vitalSignPercentage: vitalSignPercentage,
            vitalSignToChart: vitalSignDayHistoryPressure2,
            listLevels: getListLevels(vitalSign.comment.range),
            minDate: dateStart.getTime(),
            maxDate: dateFinish.getTime(),
            unit: vitalSign.comment.unit[0],
            lastReportDate: lastReportDate,
          });
        }
    });

    getTotalPercentage(vitalSignData);
    return vitalSignData;
  };

  const filterDataByDay = async (info) => {
    return processVitalSignData(
        info,
        time,
        (date) => { date.setHours(23, 59, 59, 999); return date; },  // Ajustar fecha de fin al final del día
        (date) => { date.setDate(date.getDate() + 1); return date; }  // Incrementar un día
    );
  };

  const filterDataByWeek = async (info) => {
      return processVitalSignData(
          info,
          time,
          (date) => { date.setDate(date.getDate() + 7); return date; },  // Ajustar fecha de fin a una semana
          (date) => { date.setDate(date.getDate() + 1); return date; }  // Incrementar un día
      );
  };

  const filterDataByMonth = async (info) => {
      return processVitalSignData(
          info,
          time,
          (date) => { date.setDate(date.getDate() + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()); return date; },
          (date) => { date.setDate(date.getDate() + 1); return date; }  // Incrementar un día
      );
  };


  const timesJoined = (info) => {
    let filteredVitalSigns = []
    info?.forEach(vitalSign => {
      const searchVitalSign = filteredVitalSigns.find(vital => new Date(vital.created_at).getDate() === new Date(vitalSign.created_at).getDate() );
      if (!searchVitalSign) {
        const newVital = { created_at: vitalSign.created_at, measures: [vitalSign.measure1] };
        filteredVitalSigns.push(newVital);
      } else {
        const vitalIndex = filteredVitalSigns.indexOf(searchVitalSign);
        let measuresArray = filteredVitalSigns[`${vitalIndex}`].measures;
        measuresArray.push(vitalSign.measure1);
        filteredVitalSigns[`${vitalIndex}`].measures = measuresArray;
      }
    });
    return (filteredVitalSigns);
  }

  const getTotalPercentage = (vitalSignData) => {
    let quantityDays = 0;
    let quantityDaysTotal = 0;
    const currentDate = new Date(time.startDate);
    const quantityDaysByMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

    vitalSignData.forEach( vitalSign => {
      if (time.timeOp === 'day') {
        quantityDays += vitalSign.vitalSignPercentage.length > 0 ? 1 : 0;
        quantityDaysTotal += 1;
      } else if (time.timeOp === 'month') {
        quantityDays += vitalSign.vitalSignPercentage.filter(history => history.percentage > 0).length;
        quantityDaysTotal += quantityDaysByMonth;
      } else if (time.timeOp === 'week') {
        quantityDays += vitalSign.vitalSignPercentage.filter(history => history.percentage > 0).length;
        quantityDaysTotal += 7;
      }
    });
    
    const totalPercentage = P.getPercentage(quantityDays, quantityDaysTotal);
    setTotalPercentage(totalPercentage);
  }


  const infoVitalSigns = useMemo(() => {
    const info = userToVitalSigns?.enVitalSign;
    if(time?.timeOp === 'day') return filterDataByDay(info);
    if(time?.timeOp === 'week') return filterDataByWeek(info);
    if(time?.timeOp === 'month') return filterDataByMonth(info);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToVitalSigns, time])  

  return (
    <S.VitalsignsBoxInfo>
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left">
          {
            <VitalSignsList 
              vitalSingsInfo={infoVitalSigns}
              time={time}
              loading={userToVitalSignsLoading}
              color={color}
            />
          }
        </div>
        {/* <div className="boxInfoPatient__info-right">
          <CircleChartCard 
            icon={icon} 
            title={t("patients.boxInfoPatient.report.vitalsigns")}
            percentagePrincipal={totalPercentage}
            textPrincipal={t("patients.boxInfoPatient.report.reported")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div> */}
      </div>
    </S.VitalsignsBoxInfo>
  );
}

const VitalSignsList = ({vitalSingsInfo, time, loading, color}) => {
  const [infoVitalSigns, setinfoVitalSigns] = useState();
  const [t] = useTranslation("global");

  useEffect(() => {
    
    vitalSingsInfo?.then((info) => {
      let tempVitalSings = []
      let pressureVitalSign = [];
      let tempoVitalSignsPressure = null;
      info.forEach(vitalSign => {
        
        if(vitalSign.id === "pressure") {

          if(pressureVitalSign.length < 2) {
            pressureVitalSign.push(vitalSign);
          } else {
            tempoVitalSignsPressure = vitalSign;
            tempoVitalSignsPressure.unit = [
              `${t("healthAddData.healthIndicators.systolicPressure")} (${vitalSign.unit})`, 
              `${t("healthAddData.healthIndicators.diastolicPressure")} (${vitalSign.unit})`, 
              `${t("healthAddData.healthIndicators.average")} (${vitalSign.unit})`];
            tempoVitalSignsPressure.name = t("patients.boxInfoPatient.vitalSigns.pressure");
            tempoVitalSignsPressure.vitalSignToChart = [pressureVitalSign[0].vitalSignToChart, pressureVitalSign[1].vitalSignToChart, vitalSign.vitalSignToChart];
            tempoVitalSignsPressure.multipleChart = true;
            
            tempVitalSings.push(tempoVitalSignsPressure);

          }
        } else {
          if(vitalSign.id !== "pressuredias") {
            tempVitalSings.push(vitalSign);
          }          
        }
      });
      setinfoVitalSigns(tempVitalSings);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vitalSingsInfo])
  
  
  if (loading) return (
    <>
      <ZaiaReaction
        zaiaImg={loadingZaia}
        widthImg="100px"
        sizeText="15px"
        widthText="240px"
        />
    </>
  )
  
  if (infoVitalSigns?.length === 0) return (
    <>
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthImg="100px"
        text={t('globally.noReports')}
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  return (
    <>
      {
        infoVitalSigns?.sort(function(a, b){return b.lastReportDate - a.lastReportDate}).map(vitalSign => {    
          
          let vitalSignMinDate = null;
          let vitalSignMaxDate = null;
          

          if (Array.isArray(vitalSign.vitalSignToChart) && vitalSign.vitalSignToChart.length > 0) {
            const firstElement = vitalSign.vitalSignToChart[0];
            const lastElement = vitalSign.vitalSignToChart[vitalSign.vitalSignToChart.length - 1];
          
            if (Array.isArray(firstElement) && firstElement.length > 0) {
              if (vitalSign.id === "pressure") {
                vitalSignMaxDate = firstElement[0]?.[0] ?? null;
                vitalSignMinDate = firstElement[firstElement.length - 1]?.[0] ?? null;
              } else {
                vitalSignMaxDate = firstElement[0] ?? null;
                vitalSignMinDate = lastElement[0] ?? null;
              }
            } else {
              vitalSignMaxDate = null;
              vitalSignMinDate = null;
            }
          } else {
            vitalSignMaxDate = null;
            vitalSignMinDate = null;
          }
          

          return (
            <VitalSignTimeLineCard
              key={vitalSign.id}
              colorSecundary={color.secundary}
              colorPrimary={color.dark}
              colorVariation={color.primary}
              name={vitalSign.name}
              frequency={vitalSign.frequency}
              frequencyType={vitalSign.frequencyType}
              chartInfo={vitalSign.vitalSignToChart}
              listLevels={vitalSign.listLevels}
              time={time}
              listLevelsStripe={false}
              minDate={vitalSignMinDate}
              maxDate={vitalSignMaxDate}
              unit={vitalSign.unit}
              multipleChart={vitalSign.multipleChart ? vitalSign.multipleChart : false}
            />         
          )
        })
      }
    </>
  )
}

export default VitalsignsBoxInfo;
