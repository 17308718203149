import React, { useState } from "react";
import * as S from "./Alerts.style";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";

// Pipes
import * as P from "../../../utils/pipes"

// Hooks
import { Actions } from "../../../utils/actionsIds";
import useAssignSelectPatient from "../../../hooks/useAssignSelectPatient";
import ZaiaImage from "../../zaiaComponents/zaiaImage";
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../graphql/queries/User";
import Modal from "../../../utils/modal";
import ModalSelectPatient from "../shared/modals/modalSelectPatient";
import ListOfAlerts from "./components/listOfAlerts";

// Assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;

function Alerts() {
  const { t, i18n: { language }} = useTranslation("global");

  const [{ selectedPatient }] = useStateValue();

  const { selectPatient, removePatient } = useAssignSelectPatient();
  const [activeModalPatient, setActiveModalPatient] = useState(false);

  const { data: userData } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: selectedPatient?.id },
  });

  const patient = {
    id: userData?.user[0]?.id,
    img: userData?.user[0]?.picture,
    name: userData?.user[0]?.name ? userData?.user[0]?.name + " " + userData?.user[0]?.lastname : '',
    process: userData?.user[0]?.userToProcesses?.length > 1 ? t("globally.variousProcesses") : P.dataTranslation(userData?.user[0]?.userToProcesses[0]?.process?.processName, language),
    phone: userData?.user[0]?.phone,
    weight: userData?.user[0]?.weight,
    height: userData?.user[0]?.height,
    birthdate: P.getAge(userData?.user[0]?.birthdate),
    nameCarer: userData?.user[0]?.nameCarer,
    phoneCarer: userData?.user[0]?.phoneCarer
  }

  const removePatientAction = () => {
    removePatient();
  }

  return (
    <S.Alerts>
      { 
        <Modal open={activeModalPatient}>
          <ModalSelectPatient
            setModalActive={setActiveModalPatient}
            setPatient={(patient) => selectPatient(patient.id)}
            idAction={Actions.ALERTS.SELECT_PATIENT}
          />
        </Modal>
      }
        <div className="header">
          <div className="header__left">
            {
              selectedPatient
              ? <div className="patient-filtered">
                <button className="patient-filtered__close" onClick={() => removePatientAction()}>
                  <i className="patient-filtered__close-icon icon zaia-icono-cerrar"></i>
                </button>
                <div className="patient-filtered__filtered-by">
                  {t("globally.filterBy")}
                </div>
                <div className="patient-filtered__img">
                  <ZaiaImage
                    img={patient?.img} 
                    alt="" 
                    size={20} 
                    defaultImg={defaultUser} 
                    rounded
                  />
                </div>
                <div className="patient-filtered__text">
                  <div className="patient-filtered__text-title">
                    {patient.name}
                  </div>
                </div>
              </div>
              : <div className="patient-selector" onClick={() => setActiveModalPatient(true)}>
                <div className="patient-selector__box-icon">
                  <i className="patient-selector__icon icon zaia-i-patient" />
                </div>
                <div className="patient-selector__text">
                  {t("filterPanel.filterByPatient")}
                </div>
              </div>
            }

            <div className="filter filter--program">
              <div className="filter__space">
                <Popup
                  trigger={
                    <button className={`select-filter ${"select-filter--actived"}`}>
                      <div className="select-filter__text">
                        Rango de tiempo <b>
                          {
                            // filterProgram ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processName, language) 
                            true 
                          ? "Nivel"
                          : t("globally.all")}
                        </b>
                      </div>
                      <i className="select-filter__icon icon zaia-icono-dropdown-light"></i>
                    </button>
                  }
                  closeOnDocumentClick
                  keepTooltipInside
                  position={["bottom center"]}
                  nested
                >
                  {
                    close => (
                      <S.TimeRangeSelector>
                        <div className="list-ranges">
                          {
                            [1,2,3,4,5].map((item) => {
                              return (                                
                                <div className={`range-item ${false && "program-item--actived"}`}
                                  onClick={() => {
                                    close();
                                  }}
                                >
                                  <div className="range-item__name">{item}</div>
                                </div>
                              )
                            })
                          }
                          <div className="separator"></div>
                          <div className="choose-range">
                            <div className="choose-range__text">
                              Choose range
                            </div>
                            <i className="choose-range__icon icon zaia-i-period-time"></i>
                          </div>
                        </div>
                      </S.TimeRangeSelector>
                    )
                  }
                </Popup>                   
              </div>
            </div>
          </div>
          <div className="header__right">
            <button className="alert-button alert-button--selected">
               <div className="alert-button__text alert-button__text--selected">Alertas pendienes</div>
               <div className="alert-button__number">66</div>
            </button>
            <button className="alert-button">
               <div className="alert-button__text">Alertas gestionadas</div>
               <div className="alert-button__number">14</div>
            </button>
          </div>
        </div>
        <div className="alerts-container">
          <ListOfAlerts />
        </div>
        <div className="alerts-management-container">
          <div className="alerts-management">
            <div className="alerts-management__top">
              <div className="mark-as-text">Marcar como</div>
              <div className="alerts-management__buttons">
                <button className={`button ${false && "button--selected"}`}>
                  <div className="button__text">
                    Gestionada
                  </div>
                  <div className="button__icon-container">
                    <i className="button__icon icon zaia-i-check-1"></i>
                  </div>
                </button>
                <button className={`button ${false && "button--selected"}`}>
                  <div className="button__text">
                    Archivado
                  </div>
                  <div className="button__icon-container">
                    <i className="button__icon icon zaia-icono-dots-horizontal"></i>
                  </div>
                </button>
                <button className={`button ${false && "button--selected"}`}>
                  <div className="button__text">
                    Esto no es una alerta
                  </div>
                  <div className="button__icon-container">
                    <i className="button__icon icon zaia-i-with-notification"></i>
                  </div>
                </button>
                <button className={`button-state ${false && "button-state--active"}`}>
                  <div className="button-state__text">
                    Cambiar estado
                  </div>
                  {
                    true &&
                    <i className="button-state__icon icon zaia-i-locked"></i>
                  }
                </button>
              </div>
            </div>
            <div className="alerts-management__bottom">
              Implica que se tomó una acción para resolver el problema tras revisar la alerta.
            </div>
            <button className="button-pending">
              <i className="button-pending__icon icon zaia-i-back-2"></i>
              <div className="button-pending__bottom-text">
                Mover a alertas pendites
              </div>
            </button>
          </div>
        </div>
    </S.Alerts>
  );
}

export default Alerts;
