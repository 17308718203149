import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as S from "./Patients.style";
import { useStateValue } from "../../../contextAPI/StateProvider";

// Components
import SidebarPatients from "../shared/sidebarPatients";
import DashboardAllPatients from "./components/dashboardAllPatients";
import InvitePatientButton from "./components/invitePatientButton";
import InvitePatientForm from "./components/invitePatientForm";
import SidebarTopPatients from "./components/sidebarTopPatients";
import DashboardPatient from "./components/dashboardPatient";

// hooks
import useActivateModal from "../../../hooks/useActivateModal";
import usePatientVerification from "../../../hooks/usePatientVerification";
import ModalPatientCard from "../shared/modals/modalPatientCard";
import EventCalendarForm from "../outcomes/components/dasboardOutcomesByPatient/components/eventCalendarForm";

function Patients() {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { patientID } = useParams();
  const { activeModal, desactiveModal } = useActivateModal();
  
  const [{ selectedPatient }, dispatch] = useStateValue();
  const panelActive = (
    window.location.href.split('?')[1]?.split('=')[0] === "panelActive" &&
    window.location.href.split('?')[1].split('=')[1]
  )
  ? window.location.href.split('?')[1].split('=')[1]
  : null;

  const [selectedBox, setSelectedBox] = useState();
  const [eventCalendarActive, setEventCalendarActive] = useState(false);

  const { patientValid, loadingValidation, patient } = usePatientVerification(patientID);

  useEffect(() => {
    if (patientValid === false && !loadingValidation) navigate("/patients");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientValid, loadingValidation]);

  useEffect(() => {
    if(selectedBox) {
      activeModal(() => modalCommentCard());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBox])

  useEffect(() => {
    
    if(patientID) {
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: patientID
      });

      if(patient?.userToProcesses.length === 1) {
        dispatch({
          type: "SET_CONTEXT_PROCESS",
          contextProcess: patient?.userToProcesses[0].process.id,
        });
      } else if (patient?.userToProcesses.length === 0 || patient?.userToProcesses.length > 1) {
        dispatch({
          type: "SET_CONTEXT_PROCESS",
          contextProcess: null,
        });
      }

    } else {
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: null,
      });
    }
  }, [patientID]);

  const modalCommentCard = () => {
    return (
      <>
        <ModalPatientCard
          selectedBox={selectedBox}
        />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  }

  const modalPatient = () => {
    return (
      <>
        <InvitePatientForm />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    );
  };

  return (
    <S.Patients selectedPatient={selectedPatient}>
      <div className="patients-container">
        {selectedPatient ? null : (
          <div className="patients-container__sidebar-patients">
            <SidebarPatients
              buttonHide={true}
              defaultHide={
                panelActive !== null &&
                String(panelActive).toLowerCase() !== "true"
              }
              heightTop="178px"
              urlRedirection={"/patients"}
              component={"patients"}
            >
              <SidebarTopPatients
                component={"patients"}
                title={t("patients.patientList.select")}
              />
            </SidebarPatients>
          </div>
        )}
        <div className="patients-container__info-content">
          { patientID ?  
            <DashboardPatient 
              patientID={patientID} 
              setSelectedBox={setSelectedBox}
              eventCalendarActive={eventCalendarActive}
              setEventCalendarActive={setEventCalendarActive}
            /> :
            <DashboardAllPatients />
          }
        </div>
      </div>
      {
        eventCalendarActive &&
        <div className="add-calendar-event">
          <EventCalendarForm
            setEventCalendarActive={setEventCalendarActive}
            patientID={patientID}
          />
        </div>
      }
      <div onClick={() => activeModal(() => modalPatient())}>
        <InvitePatientButton showModal={false} />
      </div>
    </S.Patients>
  );
}

export default Patients;
