import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as S from "./PatientsList.style";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../../../zaiaComponents/zaiaImage";
import Popup from "reactjs-popup";
import ProcessName from "../../../../../shared/processName";

// pipes
import * as P from "../../../../../../../utils/pipes";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import useRecordNavigation from "../../../../../../../hooks/useRecordNavigation";
import { Actions } from "../../../../../../../utils/actionsIds";
import usePatientList from "../../../../../../../hooks/usePatientList";
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED } from "../../../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../../../graphql/queries/Process";
import Modal from "../../../../../../../utils/modal";
import ModalCreateInvitePatient from "../../../../../shared/modals/modalCreateInvitePatient";
import { useNavigate } from "react-router-dom";

// Routes
import { Routes } from "../../../../../../../utils/constants";
import { ButtonsContainer } from "../followUpView/FollowUpView.style";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function PatientsList({
  filterProcess,
  filterGender,
  inputText,
  setInputText,
  textInput,
  setSelectedPatient,
  selectedPatient
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const { recordAction } = useRecordNavigation();
  const navigate = useNavigate();
  const { PROFILE } = Routes;

  const { data: processTypes } = useQuery(QUERY_GET_PROCESS_TYPES);
  const { data: userToProcessesList, loading: userToProcessesListLoading, error: userToProcessesListError } = useQuery(
    QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED, 
    { 
      variables: { isAcepted: true },
      fetchPolicy: 'cache-first'
    }
  );

  const handleSelectPatient = (item) => {
    // Evita cambiar `selectedPatient` si es el mismo usuario
    if (item.user.id !== selectedPatient?.id) {
      recordAction(Actions.AINAVIGATOR.OPEN_MODAL_SUGGEST_PATIENT);
      setSelectedPatient(item.user);
    }
  };
  const [usersListInitial, setUsersListInitial] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [filterAZ, setFilterAZ] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterProgram, setFilterProgram] = useState(null);
  const [filterProgramStatus, setFilterProgramStatus] = useState(null);

  const [createInvitePatientActive, setCreateInvitePatientActive] = useState(false);

  useEffect(() => {
    if (userToProcessesList?.userToProcess) {
      const users = userToProcessesList.userToProcess;      
      setUsersList(users);
      setUsersListInitial(users);
    }
  }, [userToProcessesList]);

  const getFilterText = (text) => {
    return inputText 
      ? String(text).toLowerCase().includes(inputText.toLowerCase())
      : true
  }

  const filteredPatients = useMemo(() => {
    if (!usersList || usersList.length === 0) return [];
  
    return usersList.filter(item => {
      const fullNameMatch = getFilterText(`${item.user.name} ${item.user.lastname}`);
      const emailMatch = getFilterText(item.user.email);
      const documentMatch = getFilterText(item.user.documentNumber);
      const processMatch = filterProcess.length > 0
        ? filterProcess.some(process =>
            item.user.userToProcesses.some(utp => utp.process.id === process.id))
        : true;
      const genderMatch = filterGender ? item.user.gender === filterGender : true;
  
      return (fullNameMatch || emailMatch || documentMatch) && processMatch && genderMatch;
    });
  }, [usersList, filterProcess, filterGender, inputText]);

  const applyFilters = useCallback(() => {
    let filteredList = usersListInitial;
  
    if (filterProgramStatus && filterProgram) {
      filteredList = filteredList.filter(
        item =>
          item.processStage.id === filterProgramStatus &&
          item.process.id === filterProgram
      );
    } else if (filterProgram) {
      filteredList = filteredList.filter(
        item => item.process.id === filterProgram
      );
    }
  
    if (filterAZ !== null) {
      filteredList = [...filteredList].sort((a, b) => {
        const nameA = `${a.user.name} ${a.user.lastname}`;
        const nameB = `${b.user.name} ${b.user.lastname}`;
        return !filterAZ ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
      });
    }
  
    if (filterDate !== null) {
      filteredList = [...filteredList].sort((a, b) =>
        filterDate
          ? new Date(b.created_at) - new Date(a.created_at)
          : new Date(a.created_at) - new Date(b.created_at)
      );
    }

    setUsersList(filteredList);
  }, [filterProgramStatus, filterProgram, filterAZ, filterDate, usersListInitial]);
  
  useEffect(() => {
    applyFilters();
  }, [applyFilters]);


  if (userToProcessesListError)
    return (
      <S.PatientsList>
        <div className="table-container">
          <div className="zaia-reaction">
            <ZaiaReaction
              zaiaImg={logoZaiaAlert}
              widthImg="100px"
              text={t("globally.errorLoadingPatients")}
              sizeText="15px"
              widthText="240px"
            />
          </div>
        </div>
      </ S.PatientsList>
    )

  if (userToProcessesListLoading)  {
  return (
    <S.PatientsList>
      <div className="table-container">
        <div className="zaia-reaction">
          <ZaiaReaction
            widthImg="150px"
            zaiaImg={logoZaiaLoading}
          />  
        </div>
      </div>
    </S.PatientsList>
  );
}

  
  

  return (
    <S.PatientsList>
      <Modal open={createInvitePatientActive}>
        <ModalCreateInvitePatient
          setCreateInvitePatientActive={setCreateInvitePatientActive}
        />
      </Modal>
      <div className="table-container">
        <table className="suggestion-table">
          <thead className="suggestion-table__head">
            <tr>
              <th className="filter filter--patient-name"> 
                <div className="filter__label">{t("aiNavigator.filters.orderPatients")}:</div>
                <div className="filter__space">
                  <button 
                    className={`patient-order ${filterAZ !== null && "patient-order--actived"}`}
                    onClick={() => setFilterAZ(
                      filterAZ === null ? true : (filterAZ === false ? null : false)
                    )}
                  >
                    <i className={`patient-order__icon icon zaia-i-filters ~iconchange__text ${filterAZ !== null && "patient-order__icon--actived"}`}></i>
                    <div className="patient-order__text">
                      {t("aiNavigator.filters.orderAZ")} <b>
                        {
                          filterAZ === false 
                          ? "Z-A"
                          : "A-Z"
                        }
                      </b>
                    </div>
                  </button>
                </div>
              </th>
              <th className="filter filter--date">
                <div className="filter__label">{t("aiNavigator.filters.orderEnrrollDate")}:</div>
                <div className="filter__space">
                  <button 
                    className={`patient-order ${filterDate !== null && "patient-order--actived"}`}
                    onClick={() => setFilterDate(
                      filterDate === null ? true : (filterDate === false ? null : false)
                    )}
                  >
                    <div className="patient-order__text">
                      {
                        filterDate === false
                        ? t("aiNavigator.filters.oldest")
                        : filterDate === true
                          ? t("aiNavigator.filters.mostRecent")
                          : t("aiNavigator.filters.tapToOrder")
                      }
                    </div>
                  </button>
                </div>
              </th>
              <th className="filter filter--program">
                <div className="filter__label">{t("aiNavigator.filters.filterByProgram")}</div>
                <div className="filter__space">
                  <Popup
                    trigger={
                      <button className={`program-filter ${filterDate !== null && "program-filter--actived"}`}>
                        <div className="program-filter__text">
                          {filterProgram ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processName, language) : t("globally.all")}
                        </div>
                        <i className="program-filter__icon icon zaia-icono-dropdown-light"></i>
                      </button>
                    }
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["bottom center"]}
                    nested
                  >
                    {
                      close => (
                        <S.ProgramSelector>
                          <div className="header">
                            <div className="header__label">{t("globally.program")}</div>
                            <div className="header__label">{t("globally.patient")}</div>
                          </div>
                          <div className="list-programs">
                            <div className={`program-item ${filterProgram === null && "program-item--actived"}`}
                              onClick={() => {
                                setFilterProgram(null);
                                setFilterProgramStatus(null);
                                close();
                              }}
                            >
                              <div className="program-item__name">{t("globally.all")}</div>
                              <div className="program-item__quantity">
                                <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                <div className="program-item__number">{usersList?.length}</div>
                              </div>
                            </div>
                            {
                              processTypes?.process.map((item) => {
                                return (                                
                                  <div className={`program-item ${filterProgram === item.id && "program-item--actived"}`}
                                    key={item.id}
                                    onClick={() => {
                                      setFilterProgram(item.id);
                                      setFilterProgramStatus(null);
                                      close();
                                    }}
                                  >
                                    <div className="program-item__name">{P.dataTranslation(item.processName, language)}</div>
                                    <div className="program-item__quantity">
                                      <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                      <div className="program-item__number">{item.userToProcesses?.length}</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </S.ProgramSelector>
                      )
                    }
                  </Popup>                   
                </div>
              </th>
              <th className="filter filter--program-status">
                <div className="filter__label filter--program-status__label">{t("aiNavigator.filters.programStatus")}</div>
                <div className="filter__space filter--program-status__space">
                  {
                    filterProgram === null 
                    ? <div
                      className="status-all"
                    >
                      <i className="status-all__icon icon zaia-i-lock-chat"></i>
                      <div className="status-all__text">{t("globally.all")}</div>
                    </div>
                    : <Popup
                      trigger={
                        <button className="program-filter">
                          <div className="program-filter__text">
                            {
                              filterProgram && filterProgramStatus 
                              ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processStages.find((item) => item.id === filterProgramStatus)?.stageName, language)
                              : t("globally.all")
                            }
                          </div>
                          <i className="program-filter__icon icon zaia-icono-dropdown-light"></i>
                        </button>
                      }
                      closeOnDocumentClick
                      keepTooltipInside
                      position={["bottom center"]}
                      nested
                    >
                      {
                        close => (
                          <S.ProgramSelector>
                            <div className="header">
                              <div className="header__label">{t("aiNavigator.filters.programStatus")}</div>
                              <div className="header__label">{t("globally.patient")}</div>
                            </div>
                            <div className="list-programs">
                              <div className={`program-item ${filterProgramStatus === null && "program-item--actived"}`}
                                onClick={() => {
                                  setFilterProgramStatus(null);
                                  close();
                                }}
                              >
                                <div className="program-item__name">{t("globally.all")}</div>
                                <div className="program-item__quantity">
                                  <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                  <div className="program-item__number">{processTypes?.process.find((item) => item.id === filterProgram).userToProcesses.length}</div>
                                </div>
                              </div>
                              {
                                processTypes?.process.find((item) => item.id === filterProgram)?.processStages.map((item) => {
                                  
                                  return (
                                    <div 
                                      className={`program-item ${filterProgramStatus === item.id && "program-item--actived"}`}
                                      key={item.id}
                                      onClick={() => {
                                        setFilterProgramStatus(item.id);
                                        close();
                                      }}
                                    >
                                      <div className="program-item__name">{P.dataTranslation(item.stageName, language)}</div>
                                      <div className="program-item__quantity">
                                        <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                        <div className="program-item__number">{
                                          processTypes?.process.find(
                                            (item) => 
                                              item.id === filterProgram
                                          ).userToProcesses.filter(
                                            (itemProcess) => 
                                            itemProcess.stageId === item.id
                                          ).length
                                        }</div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </S.ProgramSelector>
                        )
                      } 
                    </Popup>     
                  }
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="suggestion-table__body">
            { 
              filteredPatients?.map((item) => {
                return (
                  <tr 
                    onClick={
                      () => handleSelectPatient(item)
                    }
                    key={item.id}
                    className="suggestion-table__item"
                    style={{
                      borderTop: inputText ? "1px solid #F6F6FF" : '',
                      borderBottom: inputText ? "" : "1px solid #F6F6FF",
                    }}
                  >
                    <td>
                      <div className="patient-content">
                        <ZaiaImage
                          img={item.user?.picture}
                          alt=""
                          size={40}
                          rounded={true}
                        />
                        <div className="patient-content__info">
                          <div className="patient-content__info-name-psa">
                          {`${item.user.name} ${item.user.lastname}`}
                          </div>
                          <div className="patient-content__info-email">
                            {item.user.email}
                          </div>
                          {
                            item.user.documentNumber &&
                          <div className="patient-content__info-document">
                            {item.user.enDocumentType?.comment.code} {item.user.documentNumber}
                          </div>
                          }
                          {
                            item.user.countryPhoneId && item.user.phone &&
                            <div className="patient-content__info-phone">
                              {"+" + item.user.phoneCountry?.phonecode + " " + item.user.phone}
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="patient-date">
                        {P.shortDate(item.created_at, t, true, language)}
                      </div>
                    </td>
                    <td>
                      <div className="patient-program">
                        {P.dataTranslation(item.process?.processName, language)}
                      </div>
                    </td>
                    <td>
                      <div className="patient-status">
                        <i className="patient-status__icon icon zaia-i-refresh"></i>
                        <div className="patient-status__text">
                          {P.dataTranslation(item.processStage?.stageName, language)}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>  
      </div>
      <div className="footer">
        <div className="footer__label">
          <div className="footer__label-patients-quantity">
            {t("aiNavigator.your")} <b>{
              usersList?.length
            }</b> {t("aiNavigator.patientsInOnePlace")}
          </div>
          <div className="footer__label-patients-management">
            {t("aiNavigator.ifYouCantFindThePatient")}, <a onClick={() => {navigate(PROFILE.route + "?management=true")}}>
              {t("aiNavigator.goToPatientManagement")}
            </a>
          </div>
        </div>
        <div className="footer__button">
          <button className="button-add-patient"
            onClick={
              () => {
                recordAction(Actions.AINAVIGATOR.OPEN_MODAL_ADD_PATIENT);
                setCreateInvitePatientActive(true);
              }
            }
          >
            <div className="button-add-patient__text">
              {t("globally.addPatient")}
            </div>
            <i className="button-add-patient__icon icon zaia-icono-perfil ~cambiaricono"></i>
          </button>
        </div>
      </div>
    </S.PatientsList>
  );
}

export default PatientsList;
