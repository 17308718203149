import styled from 'styled-components';
import { getHexOpacity } from "../../../../../utils/pipes";

export const PatientSelectorView = styled.div`
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  /* background: linear-gradient(0deg,rgba(247,247,255,1) 0%,rgba(255,255,255,1) 130%,rgba(255,255,255,1) 100%); */
  position: relative;
  .ainavigator-content {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 20px;
    height: calc(100% - 49px);
    width: fit-content;
    box-shadow: rgba(35, 34, 157, 0.1) 0px 0px 29px 3px;
    margin: auto;

    &__header {
      display: flex;
      padding: 10px 20px;

      border-bottom: 0.5px solid #23229D40;


    }

    &__tabs {
      display: flex;
      margin-left: 28px;
    }
    .button-tab {
      background-color: #F0F0FF;
      margin: 4.5px 0;
      outline: none;
      border: none;
      border-radius: 10px;
      padding: 10px 20px 10px 20px;
      font-family: "Nunito Sans";
      font-size: 17px;
      color: #6C6BCC;
      margin-left: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &__text {
        white-space: nowrap;
      }
      .button-tab__icon {
        margin-right: 10px;
        font-size: 24px;
      }
      .button-tab__icon-alert {
        margin-left: 10px;
      }
      &--selected {
        background-color: #D2D2FF;
        color: #36369B;
        font-family: "Nunito Sans Bold";
      }
    }

  }
  /* .navigator-title {
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      color: #36369B;
      opacity: 60%;
      font-size: 26.16px;
      margin-right: 15px;
    }
    &__text {
      color: #36369B;
      font-size: 36px;
      font-family: "Nunito Sans ExtraBold";
    }
  } */
  .no-patients-container {
    display: flex;
    flex-direction: column;
    .no-patients {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin: auto;
      &__button {
        padding: 10px 0;
        font-family: Nunito Sans;
        font-size: 18px;
        background-color: #36369B;
        color: white;
        border: none;
        outline: none;

        border-radius: 14px;
        margin-top: 20px;
        cursor: pointer ;
      }
    }
  }
  .searcher-container {
    display: flex;
    justify-content: center;
    width: 100%;
    .searcher-content {
      width: 100%;
      padding: 10px;
      border-radius: 74px;
      background: #F4F4FA;
      box-sizing: border-box;
      cursor: text;
      z-index: 100;
      box-shadow: 0px 6px 10.2px -3px #27267724 inset;
      display: flex;
      align-items: center;
      &__icon {
        color: #36369B;
        font-size: 21px;
      }
    }
    .searcher-input {
      display: flex;
      justify-content: space-between;
      height: 100%;
      &__input {
        margin: 0 5px;
        border: none;
        outline: none;
        width: 100%;
        background-color: transparent;
        font-family: "Nunito Sans";	
        font-size: 19px;
        color: #36369B;
        height: 100%;
        ::placeholder {
          color: #707582;
        }
      }
      &__icon {
        color: #36369B;
        font-size: 28px;
      }
    }
    .filter-button {
      background-color: #6C6BCC;
      color: #36369B;
      font-size: 28px;
      display: flex;
      align-items: center;
      padding: 0 19px;
      border-radius: 14px;
      color: #FFFFFF;
      font-size: 15px;
      border: none;
      outline: none;
      font-family: Nunito Sans;
      margin-left: 10px;
      cursor: pointer;
      &__icon {
        color: #FFFFFF;
        font-size: 18px;
        margin-left: 10px;
      }
    }
    .searcher-options {
      margin-top: 27px;
      padding: 17px 17px 0 17px; 
      border-top: 0.5px solid ${"#36369B" + getHexOpacity(0.23)};
      cursor: default;
      .last-search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 17px;
        &__text {
          color: #68688D;
          font-size: 17px;
        }
        &__clear {
          font-size: 17px;
          color: #36369B;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .options-list {
        overflow: auto;
        max-height: 299px;
        ::-webkit-scrollbar {
          display: none;
        }
        .patient-option {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          margin-bottom: 20px;
          border-radius: 14px;
          cursor: pointer;
          transition: 0.5s;
          &__left {
            display: flex;
          }
          &__right {
            /* visibility: hidden; */
          }
          &__info {
            margin-left: 4px;
            &-name {
              margin-left: 5px;
              color: #36369B;
              font-size: 20px;
              font-family: "Nunito Sans";
            }
            &-program {
              font-family: "Nunito Sans";
              padding: 5px;
              color: #526479;
              font-size: 15px;
              background-color: #EEF1F4;
              border-radius: 4px;
              display: flex;
              width: fit-content;
              strong {
                margin-left: 5px;
              }
            }
          }
          &__quick-actions {
            display: flex;
            .quick-action {
              margin-left: 10px;
              background-color: #F3F3FC;
              border-radius: 10.75px;
              width: 61.46px;
              height: 33px;
              display: flex;
              &__icon {
                color: #3E3DA3;
                font-size: 16.97px;
                margin: auto;
              }
              .tooltip {
                z-index: 200;
                position: absolute;
                margin-top: 40px;
                display: none;
              }
            }
          }
          &__flag {
            margin-top: 10px;
            /* display: flex; */
            display: none;
            justify-content: end;
            &-icon {
              color: #FF4A4A;
              font-size: 12px;
              
            }
          }
          :hover {
            padding: 24px 10px;
            background-color: #EEF1F4;
            .quick-action {
              background-color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  .suggestion-test {
    width: 1089px;
    margin: 0 auto 10px;
    padding-left: 37px;
    color: #8686A9;
    font-size: 19px;
  }
  .filters-active {
    width: 1089px;
    display: flex;
    margin: 10px auto;
    align-items: center;
    justify-content: space-between;
    &__left {
      display: flex;
      align-items: center;
    }
    &__right {
      align-items: center;
      display: flex;
    }
    &__label {
      font-family: Nunito Sans;
      font-size: 16px;
      color: #526479;
    }
    &__filters {
      margin-left: 20px;
      display: flex;
    }
    &__clean-filters {
      font-family: Nunito Sans;
      font-size: 16px;
      text-decoration: underline;
      color: #526479;
      cursor: pointer;
    }
    .filter-container {
      display: flex;
      background-color: #6C6BCCB2;
      padding: 5px 10px 5px 10px;
      align-items: center;
      border-radius: 33px;
      margin-right: 20px;
      &__close {
        cursor: pointer;
        background-color: #FFFFFF4A;
        padding: 5.61px;
        border-radius: 50%;
        font-size: 6px;
        align-items: center;
        display: flex;
        &-icon {
          display: flex;
          color: #FFFFFF;
        }
      }
      &__text {
        margin-left: 10px;
        color: #F6F6FF;
        font-family: Nunito Sans;
        font-size: 16px;
      }
      &__space {
        margin-left: 10px;
        background-color: #F6F6FF;
        padding: 5px 10px 5px 10px;
        color: #36369B;
        font-family: Nunito Sans;
        font-size: 16px;
        border-radius: 15px;
        cursor: pointer;
      }
    }
  }
  .suggestion-list {
    width: 1089px;
    position: relative;
    display: flex;
    /* width: 1089px; */
    margin: 0 auto;
    /* padding: 40px 20px 40px 20px; */
    border-radius: 14px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    height: calc(100% - 75px);
    height: ${({filtersSectionActived}) => filtersSectionActived ? "calc(100% - 210px)" : "calc(100% - 75px)"};
    overflow: auto;
    .content-space {
      width: 100%;  
      display: none;
      &--actived {
        display: flex;
      }
    }
  }
`

export const FiltersOptions = styled.div`
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 1px 7px 0px rgba(22, 21, 117, 0.17);
  .filter-option {
    background-color: #6C6BCCB2;
    border-radius: 33px;
    margin-bottom: 10px;
    padding: 5px 10px 5px 10px;
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0px;
    }
    &__icon {
      cursor: pointer;
      background-color: #FFFFFF4A;
      padding: 5.61px;
      border-radius: 50%;
      font-size: 6px;
      align-items: center;
      display: flex;
      &-close {
        display: flex;
        color: #FFFFFF;
      }
    }
    &__text {
      font-family: Nunito Sans;
      font-size: 16px;
      color: #FFFFFF;
      margin-left: 10px;
    }
  }
`

export const ProcessNameContainer = styled.div` 
  background-color: ${({theme}) => theme.background};
  padding: 5px;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
`

export const ProcessName = styled.div` 
  font-size: 17px;
  font-family: "Nunito Sans Light";
  border-bottom: ${({last, theme}) => !last ? '1px solid ' + theme.lineSeparation : null};
  /* margin: 5px 0px; */
  padding: 2px 5px; 
  .card-selected-patient-process {
    color: #526479 !important;
  }
`
