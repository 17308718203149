import React, {useState, useMemo} from "react";
import * as S from "./SymptomsBoxInfo.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_ALL_USER_SIDE_EFFECT } from "../../../../../../../graphql/queries/SideEffects";
import { QUERY_GET_ALL_USER_FEELINGS } from "../../../../../../../graphql/queries/Feelings";

import FacesCard from "../../../../../shared/charts/FacesCard";
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import ProgressBarCard from "../../../../../shared/charts/progressBarCard";

function SymptomsBoxInfo({
  time,
  icon,
  processIds,
  allProcessIds
}) {
  const { t, i18n: { language } } = useTranslation("global");

  const { 
    data: userToSideEffect, 
    loading: userToSideEffectLoading
  } = useQuery(
    QUERY_GET_ALL_USER_SIDE_EFFECT, 
    {
      variables: { 
        processIds: processIds.length === 0 ? allProcessIds : processIds,
        startDate: new Date(time?.startDate), 
        finishDate: new Date(time?.finishDate) 
      },
    }
  );
  
  const { data: userToFeelings } = useQuery(
    QUERY_GET_ALL_USER_FEELINGS, 
    {
      variables: { 
        processIds: processIds.length === 0 ? allProcessIds : processIds,
        startDate: new Date(time?.startDate), 
        finishDate: new Date(time?.finishDate) 
      },
    }
  );

  const [reportsQuantity, setReportsQuantity] = useState();
  const [patientsReportsQuantity, setPatientsReportsQuantity] = useState(0);

  const timesJoinedByFeelings = (info) => {
    let filteredFeelings = []
    info?.forEach(feeling => {
      const searchFeeling = filteredFeelings.find(feel => 
        feel.levelFeeling === feeling.levelFeeling
      );
      if (!searchFeeling) {
        const newFeeling = { levelFeeling: feeling.levelFeeling, quantity: 1 };
        filteredFeelings.push(newFeeling);
      } else {
        const feelIndex = filteredFeelings.indexOf(searchFeeling);
        filteredFeelings[feelIndex].quantity += 1;
      }
    });
    return (filteredFeelings);
  }

  const averageByLevelFeelings = useMemo(() => {
    const dataJoined = timesJoinedByFeelings(userToFeelings?.userToFeeling);
    let percentageFeelings = []
    dataJoined.forEach(data => {
      percentageFeelings.push(
          {
            levelFeeling: data.levelFeeling,
            percentage: P.getPercentage(data.quantity, userToFeelings?.userToFeeling.length)
          }
        )
    });
    return (percentageFeelings);
  },[userToFeelings]); 

  const getFacePercentage = (levelFace) => {
    return averageByLevelFeelings.filter(level => level.levelFeeling === levelFace)[0]?.percentage;
  }

  const infoSideEffects = useMemo(() => {
    const info = userToSideEffect?.userToSideEffect || [];
    setReportsQuantity(info.length);

    const reportsSideEffectJoined = info.reduce((acc, obj) => {
      const existingItem = acc.find(item => item.sideEffectValue === obj.typeSideEffectVal?.value);
      if (existingItem) {
        existingItem.count++;
      } else {
        const newObject = {
          sideEffectName: obj.typeSideEffectVal.comment,
          sideEffectValue: obj.typeSideEffectVal?.value,
          count: 1
        };
        if (newObject.sideEffectName && newObject.sideEffectValue) {
          acc.push(newObject);
        }
      }
      return acc;
    }, []);

    const reportsSideEffectForCharts = [];
    if (reportsSideEffectJoined?.length > 0) {
      reportsSideEffectJoined.forEach(sideEffect => {
        let objectMedicine = {
          name: P.dataTranslation(sideEffect.sideEffectName, language),
          percentage: P.getPercentage(sideEffect.count, info.length)
        }
        reportsSideEffectForCharts.push(objectMedicine)
      })
    }

    reportsSideEffectForCharts.sort((a,b) => (Number(b.percentage) - Number(a.percentage)));

    const patientsQuantity = info?.reduce((acc, obj) => {
      const existingItem = acc.find(item => item.userId === obj.userId);
      if (existingItem) {
        existingItem.count++;
      } else {
        const objCopy = { ...obj, count: 1 };
        acc.push(objCopy);
      }
      return acc;
    }, []);

  setPatientsReportsQuantity(patientsQuantity?.length);  

  return (reportsSideEffectForCharts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToSideEffect, time])

  const color = {
    primary: "var(--blue-color)",
    secundary: "var(--box-shadow-blue-color)",
    dark: "var(--blue-dark-color)",
    varitation: "--dashboard-color"
  }

  return (
    <S.SymptomsBoxInfo>
      <div className="boxInfoPatient__info">
        {/* <div className="boxInfoPatient__info-left">
          <CircleChartCard 
            icon={icon} 
            title={t("patients.boxInfoPatient.report.symptoms")}
            percentagePrincipal={reportsQuantity ? reportsQuantity : 0}
            textPrincipal={t("patients.boxInfoPatient.report.repo.wereReported")}
            // subTextPrincipal={t("patients.boxInfoPatient.report.repo.reports")}
            percentageSecundary={patientsReportsQuantity ? patientsReportsQuantity : 0}
            textSecundary={t("patients.boxInfoPatient.report.repo.reported")}
            subTextSecundary={t("globally.patients")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div> */}
        <div className="boxInfoPatient__info-center">
          <ProgressBarCard 
            title={t("patients.boxInfoPatient.report.symptomMost")}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
            colorDark={color.dark}
            infoBars={infoSideEffects}
            loading={userToSideEffectLoading}
          />
        </div>
        <div className="boxInfoPatient__info-right">
          <FacesCard
            colorPrimary={color.primary}
            colorSecundary={color.dark}
            flex={false}
            goodPercentage={
              getFacePercentage(3)
            }
            regularPercentage={
              getFacePercentage(2)
            }
            badPercentage={
              getFacePercentage(1)
            }
          />
        </div>
      </div>
    </S.SymptomsBoxInfo>
  );
}

export default SymptomsBoxInfo;
      