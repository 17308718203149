import React, { useRef, useState } from "react";
import * as S from "./ModalUserAiNavigator.style";
import * as P from "../../../../../utils/pipes";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import ProcessName from "../../../shared/processName";
import Popup from "reactjs-popup";
import { Actions } from "../../../../../utils/actionsIds";

// Routes
import { Routes } from "../../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { useEffect } from "react";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";
import Modal from "../../../../../utils/modal";
import ModalAddOutcomesEvent from "../../../shared/modals/modalAddOutcomesEvent";
import ModalAddEventCalendar from "../../../agenda/components/modalAddEventCalendar";
import useAssignSelectPatient from "../../../../../hooks/useAssignSelectPatient";
import { QUERY_GET_USERS_BY_USER_ID } from "../../../../../graphql/queries/User";
import { useQuery } from "@apollo/client";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ModalChangeProgramStatus from "../../../shared/modals/modalChangeProgramStatus";
import PdfPatientInfo from "../../../patients/components/dashboardPatient/components/pdfPatientInfo/PdfPatientInfo";
import UserAINavigatorBoxes from "./userAINavigatorView";
import NewUserAINavigatorBoxes from "./newUserAINavigatorBoxes";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function ModalUserAiNavigator({ 
  setSelectedPatient,
  selectedPatient
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const navigate = useNavigate();
  const { AINAVIGATOR, DASHBOARD, AGENDA } = Routes;
  const [, dispatch] = useStateValue();
  const { selectPatient, removePatient } = useAssignSelectPatient();
  const { recordAction } = useRecordNavigation();
  const [addOutcomesEvent, setAddOutcomesEvent] = useState(false);
  const [addCalendarEvent, setAddCalendarEvent] = useState(false);
  const [changeProgramStatus, setChangeProgramStatus] = useState(false);
  const programList = useRef();
  const [cantidadElementosQueCaben, setCantidadElementosQueCaben] = useState(0);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const updateWidth = () => {
      calcElemetsQuantity();
    };    
  
    updateWidth(); // Get initial width
    window.addEventListener("resize", updateWidth);
  
    return () => window.removeEventListener("resize", updateWidth);
  }, []);


  useEffect(() => {
    calcElemetsQuantity()
  }, [programList.current]);

  const calcElemetsQuantity = () => {
    const contenedor = programList.current;

    if (contenedor) {
      const elementos = Array.from(contenedor.children);  // Obtener todos los hijos
      let contenedorWidth;

      if(cantidadElementosQueCaben !== 0 && cantidadElementosQueCaben === elementos.length) {
        contenedorWidth = contenedor.offsetWidth - 50;     // Ancho del contenedor
      } else {
        contenedorWidth = contenedor.offsetWidth - 165;     // Ancho del contenedor
      }
      let widthActual = 0;
      let elementosQueCaben = 0;

      // Calcular cuántos elementos caben
      for (let elemento of elementos) {
          const elementoWidth = elemento.offsetWidth;  // Obtener ancho del elemento

          if (widthActual + elementoWidth <= contenedorWidth) {
              widthActual += elementoWidth;
              elementosQueCaben++;
          } else {
              break;
          }
      }

      setCantidadElementosQueCaben(elementosQueCaben);
    }
  }  

  const {data: userData, loading: userDataLoading, error: userDataError} = useQuery(QUERY_GET_USERS_BY_USER_ID, {
    variables: {
      patientId: selectedPatient.id
    },
    skip: !selectedPatient?.id, 
    fetchPolicy: 'cache-and-network'
  });   

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27)  {
        closeModal();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    selectPatient(userData?.user[0].id);
  }, [userData])

  const closeModal = () => {
    removePatient();
    setSelectedPatient(null);
  }

  const openFlorence = () => {
    closeModal();
    dispatch({
      type: "SET_FLORENCE_VIEW",
      florenceView: 2,
    });
    dispatch({
      type: "SET_FLORENCE_PATIENT",
      florencePatient: userData?.user[0],
    });
    dispatch({
      type: "SET_FLORENCE_ACTIVE",
      florenceActive: userData?.user[0],
    });
  }
  
  const openNotes = async () => {
    await dispatch({
      type: "SET_ANCHORED_VIEW_ACTIVE",
      anchoredViewActived: 1,
    });
    await dispatch({
      type: "SET_NOTES_PATIENT",
      notesPatient: userData?.user[0],
    });
    closeModal();
  }

  const frequencyHours = (userToProcess) => {
    if(userToProcess.processStage.frequencyType === "days") {
      return (userToProcess.processStage.frequency * 24) 
    } else {
      return userToProcess.processStage.frequency
    }
  } 

  const followUpInfo = userData?.user[0]?.userToProcesses.map((userToProcess) => {
    return {
      id: userToProcess.id,
      processId: userToProcess.process?.id,
      processName: P.dataTranslation(userToProcess.process?.processName, language),
      stageId: userToProcess.stageId,
      lastFollowingDate: userToProcess.userToProcessHistories.length > 0 ? new Date(userToProcess.userToProcessHistories[0].created_at) : null,
      nextFollowingDate: new Date(userToProcess.nextUpdate),
      futureFollowingDate: new Date(new Date(userToProcess.nextUpdate).setHours(frequencyHours(userToProcess),0,0,0))
    }
  })

  if(userDataLoading) 
    return (
      <S.ModalUserAiNavigator>
        <div className="modal-background" onClick={() => closeModal()}>
        </div>
        <div className="modal-container">
          <div className="modal-container__reaction">
            <ZaiaReaction
              widthImg="150px"
              zaiaImg={logoZaiaLoading}
            /> 
          </div>
        </div>
      </S.ModalUserAiNavigator>
    )
    
    if(userDataError) 
      return (
        <S.ModalUserAiNavigator>
          <div className="modal-background" onClick={() => closeModal()}>
          </div>
          <div className="modal-container">
            <div className="modal-container__reaction">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                widthImg="200px"
                text={t("globally.genericError")}
                sizeText="18px"
                widthText="290px"
              />
            </div>
          </div>
        </S.ModalUserAiNavigator>
      )
  

  return (
    <S.ModalUserAiNavigator>
      <div className="modal-background" onClick={() => closeModal()}>
      </div>
      <div className="modal-container">
        <Modal open={addOutcomesEvent}>
          <ModalAddOutcomesEvent
            setCreateEventActive={setAddOutcomesEvent}
            patientID={userData?.user[0].id}
          />
        </Modal>
        <Modal open={addCalendarEvent}>
          <ModalAddEventCalendar
            event={null}
            setCardEventSelected={null}
            setCreateEventCalendarActive={setAddCalendarEvent}
            patientID={userData?.user[0].id}
          />
        </Modal>
        <Modal open={changeProgramStatus}>
          <ModalChangeProgramStatus
            setChangeProgramStatus={setChangeProgramStatus}
            patient={userData?.user[0]}
          />
        </Modal>

        <div className="header">
          <div className="close" onClick={() => closeModal()}>
            <i className="close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="content">
          <div className="patient-card">
            <div className="patient-card__left">
              <div className="patient-card__top">
                <div className="patient-card__info">
                  {/* <PdfPatientInfo patientID={userData?.user[0].id}> */}
                  <img className="patient-image"
                    src={userData?.user[0].picture}
                    alt={userData?.user[0].name}
                  ></img>
                  {/* </PdfPatientInfo> */}
                  <div 
                    className="patient-info"
                  >
                    <div className="patient-info__name">
                      <b>{userData?.user[0].name} {userData?.user[0].lastname}</b> {P.getAge(userData?.user[0]?.birthdate)} {t("aiNavigator.outcomes.yearsOld")}
                    </div>
                    {
                      userData?.user[0].documentNumber &&
                      <div className="patient-content__info-document">
                        {userData?.user[0].enDocumentType?.comment.code} {userData?.user[0].documentNumber}
                      </div>
                    }
                    <div className="patient-info__email">{userData?.user[0].email}</div>
                    <div 
                      className="patient-info__program"
                      ref={programList}
                    >
                      {
                        userData?.user[0]?.userToProcesses.map((userToProcess, index) => {

                          let programsQuantity =  userData?.user[0]?.userToProcesses.length - cantidadElementosQueCaben;
                          if(index === cantidadElementosQueCaben ) {
                            return (
                              <Popup
                              key={"more-programs"}
                                trigger={
                                  <button
                                    className="more-programs"
                                  >
                                    + {programsQuantity} { programsQuantity === 1 ? t("globally.program") : t("globally.programs")}
                                  </button>
                                }
                                keepTooltipInside
                                nested
                                // position={"right bottom"}
                                on="hover"
                              >
                                {
                                  <ProgramsContainer 
                                    processes={userData?.user[0]?.userToProcesses}
                                  />
                                }
                              </Popup>
                            )
                          } else {
                            return (
                              <div className="program-box" key={userToProcess.id}>
                                <div className="program-box__name">
                                  <i>{t("globally.program")}</i> {P.dataTranslation(userToProcess.process?.processName, language)}
                                </div>
                                <div className="program-box__status">
                                  {t("followUp.stage")} <b>{P.dataTranslation(userToProcess.processStage?.stageName, language)}</b>
                                </div>
                              </div>
                            )
                          }                       
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="patient-info__shortcuts">
                <div className="shortcut"
                  onClick={
                    () => {
                      recordAction(Actions.AINAVIGATOR_MODAL.CHANGE_PROGRAM_STATUS);
                      setChangeProgramStatus(true);
                    }
                  }
                >
                  <div className="shortcut__name">{t("changeOfStatus.title")}</div>
                  <i className="shortcut__icon icon zaia-i-patient ~iconchange"></i>
                </div>
                <div className="shortcut"
                  onClick={
                    () => {
                      recordAction(Actions.AINAVIGATOR_MODAL.GO_NOTES);
                      openNotes();
                    }
                  }
                >
                  <div className="shortcut__name">{t("notes.addNote")}</div>
                  <i className="shortcut__icon icon zaia-document ~iconchange"></i>
                </div>
              </div>
            </div>
            <div className="patient-card__right">
              <div className="numbers">
                <div className="numbers__title">
                  <div className="numbers__title-text">{t("aiNavigator.patientModal.phoneNumbers")}</div>
                  <i className="numbers__title-icon icon zaia-i-phone"></i>
                </div>
                <div className="number">
                  <div className="number__title">
                    {t("globally.patient")}
                  </div>
                  <div className="number__phone">
                    {
                      userData?.user[0].carerPhoneCountry
                      ? "+" + userData?.user[0].phoneCountry?.phonecode + " "
                      : ""                      
                    }
                    {userData?.user[0].phone}
                  </div>
                </div>
                {
                  userData?.user[0].phoneCarer &&
                  <div className="number">
                    <div className="number__title">
                      {t("globally.caregiver")}
                    </div>
                    <div className="number__phone">
                      {
                        userData?.user[0].carerPhoneCountry
                        ? "+" + userData?.user[0].carerPhoneCountry?.phonecode + " "
                        : ""
                      } {userData?.user[0].phoneCarer}
                    </div>
                  </div>
                }
              </div>
              {/* <button className="button-email">
                <div className="button-email__text">{t("aiNavigator.patientModal.sendEmail")}</div>
                <i className="button-email__icon icon"></i>
              </button> */}
            </div>
          </div>
          <div className="user-ainavigator-boxes">
            {/* <UserAINavigatorBoxes
              patient={selectedPatient}
              closeModal={closeModal}
              followUpInfo={followUpInfo}
            /> */}

            <NewUserAINavigatorBoxes
              patient={selectedPatient}
              closeModal={closeModal}
              followUpInfo={followUpInfo}
            />
            <button className="show-info"
              onClick={() => setShowInfo(!showInfo)}
            >
              ...
            </button>
            {
              showInfo &&
              <PdfPatientInfo patientID={userData?.user[0].id}>
              </PdfPatientInfo>
            }
            
          </div>
        </div>
      </div>
    </S.ModalUserAiNavigator>
  );
};

const ProgramsContainer = ({processes}) => {

  const {t, i18n: { language }} = useTranslation("global");

  return (
    <S.ProgramsContainer>
      <div className="title">{t("globally.programs")}</div>
      <div className="programs-list">
        {
          processes.map((userToProcess) => {          
            return (
              <div className="program-box" key={userToProcess.id}>
                <div className="program-box__name">
                  <i>{t("globally.program")}</i> {P.dataTranslation(userToProcess.process?.processName, language)}
                </div>
                <div className="program-box__status">
                  {t("followUp.stage")} <b>{P.dataTranslation(userToProcess.processStage?.stageName, language)}</b>
                </div>
              </div>
            )                                 
          })
        }
      </div>
    </S.ProgramsContainer>
  )
}

export default ModalUserAiNavigator;
