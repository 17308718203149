import React, { useState } from "react";
import * as S from "./SurveyBox.style.js";
import { useStateValue } from "../../../../../contextAPI/StateProvider.js";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation.js";

// utils
import { Actions } from "../../../../../utils/actionsIds.js";
import * as P from "../../../../../utils/pipes.js";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput/ZaiaInput.jsx";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../utils/constants.js";
import Popup from "reactjs-popup";

function SurveyBox({
  data
}) {

  const { recordAction } = useRecordNavigation();
  const { AINAVIGATOR } = Routes;
  const navigate = useNavigate();
  const [{ selectedPatient }, dispatch] = useStateValue();
  const [processSelected, setProcessSelected] = useState(selectedPatient?.userToProcesses[0].process?.id);
  const { i18n: { language }, t } = useTranslation("global");
  const [filterText, setFilterText] = useState("");

  const frequenciesList = [
    {
      value: {
        number: 1,
        type: "day"
      },
      text: `${t("globally.every")} ${t("globally.day")}` 
    },
    {
      value: {
        number: 2,
        type: "day"
      },
      text: `${t("globally.every")} 2 ${t("globally.days")}` 
    },
    {
      value: {
        number: 4,
        type: "day"
      },
      text: `${t("globally.every")} 4 ${t("globally.days")}` 
    },
    {
      value: {
        number: 1,
        type: "week"
      },
      text: `${t("globally.every")} ${t("globally.week")}` 
    },
    {
      value: {
        number: 2,
        type: "day"
      },
      text: `${t("globally.every")} 2 ${t("globally.weeks")}` 
    },
    {
      value: {
        number: 1,
        type: "month"
      },
      text: `${t("globally.every")} ${t("globally.month")}` 
    },
    {
      value: {
        number: 2,
        type: "month"
      },
      text: `${t("globally.every")} 2 ${t("globally.month")}` 
    },
    {
      value: {
        number: 3,
        type: "month"
      },
      text: `${t("globally.every")} 3 ${t("globally.month")}` 
    },
  ]

  return <S.SurveyBox
    onClick={() => {
      navigate(`${AINAVIGATOR.route}/${data?.user.id}?survey=${data?.customFormId}`); 
    }}
  >
    {/* <tr> */}
      <td>
        <div className="survey-principal">
          {/* <button className="survey-favourite">
            <i className="survey-favourite__icon icon zaia-icon-star"></i>
          </button> */}
          <img src={data?.user.picture} alt="" className="survey-patient-img" />
          <div className="survey-info">
            {/* <div className="survey-info__title">{P.dataTranslation(data?.customForm.customFormName, language)}</div> */}
            <div className="survey-info__title">{data?.user.name} {data?.user.lastname}</div>
            {/* <div className="survey-info__subtitle">{P.dataTranslation(data?.customForm., language)}</div> */}
          </div>
        </div>
      </td>
      {/* <td>
        <div className="survey-status">
          <i className="survey-status__icon icon zaia-alert"></i>
          <div className="survey-status__text">close</div>
        </div>
      </td> */}
      <td>
        <div className="survey-date">
          {
            P.longDate(data?.date, t, language)
          }
        </div>
      </td>
      <td>
        <div className="survey-status">
          <Popup
            trigger={
              <i className="survey-status__icon icon zaia-i-info"></i>
            }
            on={["hover", "focus"]}
            closeOnDocumentClick
            keepTooltipInside
            position={["right center"]}
            nested
          >
            <S.TabDescription>
              {t("surveys.answeredTooltip")}
            </S.TabDescription>
          </Popup>
          <div className="survey-status__text">
            {
              data?.answersQuantity
            }
          </div>
        </div>
      </td>
      <td>
        {
          data?.frequency && data?.frequencyType 
          ? <div className="survey-frequency">
              {frequenciesList.find((frequency) => frequency.value.number === data?.frequency && frequency.value.type === data?.frequencyType)?.text}
            </div>
          : <div className="survey-frequency survey-frequency--no-frequency">
            {t("surveys.noFrequency")}
          </div>

        }
      </td>
      {/* <td>
        <div className="survey-delicate">
          <div className="survey-delicate__icon-container">
            <i className="survey-delicate__icon icon zaia-alert"></i>
          </div>
          <div className="survey-delicate__number">
            4
          </div>
          <div className="survey-delicate__expand">
            <i className="survey-delicate__expand-icon icon zaia-i-down"></i>
          </div>
        </div>
      </td> */}
    {/* </tr> */}
  </S.SurveyBox>
}

export default SurveyBox;