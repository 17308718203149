import React, { useEffect, useState } from "react";
import * as S from  "./AiNavigatorHealth.style";
import { useTranslation } from "react-i18next";
import { Actions } from "../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";
import Popup from "reactjs-popup";

// pipes
import * as P from "../../../../../utils/pipes";
import Modal from "../../../../../utils/modal";
import FeelingsBoxInfo from "../../../patients/components/dashboardPatient/components/feelingsBoxInfo";
import SymptomsBoxInfo from "../../../patients/components/dashboardPatient/components/symptomsBoxInfo";
import VitalsignsBoxInfo from "../../../patients/components/dashboardPatient/components/vitalsignsBoxInfo";
import MedicinesBoxInfo from "../../../patients/components/dashboardPatient/components/medicinesBoxInfo";
import ModalMedication from "../../../shared/modals/addPatientData/modalMedication";
import ModalHealthIndicators from "../../../shared/modals/addPatientData/modalHealthIndicators";
import ModalSymptoms from "../../../shared/modals/addPatientData/modalSymptoms";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import ListOfCommentCards from "../../../patients/components/dashboardPatient/components/listOfCommentCards";

function AiNavigatorHealth() {

  const { i18n: { language }, t } = useTranslation("global");

  const { recordAction } = useRecordNavigation();
  const [{ selectedPatient }] = useStateValue();
  const [timesList, setTimesList] = useState();
  const [time, setTime] = useState();
  const nowTime = new Date(Date.now());
  const [modalMedicationActive, setModalMedicationActive] = useState(false);
  const [modalHealthIndicatorActive, setModalHealthIndicatorActive] = useState(false);
  const [modalSymptomActive, setModalSymptomActive] = useState(false);
  const [modalOption, setModalOption] = useState(); 

  return(
    <S.AiNavigatorHealth>
      <Modal open={modalMedicationActive}>
        <ModalMedication
          setModalActive={setModalMedicationActive}
          option={modalOption}
          patient={selectedPatient}
        />
      </Modal>
      <Modal open={modalHealthIndicatorActive}>
        <ModalHealthIndicators
          setModalActive={setModalHealthIndicatorActive}
          option={modalOption}
          patient={selectedPatient}
        />
      </Modal>
      <Modal open={modalSymptomActive}>
        <ModalSymptoms
          setModalActive={setModalSymptomActive}
          option={modalOption}
          patient={selectedPatient}
        />
      </Modal>
      <div className="ainavigator-content__filters-box">
        <div className="ainavigator-content__button-add-data">
          <Popup
            trigger={
              <div className="button-add-data">
                <div className="button-add-data__text">{t("aiNavigator.health.addData")}</div>
                <i className="button-add-data__icon icon zaia-i-down"></i>
              </div>
            }
            position={"bottom left"}
          >
            {
              close => (
                <S.AddOptionsTooltip>
                  <div className="add-item">
                    <div className="add-item__icon-container">
                      <div className="add-item__icon-container-box"
                        style={{backgroundColor: "#92D46E"}}
                      >
                        <i className="add-item__icon icon zaia-i-add-medicine"></i>
                      </div>
                    </div>
                    <div className="add-item__text">
                      <div className="add-item__text-title">
                        {t("globally.medication")}
                      </div>
                      <div className="add-item__text-options">
                        <div className="add-item__text-option" 
                          onClick={() => {
                            recordAction(Actions.HEALTH.CREATE_MEDICATION);
                            setModalMedicationActive(true);
                            setModalOption(1);
                            close();
                          }}
                        >
                          {t("globally.createAlert")}
                        </div>
                        {/* <div className="add-item__text-option" 
                          onClick={() => {
                            recordAction(Actions.HEALTH.CREATE_MEDICATION);
                            setModalMedicationActive(true);
                            setModalOption(2);
                            close();
                          }}
                        >
                          {t("globally.createAlert")}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="add-item">
                    <div className="add-item__icon-container">
                      <div className="add-item__icon-container-box"
                        style={{backgroundColor: "#E92034"}}
                      >
                        <i className="add-item__icon icon zaia-icono-signos-vitales"></i>
                      </div>
                    </div>
                    <div className="add-item__text">
                      <div className="add-item__text-title">
                        {t("globally.healthIndicators")}
                      </div>
                      <div className="add-item__text-options">
                        <div className="add-item__text-option"
                          onClick={() => {
                            recordAction(Actions.HEALTH.CREATE_VITAL_SIGNS);
                            setModalHealthIndicatorActive(true);
                            setModalOption(1);
                            close();
                          }}
                        >
                          {t("globally.createAlert")}
                        </div>
                        <div className="add-item__text-option"
                          onClick={() => {
                            recordAction(Actions.HEALTH.REGISTER_VITAL_SIGN);
                            setModalHealthIndicatorActive(true);
                            setModalOption(2);
                            close();
                          }}
                        >
                          {t("aiNavigator.health.registerVitalSigns")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add-item">
                    <div className="add-item__icon-container">
                      <div className="add-item__icon-container-box"
                        style={{backgroundColor: "#3E3DA3"}}
                      >
                        <i className="add-item__icon icon zaia-i-symptoms"></i>
                      </div>
                    </div>
                    <div className="add-item__text">
                      <div className="add-item__text-title">
                        {t("globally.symptoms")}
                      </div>
                      <div className="add-item__text-options">
                        <div className="add-item__text-option"
                          onClick={() => {
                            recordAction(Actions.HEALTH.REPORT_SYMPTOMS);
                            setModalSymptomActive(true);
                            setModalOption(1);
                            close();
                          }}
                        >
                          {t("aiNavigator.health.reportSymptoms")}
                        </div>
                      </div>
                    </div>
                  </div>
                </S.AddOptionsTooltip>
              )
            }
          </Popup>
        </div>
        <div className="ainavigator-content__filters">
          <Tabs setTimesList={setTimesList} nowTime={nowTime} setTime={setTime}/>
          <div className="specific-date-selector-container">
            <Popup
              trigger={
                <div className="specific-date-selector">
                  <div className="specific-date-selector__date">
                    <strong>{time?.name}</strong>
                  </div>
                  <i className="icon zaia-icono-flechas-arriba-abajo"></i>
                </div>
              }
              position={"bottom center"}
            >
              <DatesContainer timesList={timesList} setTime={setTime} time={time}/>
            </Popup>
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="section-container__title">
          <i className="section-container__title-icon icon zaia-sintoma-bien" />
          <div className="section-container__title-text">{t("globally.feeling")}</div>
        </div>
        <div className="section-container__container">
          <FeelingsBoxInfo time={time} icon={"zaia-icono-sintomas"}/>
        </div>
      </div>
      
      <div className="section-container">
        <div className="section-container__title">
          <i className="section-container__title-icon icon zaia-icono-sintomas" />
          <div className="section-container__title-text">{t("globally.symptoms")}</div>
        </div>
        <div className="section-container__container">
          <SymptomsBoxInfo time={time} icon={"zaia-icono-sintomas"}/>
        </div>
      </div>
      
      <div className="section-container">
        <div className="section-container__title">
          <i className="section-container__title-icon icon zaia-icono-signos-vitales" />
          <div className="section-container__title-text">{t("globally.vitalSign")}</div>
        </div>
        <div className="section-container__container">                      
          <VitalsignsBoxInfo time={time} icon={"zaia-icono-signos-vitales"}/>
        </div>
      </div>

      <div className="section-container">
        <div className="section-container__title">
          <i className="section-container__title-icon icon zaia-icono-medicina" />
          <div className="section-container__title-text">{t("globally.medications")}</div>
        </div>
        <div className="section-container__container">   
          <MedicinesBoxInfo time={time} icon={"zaia-icono-medicina"}/>
        </div>
      </div>

      {/* <div className="section-container">
        <div className="section-container__title">
          <i className="section-container__title-icon icon zaia-icono-medicina" />
          <div className="section-container__title-text">{"~Comments"}</div>
        </div>
        <div className="section-container__container">   
          <ListOfCommentCards
            initialDate={new Date(time?.startDate)}
            finishDate={new Date(time?.finishDate)}
            selectedPatient={selectedPatient.id}
            setSelectedBox={() => {}}
          />
        </div>
      </div> */}
    </S.AiNavigatorHealth>
  );
}

const Tabs = ({setTimesList, nowTime, setTime}) => {
  const { t, i18n: { language }} = useTranslation("global");
  const [activeTab, setActiveTab] = useState(3);
  const { recordAction } = useRecordNavigation();

  useEffect(() => {
    timesForMonth().then(
      timesForMonth => {
        setTime(timesForMonth[0]); 
        setTimesList(timesForMonth);
      }
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setTime, setTimesList]);

  const tabs = [
    {id: 1, name: t("globally.day")}, // day 1
    {id: 2, name: t("globally.week")}, // week 2
    {id: 3, name: t("globally.month")} // year 3
  ]

  const changeDates = (e) => {
    if(e !== activeTab){
      setActiveTab(e);
      if(e === 1) timesForDay().then(
        timesDay => {
          setTime(timesDay[0]);
          setTimesList(timesDay);
        }
      )
      if(e === 2) timesForWeek().then(
        timesWeek => {
          setTime(timesWeek[0]);
          setTimesList(timesWeek);
        }
      )
      if(e === 3) timesForMonth().then(
        timesForMonth => {
          setTime(timesForMonth[0]); 
          setTimesList(timesForMonth);
        }
      )
    }
  }

  const timesForDay = async () => {
    let timesDay = [];
    for(let i=0; i<16; i++) {
      let time = nowTime.setDate(nowTime.getDate() - (i === 0 ? 0 : 1));
      timesDay.push(
        {
          startDate: new Date(time).setHours(0,0,0,0),
          finishDate: new Date(time).setHours(23,59,59,999),
          name: P.longDate(new Date(time), t, language),
          timeOp: 'day'
        }
      )
    }
    return Promise.all(timesDay);
  }

  const timesForWeek = async () => {
    let timesWeek = [];
    let nowDay = nowTime.getDay();
    let dateStartWeek = new Date(nowTime);
    let dateFinishWeek = new Date(nowTime);
    for(let i=0; i<16; i++) {

      const dayOffset = i === 0 ? (nowDay - 1) : 7;
      let startDayOffset; 
      if (i === 0) {
        startDayOffset = 0;
      } else if (i === 1 && nowDay !== 0) {
        startDayOffset = nowDay;
      } else {
        startDayOffset = 7;
      }

      dateStartWeek.setDate(dateStartWeek.getDate() - dayOffset);
      dateFinishWeek.setDate(dateFinishWeek.getDate() - startDayOffset);

      timesWeek.push(
        {
          startDate: new Date(dateStartWeek).setHours(0,0,0,0),
          finishDate: new Date(dateFinishWeek).setHours(23,59,59,999),
          name: P.shortDate(new Date(dateStartWeek), t, false, language) + " - " +  P.shortDate(new Date(dateFinishWeek), t, false, language),
          timeOp: 'week'
        }
      )
    }
    return Promise.all(timesWeek);
  }
  
  const timesForMonth = async () => {
    let timesMonth = [];
    let dateStartMonth = new Date(nowTime)
    let dateFinishMonth = new Date(nowTime);
    for(let i=0; i<12; i++) {
      let numberMonth = dateFinishMonth.getMonth();
      let numberDaysMonth = new Date(dateFinishMonth.getFullYear(), numberMonth + 1, 0).getDate();
      dateFinishMonth.setDate(numberDaysMonth);
      dateStartMonth.setDate(1);
      timesMonth.push(
        {
          startDate: new Date(dateStartMonth).setHours(0,0,0,0),
          finishDate: new Date(dateFinishMonth).setHours(23,59,59,999),
          name: P.nameMonth(numberMonth, 'L', t),
          timeOp: 'month'
        }
      )
      dateFinishMonth.setDate(dateFinishMonth.getDate() - numberDaysMonth);
      dateStartMonth.setDate(dateStartMonth.getDate() - 1);
    }
    return Promise.all(timesMonth);
  }

  return (
    <div className="time-filters">
      {
        tabs.map(tab => {
          return (
            <div 
              key={tab.id} 
              className={`time-filter ${tab.id === activeTab ? "time-filter--selected" : ""}`}
              onClick={
                () => {
                  recordAction(Actions.AINAVIGATOR.FILTERS);
                  changeDates(tab.id);
                }
              }
            >
              {tab.name}
            </div>
          )
        })
      }
    </div>
  );
};

const DatesContainer = ({color, timesList, setTime, time}) => {
  return (
    <S.DateOptions>
      {
        timesList?.map(timeItem => {
          return (
            <div 
              key={timeItem.startDate} 
              className="select-date" 
              onClick={() => setTime(timeItem)}
              style={{backgroundColor: timeItem === time ? "#36369B" : "", "#36369B": timeItem === time ? "var(--white)" : "" }}
              ref={element => { 
                if (element) element.style.setProperty('color', timeItem === time ? "var(--white)" : "", 'important'); 
              }}
            >{timeItem.name}</div> 
          )
        })
      }
    </S.DateOptions>
  )
}

export default AiNavigatorHealth;