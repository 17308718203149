import styled from "styled-components";

export const MedicinesBoxInfo = styled.div`

  .boxInfoPatient__info {
    display: flex;
    
    &-left {
      min-height: 140px;
      display: flex;
      /* width: calc(100% - 200px); */
      width: 100%;
      overflow-y: auto;
      border-right: #E3E3E3 1px solid;
    }

    &-right {
      width: 200px;
      padding-left: 10px;
      display: flex;
    }
  }

  @media only screen and (max-width: 768px) {
    .boxInfoPatient__info {
      flex-direction: column;
      align-items: center;
      &-left {
        width: 100%; 
        margin-bottom: 10px;
      }
      &-right {
        width: 100%;
      }
    }
  }


`;