import styled from "styled-components";
const bgFlorence = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/background-florence.svg`;

export const NewUserAINavigatorBoxes = styled.div`
  position: relative;
  height: 100%;
  .options-row {
    display: flex;
    width: 100%;
    &--first {
        margin-bottom: 20px;
    }
    .box {
      padding: 20px;
      box-sizing: border-box;
      width: 32%;
      background-color: #FFF;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: relative;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 19px;
      }
      &__title-icon {
        font-size: 24px;
        margin-right: 5px;
        &--outcomes {
          color: #E0E0FF;
        }
        &--calendar {
          color: #E0E0FF;
        }
        &--health {
          color: #DB9DC6;
        }
        &--alerts {
          color: #FF4A4A;
          font-size: 12px;
        }
      }
      &__title-text {
        font-size: 18px;
        color: #3E3DA3;
      }
      &__title-left {
        display: flex;
        align-items: center;
      }
      &__title {
        align-items: center;
        justify-content: space-between;
        color: #36369B;
        font-family: "Nunito Sans Bold";
        font-size: 18.232px;
        display: flex;
        align-items: center;          
      }
      &__icon {
        color: #3E3DA3;
        font-size: 19px;
      }
      &__subtitle {
        color: #707582;
        font-family: "Nunito Sans Light";
        font-size: 14px;
        margin-top: 5px;
      }
      .select-container {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-self: center;
        text-align-last: center;
        &__label {
          font-family: Nunito Sans Bold;
          font-size: 16px;
          color: #7D7C8B;
          margin-bottom: 5px;
        }
      }
      &__image {
        margin: 15px 0px 10px -28px;
        width: 362px;
        position: absolute;
        margin-top: 100px;
        z-index: 100;
      }
      &__buttons {
        display: flex;
        padding-bottom: 15px;
        justify-content: center;
        z-index: 200;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      &__button {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #3E3DA3;
        box-shadow: 0px 7px 7px 0px rgba(35, 34, 157, 0.00);
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 14px;
        border: none;
        outline: none;
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 5px;
        }
      }
      &--monaco {
        background-color: white;
        box-sizing: border-box;
        background: linear-gradient(111.82deg, #99F2FF -42.02%, #B5E3FC -8.25%, #7CBCFE 16.49%, #0E77E2 51.45%);
        display: flex;
        flex-direction: row;
      }
      &__monaco-text {
        color: #FFF;
        margin-left: 90px;
      }
      &__monaco-title {
        font-family: "Nunito Sans Bold";
        font-size: 18px;
      }
      &__monaco-subtitle {
        font-family: "Nunito Sans Light";
        font-size: 14px;
      }
      &__monaco-image {
        width: 250px;
        height: 250px;
        position: absolute;
        margin-left: -118px;
        margin-top: -119px;
      }
    }
  }
`;