import React, { useMemo, useState, useEffect } from "react";
import * as S from "./MedicinesBoxInfo.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"

// components
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import MedicinesLogCard from "../../../../../shared/charts/medicinesLogCard";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_MEDICINES } from "../../../../../../../graphql/queries/drugs";

// pipes
import * as P from "../../../../../../../utils/pipes";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;
const loadingZaia = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function MedicinesBoxInfo({
  time,
  icon
}) {
  const { t, i18n: { language } } = useTranslation("global");
  const { patientID } = useParams();
  const { 
    data: userToMedicines,
    loading: userToMedicinesLoading 
  } = useQuery(
    QUERY_GET_USER_MEDICINES, 
    {
      variables: { 
        patientId: patientID, 
        startDate: new Date(time?.startDate), 
        finishDate: new Date(time?.finishDate) 
      }
    }
  );
  const [totalPercentage, setTotalPercentage] = useState(0);

  const color = {
    primary: "var(--green-color)",
    secundary: "var(--box-shadow-green-color)",
    dark: "var(--green-color-100)",
  }

  const getFrequencyTimes = (medicine) => {
    let frequencyTimes = []
    let dayStart = new Date(new Date().setHours(0,0,0,0));
    while(dayStart.getHours() !== 24) {
      frequencyTimes.push(new Date(dayStart));
      dayStart.setHours(dayStart.getHours() + medicine.frequency, 0, 0, 0);
      if(dayStart.getHours() === 0) break;
    }
    return frequencyTimes
  } 

  const periodicMedicines = (medicine, medicinesLog, frequencyTimes, dateStart, dateInHistory) => {
    const frequencyStates = [];
    let quantityHistoryMedLog = 0;
    let quantityMedLog = 0;
  
    const dateInMedicinesLog = medicinesLog?.includes(new Date(dateStart).getDay());
  
    frequencyTimes.forEach(time => {
      const timefinish = new Date(time);
      timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);
      const timefinishHour = timefinish.getHours() === 0 ? 24 : timefinish.getHours();
  
      const historyInHour = dateInHistory.find(date => {
        const createdDate = new Date(date.created_at).getHours();
        return createdDate >= time.getHours() && createdDate < timefinishHour;
      });
  
      if (dateInMedicinesLog) {
        if (historyInHour) {
          frequencyStates.push(historyInHour.isTaken ? 'takeIt' : 'noTakeIt');
          quantityHistoryMedLog++;
        } else if (new Date(new Date(dateStart).setHours(new Date(time).getHours() + medicine.frequency, 0, 0, 0)) <= new Date(Date.now())) {
          frequencyStates.push('noReported');
        } else {
          frequencyStates.push('haveTakeIt');
        }
        quantityMedLog++;
      } else {
        frequencyStates.push('haveNoTakeIt');
      }
    });
  
    return { quantityHistoryMedLog, quantityMedLog, frequencyStates };
  };

  const nonPeriodicMedicines = (medicine, frequencyTimes, dateStart, dateInHistory) => {
    const frequencyStates = [];
    let quantityHistoryMedLog = 0;
    let quantityMedLog = 0;
    const drugDatesList = medicine.userToDrugDates;     

    frequencyTimes.forEach(time => {
      const timefinish = new Date(time);
      timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);
      time.setDate(new Date(dateStart).getDate());
      const drugDateFound = drugDatesList.find(info => new Date(info.daySpecific).getDate() === new Date(dateStart).getDate());
      const historyInHour = dateInHistory.find(date => (new Date(date.created_at) >= new Date(time) && new Date(date.created_at) < new Date(timefinish)));
      if (historyInHour) {
        frequencyStates.push(historyInHour.isTaken ? 'takeIt' : 'noTakeIt');
        quantityHistoryMedLog++;
        quantityMedLog++;
      } else {
        if (drugDateFound) {
          frequencyStates.push(new Date(new Date(dateStart).setHours(new Date(time).getHours() + medicine.frequency, 0, 0, 0)) <= new Date(new Date(Date.now())) ? 'noReported' : 'haveTakeIt');
          quantityMedLog++;
        } else {
          frequencyStates.push('haveNoTakeIt');
        }
      }
    });

    return { quantityHistoryMedLog, quantityMedLog, frequencyStates };
  }

  const getLastReportDate = (filterInsideTreatment, historieslength, date) => {
    if(filterInsideTreatment === 'inside') {
      if(historieslength !== 0 ) {
        return new Date(date[`${historieslength-1}`].created_at).getTime();
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  }


  const filterDataByDay = async (info) => {
    let medicinesDay = [];

    for (const medicine of info) {
        const frequencyTimes = getFrequencyTimes(medicine);
        const filterInsideTreatment = insideIntervalTreatment(medicine);
        const medicinesLog = getMedicinesLog(medicine);
        let dateStartDay = new Date(time?.startDate);
        let dateInHistory;

        dateInHistory = medicine.userToDrugHistories;
        
        let medicationDaysHistory = [];
        let periodicMedicineResult = {};
        
        if(medicine.isPeriodic) {
          periodicMedicineResult = periodicMedicines(medicine, medicinesLog, frequencyTimes, dateStartDay, dateInHistory);
        } else {
          periodicMedicineResult = nonPeriodicMedicines(medicine, frequencyTimes, dateStartDay, dateInHistory);        
        }
  
        medicationDaysHistory.push(
          {
            name: P.longDate(dateStartDay, t, language),
            frequencyStates: periodicMedicineResult.frequencyStates,
          }
        )
  
        let historieslength = dateInHistory.length;
  
        let lastReportDate = getLastReportDate(filterInsideTreatment, historieslength, dateInHistory);

        const medicineName = medicine.displayName || medicine.drug.genericName;
  
        medicinesDay.push(
          {
            id: medicine.id,
            name: medicineName,
            dose: medicine.drugDose.doseTypeVal.comment,
            quantity: medicine.quantity,
            frequency: medicine.frequency,
            frequencyType: P.dataTranslation(medicine.frequencyTypeValue.comment, language),
            medicationToChart: medicationDaysHistory,
            ubicationInTreatment: filterInsideTreatment,
            quantityMedicationLogDays: periodicMedicineResult.quantityMedLog,
            quantityMedicationDays: periodicMedicineResult.quantityHistoryMedLog,
            frequencyTimes: frequencyTimes,
            lastReportDate: lastReportDate
          }
        )
    }

    getTotalPercentage(medicinesDay);
    return medicinesDay;
  }

  const filterDataByWeek = async (info) => {
    let medicinesWeek = [];
    info?.forEach(medicine => {
      const frequencyTimes = getFrequencyTimes(medicine);
      const filterInsideTreatment = insideIntervalTreatment(medicine);
      let medicationWeekHistory = [];
      const medicinesLog = getMedicinesLog(medicine);
      let periodicMedicineResult = {};
      let quantityMedLog = 0;
      let quantityHistoryMedLog = 0;

      let historieslength = medicine.userToDrugHistories.length;

      let lastReportDate;

      if(filterInsideTreatment === 'inside') {
        let dateStartWeek = new Date(time.startDate);
        let dateFinishWeek = new Date(time.startDate);
        dateFinishWeek.setDate(dateFinishWeek.getDate() + 7);

        while(dateStartWeek.getTime() !== dateFinishWeek.getTime()) {
          let dateInHistory = medicine.userToDrugHistories.filter(info => new Date(info.created_at).getDate() === new Date(dateStartWeek).getDate());

          if(medicine.isPeriodic) {
            periodicMedicineResult = periodicMedicines(medicine, medicinesLog, frequencyTimes, dateStartWeek, dateInHistory);
          } else {
            periodicMedicineResult = nonPeriodicMedicines(medicine, frequencyTimes, dateStartWeek, dateInHistory);
          }

          quantityMedLog += periodicMedicineResult.quantityMedLog;
          quantityHistoryMedLog += periodicMedicineResult.quantityHistoryMedLog;

          medicationWeekHistory.push(
            {
              name: P.nameDayWeek(new Date(dateStartWeek).getDay(), 'S', t),
              frequencyStates: periodicMedicineResult.frequencyStates,
              date: new Date(dateStartWeek)
            }
          )

          dateStartWeek.setDate(dateStartWeek.getDate() + 1);
        }
      }

      lastReportDate = getLastReportDate(filterInsideTreatment, historieslength, medicine.userToDrugHistories);
      const medicineName = medicine.displayName || medicine.drug.genericName;

      medicinesWeek.push(
        {
          id: medicine.id,
          name: medicineName,
          dose: medicine.drugDose.doseTypeVal.comment,
          quantity: medicine.quantity,
          frequency: medicine.frequency,
          frequencyType: P.dataTranslation(medicine.frequencyTypeValue.comment, language),
          medicationToChart: medicationWeekHistory,
          ubicationInTreatment: filterInsideTreatment,
          medicationLog: medicinesLog,
          medicationHistory: medicine.userToDrugHistories,
          quantityMedicationLogDays: quantityMedLog,
          quantityMedicationDays: quantityHistoryMedLog,
          frequencyTimes: frequencyTimes,
          lastReportDate: lastReportDate
        }
      )
    });
    
    getTotalPercentage(medicinesWeek);
    return medicinesWeek;
  }

  const filterDataByMonth = async (info) => {
    let medicinesMonth = [];

    info?.forEach(medicine => {
      let frequencyTimes = getFrequencyTimes(medicine);
      const filterInsideTreatment = insideIntervalTreatment(medicine);
      let medicationMonthHistory = []
      const medicinesLog = getMedicinesLog(medicine);
      let periodicMedicineResult = {};
      let quantityMedLog = 0;
      let quantityHistoryMedLog = 0;

      let historieslength = medicine.userToDrugHistories.length;
      let lastReportDate;

      if(filterInsideTreatment === 'inside') {
        let dateStartMonth = new Date(time.startDate);
        let dateFinishMonth = new Date(time.startDate);
        const numberDaysMonth = new Date(dateStartMonth.getFullYear(), dateStartMonth.getMonth() + 1, 0).getDate();
        dateFinishMonth.setDate(dateFinishMonth.getDate() + numberDaysMonth);

        while(dateStartMonth.getTime() !== dateFinishMonth.getTime()) {

          let dateInHistory;
          dateInHistory = medicine.userToDrugHistories.filter(info => new Date(info.created_at).getDate() === new Date(dateStartMonth).getDate());

          if(medicine.isPeriodic) {
            periodicMedicineResult = periodicMedicines(medicine, medicinesLog, frequencyTimes, dateStartMonth, dateInHistory);
          } else {
            periodicMedicineResult = nonPeriodicMedicines(medicine, frequencyTimes, dateStartMonth, dateInHistory);
          }

          quantityMedLog += periodicMedicineResult.quantityMedLog;
          quantityHistoryMedLog += periodicMedicineResult.quantityHistoryMedLog;
          
          medicationMonthHistory.push(
            {
              name: new Date(dateStartMonth).getDate().toString().padStart(2, '0'),
              frequencyStates: periodicMedicineResult.frequencyStates,
              date: new Date(dateStartMonth)
            }
          )

          dateStartMonth.setDate(dateStartMonth.getDate() + 1);

        }
      }

      lastReportDate = getLastReportDate(filterInsideTreatment, historieslength, medicine.userToDrugHistories);
      const medicineName = medicine.displayName || medicine.drug.genericName;

      medicinesMonth.push(
        {
          id: medicine.id,
          name: medicineName,
          dose: medicine.drugDose.doseTypeVal.comment,
          quantity: medicine.quantity,
          frequency: medicine.frequency,
          frequencyType: P.dataTranslation(medicine.frequencyTypeValue.comment, language),
          medicationToChart: medicationMonthHistory,
          ubicationInTreatment: filterInsideTreatment,
          medicationLog: medicinesLog,
          medicationHistory: medicine.userToDrugHistories,
          quantityMedicationLogDays: quantityMedLog,
          quantityMedicationDays: quantityHistoryMedLog,
          frequencyTimes: frequencyTimes,
          lastReportDate: lastReportDate
        }
      )

    });
    getTotalPercentage(medicinesMonth);
    return medicinesMonth;
  }

  const getMedicinesLog = (medicine) => {
    const filterStart = new Date(time.startDate);
    const filterEnd = new Date(time.finishDate);
    if(medicine.isPeriodic) {
      let newDaysOfWeek = medicine?.daysOfWeek?.map(day => {
        return day !== 6 ? day + 1 : 0
      });
      return newDaysOfWeek;
    } else {
      let daysToMedicate = [];
      medicine.userToDrugDates?.forEach(date => {
        if (new Date(date.daySpecific) >= filterStart &&
            new Date(date.daySpecific) <= filterEnd) {
          daysToMedicate.push(new Date(date.daySpecific));
        }
      });
      return daysToMedicate;
    }
  }

  const insideIntervalTreatment = (medicine) => {
    const filterStart = new Date(time.startDate);
    const filterEnd = new Date(time.finishDate);
    const treatmentStart = new Date(new Date(medicine.startTreatment).setUTCHours(5));
    const treatmentEnd = medicine.endTreatment ? new Date(new Date(medicine.endTreatment).setUTCHours(5)) : null;

    // if(treatmentEnd) {
    //   if (medicine.isFix) {
    //     return (filterEnd > treatmentStart) ? 'inside' : 'before';
    //   } else {
    //     if (filterStart <= treatmentEnd && filterEnd >= treatmentStart) {
    //       return 'inside';
    //     } else if (filterEnd <= treatmentStart) {
    //       return 'before';
    //     } else {
    //       return 'after';
    //     }
    //   }
    // } else if (treatmentStart > filterEnd) {
    //   return 'after';
    // } else {
    //   return 'inside';
    // }
    
    if (!treatmentEnd) {
      return treatmentStart > filterEnd ? 'after' : 'inside';
    }
  
    if (medicine.isFix) {
      return filterEnd > treatmentStart ? 'inside' : 'before';
    }
  
    if (filterStart <= treatmentEnd && filterEnd >= treatmentStart) {
      return 'inside';
    }
  
    if (filterEnd <= treatmentStart) {
      return 'before';
    }
  
    return 'after';
  }

  const getTotalPercentage = (medicinesData) => {
    let quantityDays = 0;
    let quantityDaysLog = 0;
    medicinesData.forEach( medicine => {
      quantityDays += medicine.quantityMedicationDays;
      quantityDaysLog += medicine.quantityMedicationLogDays;
    });
    const totalPercentage = P.getPercentage(quantityDays, quantityDaysLog);
    setTotalPercentage(totalPercentage);
  }

  const infoMedicines = useMemo(() => {
    const info = userToMedicines?.user[0].userToDrugs.filter(medicine => !medicine.deleted);
    
    if(time?.timeOp === 'day') return filterDataByDay(info);
    if(time?.timeOp === 'week') return filterDataByWeek(info);
    if(time?.timeOp === 'month') return filterDataByMonth(info);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToMedicines, time])

  return (
    <S.MedicinesBoxInfo>
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left"
        >
          {
            <MedicinesLogList
              medicinesLog={infoMedicines}
              time={time}
              loading={userToMedicinesLoading}
            />
          }
        </div>
        {/* <div className="boxInfoPatient__info-right">
          <CircleChartCard
            icon={icon}
            title={t("patients.boxInfoPatient.report.medicines")}
            percentagePrincipal={totalPercentage}
            textPrincipal={t("patients.boxInfoPatient.report.reported")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div> */}
      </div>
    </S.MedicinesBoxInfo>
  );
}

const MedicinesLogList = ({medicinesLog, time, loading}) => {
  const [infoMedicine, setinfoMedicine] = useState();
  const [t] = useTranslation("global");

  useEffect(() => {
    medicinesLog?.then((info) => {
      setinfoMedicine(info?.filter(medicine => medicine.ubicationInTreatment !== 'before'));
    })
  },[medicinesLog])

  if (loading) return (
    <>
      <ZaiaReaction
        zaiaImg={loadingZaia}
        widthImg="100px"
        sizeText="15px"
        widthText="240px"
        />
    </>
  )

  if (infoMedicine?.length === 0) return (
    <>
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthImg="100px"
        text={t('globally.noReports')}
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  return (
    <>
      {
        infoMedicine?.sort(function(a, b){return b.lastReportDate - a.lastReportDate}).map(medicine => {
          if(medicine.ubicationInTreatment === 'before') return null;
          return (
            <MedicinesLogCard
              medicinesToChart={medicine}
              time={time}
              key={medicine.id}
            />
          )
        })
      }
    </>
  )
}

export default MedicinesBoxInfo;
