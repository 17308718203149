import React, { useContext, useState } from "react";
import * as Yup from "yup";
import * as S from "./ChangePassword.style";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { ThemeContext } from 'styled-components';

// context
import { useStateValue } from "../../../contextAPI/StateProvider";

// Routes
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../utils/constants";

// Components
import Header from "../header";
import CoverPage from "../coverPage";
import ErrorMessage from "../errorMessage";
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import ZaiaInputForm from "../../zaiaComponents/zaiaInputForm";

// supertokens
import { submitNewPassword } from "supertokens-auth-react/recipe/emailpassword";

function ResetPassword() {
  const [t] = useTranslation("global");
  const { AINAVIGATOR } = Routes;
  const navigate = useNavigate();
  const [, dispatch] = useStateValue();
  const webView = Cookies.get('webView');
  const theme = useContext(ThemeContext);

  const [alertMsg, setAlertMsg] = useState('');
  const [activeErrorForm, setActiveErrorForm] = useState(false);
  const [loadingChangePass, setLoadingChangePass] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);

  async function newPasswordEntered(
    newPassword, 
    setSubmitting, 
    resetForm
  ) {
    setLoadingChangePass(true);
    try {
      let response = await submitNewPassword({
        formFields: [{
          id: "password",
          value: newPassword
        }]
      }); 

      setLoadingChangePass(false);

      if (response.status === "FIELD_ERROR") {
        resetForm();
        setAlertMsg(t('changePassword.errorData'));
        activateAlert();
      } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
        resetForm();
        setAlertMsg(t('changePassword.errorToken'));
        activateAlert();
      } else {
        setAlertMsg(t('changePassword.success'));
        setChangePasswordSuccess(true);
        activateAlert();
        setTimeout(function() {
          dispatch({
            type: "SET_AUTH",
            isAuth: true,
          });
          navigate(AINAVIGATOR.route);
        }, 6000);
      }
    } catch (err) {
      setLoadingChangePass(false);
      setAlertMsg(t('globally.errorServer'));
      activateAlert();
      resetForm();
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    newPasswordEntered(values.confirmNewPass, setSubmitting, resetForm)
  };

  const activateAlert = () => {
    setActiveErrorForm(true);
    setTimeout(function() {
      setActiveErrorForm(false);      
      setAlertMsg('');
    }, 5000);
  }

  return (
    <S.ChangePassword webView={webView}>
      <CoverPage/>
      <div className="resetPassword__right">
        <Header route="/" />
        <Formik
          initialValues={{
            newPass: "",
            confirmNewPass: ""
          }}
          validationSchema={Yup.object({
            newPass: Yup.string()
              .min(3, `${t("register.validation.min")}`)
              .required(`${t("register.validation.password")}`)
              .matches(
                /^(?=.*?[A-ZÁ-Ú])(?=.*?[a-zá-ú])(?=.*?\d).{6,}$/,
                `${t("register.validation.matches")}`
              ),
            confirmNewPass: Yup.string()
              .min(3, `${t("register.validation.min")}`)
              .required(`${t("register.validation.confirmPass")}`)
              .when("newPass", {
                is: (newPass) => (newPass && newPass.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("newPass")],
                  `${t("register.validation.match")}`
                ),
              }),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className="reset-pass-form">
              <>
                <S.ResetPasswordFormDiv messageError={activeErrorForm}>
                  <div className="reset-pass-container">
                    <ErrorMessage
                      errorMessage={alertMsg}
                      activeError={activeErrorForm}
                      color={changePasswordSuccess ? theme.primaryColor : null}
                    />
                    <h2>{t("globally.resetPassword")}</h2>
                    <div className="resetPassword__email">
                      <h3 className="title-register">{t("changePassword.input")}</h3>
                      <ZaiaInputForm 
                        placeholder={t("profile.newPass")}
                        type="password"
                        name="newPass"
                        icon="icono-contrasena"
                      />
                      <div className="space-between"></div>
                      <h3 className="title-register">{t("changePassword.inputRepeat")}</h3>
                      <ZaiaInputForm 
                        placeholder={t("profile.confimNewPass")}
                        type="password"
                        name="confirmNewPass"
                        icon="icono-contrasena"
                      />
                      <ZaiaButton
                        type="submit"
                        marginTop="100"
                        shadow={true}
                        title={
                          loadingChangePass
                            ? `${t("globally.loading")}...`
                            : `${t("globally.send")}`
                        }
                      />
                    </div>
                  </div>
                </S.ResetPasswordFormDiv>
              </>
            </form>
          )}
        </Formik>
      </div>
    </S.ChangePassword>
  );
}

export default ResetPassword;
